import React, { FC, useEffect, useState, useContext, useCallback } from 'react';
import axios from 'src/utils/axiosintercpt';
import logo from 'src/assets/assets/img/logo1.png';
import logo2 from 'src/assets/synslandingpage/logo2.png';
import { pConfig } from 'src/config';
import Modal from 'react-bootstrap/Modal';
import Helmet from 'react-helmet';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import ManagementCourseBG from 'src/assets/mbbsadmission/banner1.png';
import Scope1 from 'src/assets/mbbsadmission/Scope-1.png';
import Scope2 from 'src/assets/mbbsadmission/Scope-2.png';
import Scope3 from 'src/assets/mbbsadmission/Scope-3.png';
import Scope4 from 'src/assets/mbbsadmission/Scope-4.png';
import Scope5 from 'src/assets/mbbsadmission/Scope-5.png';
import Scope6 from 'src/assets/mbbsadmission/Scope-6.png';
import Kasturba from 'src/assets/mbbsadmission/Kasturba.png';
import JSS from 'src/assets/mbbsadmission/JSS.png';
import Ramaiah from 'src/assets/mbbsadmission/Ramaiah.png';
import Sapthagiri from 'src/assets/mbbsadmission/Sapthagiri.png';
import Yenepoya from 'src/assets/mbbsadmission/Yenepoya.png';
import Vydehi from 'src/assets/mbbsadmission/Vydehi.png';
import RajaRajeshwari from 'src/assets/mbbsadmission/RajaRajeshwari.png';
import Father from 'src/assets/mbbsadmission/Father.png';
import Hegde from 'src/assets/mbbsadmission/Hegde.png';
import Oxford from 'src/assets/mbbsadmission/Oxford.png';
import Medical from 'src/assets/mbbsadmission/Medical.png';
import Akash from 'src/assets/mbbsadmission/Akash.png';

import Direct1 from 'src/assets/mbbsadmission/Direct1.png';
import Lowest from 'src/assets/mbbsadmission/Lowest.png';
import Step from 'src/assets/mbbsadmission/Step.png';
import Scholarship from 'src/assets/mbbsadmission/Scholarship.png';
 
import whtsp from 'src/assets/Alliancecollege/whatsapp.gif';

import contactbanner from 'src/assets/mbbsadmission/mbbsBanner-02.png';


import "./styles.css";

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';


const BscNursing: FC = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();



  const [validated, setValidated] = useState(false);


  const handleSubmit = (event: { currentTarget: any; preventDefault: () => void; stopPropagation: () => void; },) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    // <GlobalPopupEnquiry className="btn btn btn-primary" title="Please fill in your details to receive your detailed rank prediction." />
    event.preventDefault();
    console.log(event);
    setValidated(true);
    // <GlobalPopupEnquiry className="btn btn btn-primary" title="Please fill in your details to receive your detailed rank prediction." />

  };

  const [values, setValues] = useState({
    name: '', email: '', contact: '', course_in_mind: '', location: '', description: ''
  });
  const set = name => {
    return ({ target: { value } }) => {
      setValues(oldValues => ({ ...oldValues, [name]: value }));
    }
  };

  const onSubmit = async (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('email', values.email);
    formData.append('contact', values.contact);
    formData.append('course_in_mind', values.course_in_mind);
    formData.append('location', values.location);
    formData.append('description', values.description);
    formData.append('current_url', window.location.href);
    formData.append('sourse', 'MBBS Admission 2025-26');

    try {
      const response = await axios.post('api/website/home/landingpageenquiry', formData);
      // console.log('response: ', response);


      if (response.data.status) {

        history.push('/thank-you');
      }
    } catch (err) {
      console.error(err);

      enqueueSnackbar('Please try again', {
        variant: 'error',
      });
    }


  }

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const navigate = (e, id) => {
    e && e.preventDefault(); // to avoid the link from redirecting
    const elementToView = document.getElementById(id);
    elementToView.scrollIntoView();
  }

  return (


    <>
      <Helmet>
        <title>MBBS Admission in Karnataka 2023-2024</title>
        <meta name="description"
          content=" Looking for MBBS Admission in Karnataka? Check out MBBS Details, scope, Top Medical Colleges in Karnataka, MBBS Colleges in Bangalore, & more. Apply Now!" />
        <link rel="canonical" href="https://bangalorestudy.com/mbbs-admission" />
        <meta
          name="viewport"
          content="width=device-width; initial-scale=1.0; maximum-scale=1.0; user-scalable=0;"
        />
        <link rel="stylesheet" href="preprod/nursing_admission/assets/css/dental.css" />
        <link rel="stylesheet" href="preprod/nursing_admission/assets/css/style.min.css" />
        <link rel="stylesheet" href="preprod/nursing_admission/assets/css/resp.min.css" />
        <link
          href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
          rel="stylesheet"
          integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN"
          crossOrigin="anonymous"
        />
        <script>
          {`
        window.dataLayer = window.dataLayer || [];
        function gtag() {dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', 'AW-990332405');
        `}
        </script>


        <script>{`
      (function (w, d, s, l, i) {
        w[l] = w[l] || []; w[l].push({
          'gtm.start':
        new Date().getTime(), event: 'gtm.js'
			}); var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
        'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
		})(window, document, 'script', 'dataLayer', 'GTM-MGDJ5RD');
    `}</script>

        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-990332405"></script>
        <script src="preprod/medical_admission_asset/js/jquery.min.js"></script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-140595938-1"></script>





        <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>

        <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.16.0/umd/popper.min.js"></script>

        {/* <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script> */}

        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/2.1.3/jquery.min.js"></script>
        <script src="https://cdn.jsdelivr.net/jquery.slick/1.4.1/slick.min.js"></script>

        <script src="preprod/medical_admission_asset/js/jquery.min.js"></script>
        <script src="preprod/nursing_admission/assets/js/jquery.min.js"></script>
        <script type="text/javascript" src="preprod/nursing_admission/assets/js/jquery.fancybox.pack.js"></script>
        <script src="preprod/nursing_admission/assets/js/owl.carousel.min.js"></script>

        <script type="text/javascript"
          src="https://bangalorestudy.com/veterinary_assets/asset/js/owl.carousel.min.js "></script>


        <script type="text/javascript">{`
      

setTimeout(function () {
  $('.widget-visible').remove();
}, 15000);
setTimeout(function () {
  $('.widget-visible').remove();
}, 10000);

setTimeout(function () {
  $('.widget-visible').remove();
}, 5000);

setTimeout(function () {
  $('.widget-visible').remove();
}, 3000);

setTimeout(function () {
  $('.widget-visible').remove();
}, 2000);


setTimeout(function () {
  $('.widget-visible').remove();
}, 1000);
$(document).ready(function () {
  $("#formoid").submit(function (event) {
    console.log("12");
    /* stop form from submitting normally */
    event.preventDefault();

    /* get the action attribute from the <form action=""> element */
    var $form = $(this),
      url = $form.attr('action');

    /* Send the data using post with element id name and name2*/

    var posting = $.post(url, {
      name: $('#name').val(),
      email: $('#email').val(),
      contact: $('#contact').val(),
      college_name: $('#college_name').val(),
      current_url: window.location.href,
      course_in_mind: $('#course_name').val(),
      location: $('#location').val(),
      sourse: "MBBS Admission 2025-26",
      description: $('#description').val(),

    });

    // console.log($('#name').val(),$('#email').val(), $('#contact').val(),$('#college_name').val(),);

    /* Alerts the results */
    posting.done(function (data) {
      // console.log(data);
      // console.log("1");
      $('#result').text('Thank you we will get back to you');
      $('#result').addClass('alert alert-success');
      $('#result').addClass('mt-1');
      $("#result").show().delay(5000).fadeOut();

      $('#formoid').each(function () {
        this.reset();
        location.href = "https://bangalorestudy.com/thank-you"
      });

    });
    posting.fail(function () {
      // console.log(data);
      // console.log("2");
      $('#result').text('Sorry please try again');
      $('#result').addClass('alert alert-warning');
      $('#result').addClass('mt-1');
      $("#result").show().delay(5000).fadeOut();
      $('#formoid').each(function () {
        this.reset();
      });
    });
  });
  $("#formoid1").submit(function (event) {
    console.log("12");
    /* stop form from submitting normally */
    event.preventDefault();

    /* get the action attribute from the <form action=""> element */
    var $form = $(this),
      url = $form.attr('action');

    /* Send the data using post with element id name and name2*/
    var posting = $.post(url, {
      name: $('#name1').val(),
      email: $('#email1').val(),
      contact: $('#contact1').val(),
      college_name: $('#college_name1').val(),
      current_url: window.location.href,
      // course_in_mind: $('#course_name').val(),
      location: $('#location1').val(),
      // description: $('#description1').val(),

    });

    /* Alerts the results */
    posting.done(function (data) {
      // console.log(data);
      // console.log("1");
      $('#result').text('Thank you we will get back to you');
      $('#result').addClass('alert alert-success');
      $('#result').addClass('mt-1');
      $("#result").show().delay(5000).fadeOut();

      $('#formoid1').each(function () {
        this.reset();
        location.href = "https://bangalorestudy.com/thank-you"
      });

    });
    posting.fail(function () {
      // console.log(data);
      // console.log("2");
      $('#result').text('Sorry please try again');
      $('#result').addClass('alert alert-warning');
      $('#result').addClass('mt-1');
      $("#result").show().delay(5000).fadeOut();
      $('#formoid1').each(function () {
        this.reset();
      });
    });
  });
})


`}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(
            {
              "@context": "https://schema.org",
              "@type": "LocalBusiness",
              "name": "Bangalore Study",
              "image": `${pConfig.siteUrl}home_page/images/logo.png`,
              "@id": `${pConfig.siteUrl}contact`,
              "url": `${pConfig.siteUrl}`,
              "telephone": "+91-9036020016",
              "priceRange": "INR",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "#3/75, 'D' Main, East End,Jayanagar 9th Block,Bangalore - 560069",
                "addressLocality": "Bengaluru",
                "postalCode": "560069",
                "addressCountry": "IN"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 12.918769,
                "longitude": 77.598285
              },
              "openingHoursSpecification": {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": [
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday",
                  "Saturday"
                ],
                "opens": "10:00",
                "closes": "19:00"
              }
            }
          )}
        </script>
        <script>
          {`
        var $titleTab = $('.title_tab');
        $('.Accordion_item:eq(0)').find('.title_tab').addClass('active').next().stop().slideDown(300);
        $('.Accordion_item:eq(0)').find('.inner_content').find('p').addClass('show');
        $titleTab.on('click', function (e) {
            e.preventDefault();
            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
                $(this).next().stop().slideUp(500);
                $(this).next().find('p').removeClass('show');
            } else {
                $(this).addClass('active');
                $(this).next().stop().slideDown(500);
                $(this).parent().siblings().children('.title_tab').removeClass('active');
                $(this).parent().siblings().children('.inner_content').slideUp(500);
                $(this).parent().siblings().children('.inner_content').find('p').removeClass('show');
                $(this).next().find('p').addClass('show');
            }
        });
      `}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(
            {
              "@context": "https://schema.org",
              "@type": "Website",
              "name": "Bangalore Study",
              "url": `${pConfig.siteUrl}`,
              "headline": "Bangalore Study | #1 College Admissions in Bangalore",
              "description": "Bangalorestudy.com by Learntech Edu Solution Pvt. Ltd is an online education platform that operates for counselling students on college admissions",
              "image": {
                "@type": "ImageObject",
                "url": `${pConfig.siteUrl}home_page/images/logo.png`,
                "sameAs": [
                  "https://www.facebook.com/bangalorestudy/",
                  "https://twitter.com/BangaloreStudy2",
                  "https://www.youtube.com/channel/UCZP40_ivVcdelNOVhmQFr7w",
                  "https://www.instagram.com/bangalorestudy/"
                ]
              }
            }
          )}
        </script>

        <script type="application/ld+json">
          {JSON.stringify(
            {
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity":
                [{
                  "@type": "Question",
                  "name": "What is the MBBS eligibility criteria?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "The MBBS eligibility criteria is passed 10+2/ 2nd PUC/ Equivalent with English and 50% aggregate marks in Physics, Chemistry and Biology and should have qualified in NEET-UG."
                  }
                }, {
                  "@type": "Question",
                  "name": "What is the MBBS course duration and fees?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "MBBS is an undergraduate program of 5.5 years which includes 1 year internship. The MBBS course fees vary as per the quota and the college."
                  }
                }, {
                  "@type": "Question",
                  "name": "Name some of the best private Medical Colleges in Karnataka?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Some of the private medical colleges in Karnataka include Kasturba Medical College (KMC), JSS Medical College, Vydehi Institute of Medical Sciences & Research Centre, Sapthagiri Institute of Medical Sciences & Research Centre (SIMSRC) and more."
                  }
                }, {
                  "@type": "Question",
                  "name": "How to get MBBS admission in Bangalore?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "It is mandatory to qualify in NEET-UG. After qualifying in the entrance exam, students need to participate in the NEET-UG counselling process to acquire a seat in one of the best MBBS Colleges in Bangalore."
                  }
                }, {
                  "@type": "Question",
                  "name": "Which are the top MBBS Colleges in Bangalore?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Some of the best Medical Colleges in Bangalore include Ramaiah Medical College (RMC)), Vydehi Institute of Medical Sciences & Research Centre, Rajarajeswari Medical College & Hospital, The Oxford Medical College, Hospital & Research Centre, M.V.J Medical College & Research Hospital, Akash Institute of Medical Sciences & Research Centre (AIMSRC) and more."
                  }
                }
                ]
            }
          )}
        </script>


      </Helmet>
      <div className="col-md-12  desktop-view">
        <div className="right-sec top60">
          <div className="mob-sec cont-sec">
            <a href="tel:+91 9036020016">
              <span> 9036020016/05/24 &nbsp; &nbsp; </span>
              <i className="fa fa-phone mr-2" />
            </a>
          </div>
        </div>
        <div className=" right-sec2 top70 bamleftsec" >
          <div className="mail-sec cont-sec">
            <a href="mailto:info@bangalorestudy.com">
              <span>info@bangalorestudy.com &nbsp; &nbsp;</span>
              <i className="fa fa-envelope" />
            </a>
          </div>
        </div>
      </div>
      <div className="col-md-12 mobile-view">
        <div className="right-sec4">
          <div className="mob-sec cont-sec">
            <a href="tel:+91 9036020016">
              <i className="fa fa-phone mr-2" />
            </a>
          </div>
        </div>
        <div className=" right-sec3">
          <div className="mail-sec cont-sec">
            <a href="mailto:info@bangalorestudy.com">
              <i className="bi bi-envelope" />
            </a>
          </div>
        </div>
      </div>
      <header>
        <Navbar collapseOnSelect expand="lg" bg="light" fixed="top" variant="light">
          <Container fluid>
            <Navbar.Brand className="hov m-3" >
              <img
                src={logo}
                width="172"
                height="57"
                className="d-inline-block align-top hov"
                alt="BAMS Admission in Karnataka 2024-25"
              /></Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav my-3">
              <Nav style={{ marginLeft: "auto" }}>
                <Nav.Link href="" onClick={(e => navigate(e, 'home'))}>Home</Nav.Link>
                <Nav.Link href="" onClick={(e => navigate(e, 'about'))}>Why Us?</Nav.Link>
                <Nav.Link href="" onClick={(e => navigate(e, 'details'))}>MBBS Details</Nav.Link>
                <Nav.Link href="" onClick={(e => navigate(e, 'Scope'))}>Scope</Nav.Link>
                <Nav.Link href="" onClick={(e => navigate(e, 'admission'))}> Admissions</Nav.Link>
                <Nav.Link href="" onClick={(e => navigate(e, 'topcolleges'))}>Top Medical  Colleges</Nav.Link>
                <Nav.Link href="" onClick={(e => navigate(e, 'FAQs'))}>FAQs</Nav.Link>
                <Nav.Link href="" onClick={(e => navigate(e, 'Contactus'))}>Contact Us</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>

      <section className="d-flex align-items-center pb-0 px-0" id="home" style={{ paddingTop: 95 }} >
        {/* <div className="container-fluid bgimage" style={{ background: `url(${ManagementCourseBG})` }}> */}
        <div className="container-fluid bamsimage" id="home" style={{ background: `linear-gradient(341deg, rgb(0 0 0 / 0%), rgb(0 0 0 / 0%)) , url(${ManagementCourseBG})` }}>
          <div
            className="container-fluid aos-init aos-animate p-md-2 p-4 "
            data-aos="zoom-out"
            data-aos-delay={100}
          >
            <div className="row mt-md-5 pt-md-5 gap-md-0 gap-4" >
              <div className="col-xl-7 col-lg-7 col-md-7 text-center">
                <div className='bgblackmbbs slide-in'>

                  <h1 className='text-center h1amb  pt-3 '>MBBS Admission in Karnataka 2024-25
                    {/* <span className='d-block'>BDS Admission 2024-25</span > */}
                    {/* <span className='span pt-2'></span> */}
                  </h1>

                  <p className='firstmbbs mt-3  pt-md-4 mt-md-2'>Easy MBBS Counselling Process,  <br className='d-none d-md-block' /> Top MBBS
                    Colleges in Karnataka</p>
                  <p className='text-center p pt-md-4 mt-3  mt-md-2 font23 '  >Get into the Top MBBS Colleges in Karnataka with our Expert <br className='d-none d-md-block' /> Assistance for MBBS Admission in Karnataka and MBBS  <br className='d-none d-md-block' /> Admission in Bangalore.

                  </p>

                  <Button variant="primary" className="btn btn-success mb-4 btnbds" onClick={handleShow}>
                    Connect with our Experts!
                  </Button>
                </div>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-5">

                <div className="form-boxamb p-md-4 slide-in2 py-md-2">
                  <div>

                    <h3
                      className="f700 pb-2"
                      style={{ color: "white", textAlign: "center", fontSize: 20 }}
                    >
                      Assistance for Hassle-Free MBBS Admission Process.


                    </h3>
                  </div>

                  <form id="formoid"
                    action="https://api.bangalorestudy.com:3000/api/website/home/landingpageenquiry"
                    title=""
                    method="post">
                    <div className="form-group mb-3">

                      <input
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Name"
                        required
                        className="form-control"
                      />

                    </div>
                    <div className="form-group mb-3">
                      <input
                        className="form-control"
                        id="email"
                        type="email"
                        name="email"
                        placeholder="Your Email "
                        required
                      />
                    </div>
                    <div className="form-group mb-3">
                      <input
                        className="form-control"
                        name="contact"
                        id="contact"
                        type="number"

                        required
                        placeholder="Contact No."
                      />
                    </div>
                    <div className="form-group mb-3">
                      <input
                        className="form-control"
                        name="course_name"
                        id="course_name"
                        type="number"

                        required
                        placeholder="NEET Rank"
                      />
                    </div>

                    <div className="form-group mb-3">
                      <select
                        className="browser-default custom-select form-control"
                        name="location"
                        id="location"
                        required
                      >
                        <option value=" ">State</option>
                        <option value="Andhra Pradesh ">Andhra Pradesh</option>
                        <option value="Arunachal Pradesh ">Arunachal Pradesh</option>
                        <option value="Assam ">Assam</option>
                        <option value="Bihar ">Bihar</option>
                        <option value="Chhattisgarh ">Chhattisgarh</option>
                        <option value="Goa ">Goa</option>
                        <option value="Gujarat ">Gujarat</option>
                        <option value="Haryana ">Haryana</option>
                        <option value="Himachal Pradesh ">Himachal Pradesh</option>
                        <option value="Jammu & Kashmir ">Jammu &amp; Kashmir</option>
                        <option value="Jharkhand ">Jharkhand</option>
                        <option value="Karnataka ">Karnataka</option>
                        <option value="Kerala ">Kerala</option>
                        <option value="Madhya Pradesh ">Madhya Pradesh</option>
                        <option value="Maharashtra ">Maharashtra</option>
                        <option value="Manipur ">Manipur</option>
                        <option value="Meghalaya ">Meghalaya</option>
                        <option value="Mizoram ">Mizoram</option>
                        <option value="Nagaland ">Nagaland</option>
                        <option value="Odisha ">Odisha</option>
                        <option value="Punjab ">Punjab</option>
                        <option value="Rajasthan ">Rajasthan</option>
                        <option value="Sikkim ">Sikkim</option>
                        <option value="Tamil Nadu ">Tamil Nadu</option>
                        <option value="Tripura ">Tripura</option>
                        <option value="Telangana ">Telangana</option>
                        <option value="Uttarakhand ">Uttarakhand</option>
                        <option value="Uttar Pradesh ">Uttar Pradesh</option>
                        <option value="West Bengal ">West Bengal</option>
                        <option value="Andaman & Nicobar ">
                          Andaman &amp; Nicobar
                        </option>
                        <option value="Chandigarh ">Chandigarh</option>
                        <option value="Dadra and Nagar Haveli ">
                          Dadra and Nagar Haveli
                        </option>
                        <option value="Daman & Diu ">Daman &amp; Diu</option>
                        <option value="Delhi ">Delhi</option>
                        <option value="Lakshadweep ">Lakshadweep</option>
                        <option value="Puducherry ">Puducherry</option>
                      </select>
                    </div>

                    <div className="form-group mb-3">
                      <textarea

                        cols={3}
                        name="description"
                        id="description"
                        placeholder="Message (optional) "
                        className="form-control"
                        defaultValue={""}
                      />
                    </div>
                    <div className="form-group text-center" >
                      {/* <span  style={{ display: "hidden" }}></span> */}
                      <button className="btn btn-success submit btnbdscolour" name="submit" id="about" defaultValue="Submit" type="submit">Submit  </button>

                    </div>

                  </form>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="osec-with-right-form pb-0 pt-4" style={{ backgroundColor: "rgb(18 34 55)", color: "white" }}  >


        <div className="container-fluid " >
          <div className='row px-0 px-md-3'>
            <div className='col-12 bdsh2why h2 pb-4 pb-md-0' >Why Us?

            </div>
            <div className="col-12 col-md-3">     <div style={{ textAlign: "center" }}>
              {/* <i
        className="fa fa-bars"
        style={{ fontSize: 100, marginBottom: 17 }}
      /> */}
              <img src={Direct1} alt='Direct Admission in Top Dental Colleges in Karnataka' className='bamsimg4' ></img>
              <h3 className='mabbsorangen' >Hassle-Free MBBS Admission Process</h3>
              <p className='pt-3 pb-3 pt-md-5 pb-md-4'>
                Qualified NEET-UG? Wondering if you will be able to acquire a seat in one of the top MBBS Colleges in Karnataka? Aiming to get into any of the top Deemed Medical Colleges in Karnataka or any top Private Medical colleges in Bangalore? Then, contact our Experts to guide you and increase your chances of grabbing an MBBS Seat.
              </p>
            </div></div>
            <div className="col-12 col-md-3">     <div style={{ textAlign: "center" }}>
              <img src={Lowest} className='bamsimg4' alt='Lowest BDS Course'></img>
              <h3 className='mabbsorangen' >Details of MBBS Fees after Clearing NEET</h3>
              <p className='pt-3 pb-3 pt-md-5 pb-md-4'> Want the latest information about the MBBS fee structure in various colleges and get started with the admission process? Connect with our Experts for MBBS NRI quota fees, fee structure for MBBS in private colleges, MBBS Management quota fees in Karnataka or other fee details.

              </p>
            </div></div>

            <div className="col-12 col-md-3">     <div style={{ textAlign: "center" }}>
              <img src={Step} className='bamsimg4' alt='Step-by-Step NEET Counselling Assistance'></img>

              <h3 className='mabbsorangen'>Step-by-Step NEET Counselling Assistance
              </h3>
              <p className='pt-3 pb-3 pt-md-5 pb-md-4' >
                Get assistance to register for NEET counsellings and to choose the right colleges. Complete the counselling process without any mistakes. Get in touch with our Experts to get complete NEET-UG Counselling Assistance along with tricks and strategies for a guaranteed seat!

              </p>
            </div></div>
            <div className="col-12 col-md-3">     <div style={{ textAlign: "center" }}>
              <img src={Scholarship} className='bamsimg4' alt='Scholarship'></img>
              <h3 className='mabbsorangen'>Scholarship for MBBS Students</h3>
              <p className='pt-2 pb-3 pt-md-4 pb-md-4 pt-xl-5' >
                Don’t give up on your Medical dreams because of high MBBS fees. We offer scholarship for MBBS students to enable deserving candidates to study in the best MBBS Colleges in Bangalore and Karnataka without having to go through financial stress. Get in touch with us for further details!

                {/* <span id="details" style={{ display: "hidden" }}></span> */}

              </p>
              <span id="details" style={{ display: "hidden" }}></span>
            </div>
            </div>


          </div>

        </div>



      </section >


      <section className="container-fluid  p-0 pb-0 pt-3"  >
        <div className="container">
          <div className="row ">
            <div className="col-lg-12 text-center pt-3">
              <h3 className='f3mbbs'>MBBS  Course Details</h3>
            </div>

            <div className="col-lg-12 text-center pt-3 pb-3"><p className='textmbbs'>The most common choice of a student who wants to enter the Medical field is MBBS. Although MBBS is one the toughest courses, many students take up the course every single year. If you are one among the many who wants to pursue MBBS this year, from one of the Best Medical Colleges in Karnataka, then scan through some of the basic details related to MBBS before getting to the details related to the admission process.

            </p></div>


            <div className="col-lg-12  pt-3 px-0 px-md-5 ">
              <div className=" mbbsscopecustomborder">
                <table className="table table-bordered mb-0">
                  <tbody>

                    <tr>
                      <th scope="row">Course Name</th>
                      <td colSpan={3}>Bachelor of Medicine and Bachelor of Surgery (MBBS)</td>

                    </tr>
                    <tr>
                      <th scope="row">Course Level</th>
                      <td colSpan={3}>Undergraduate (UG)</td>

                    </tr>
                    <tr>
                      <th scope="row">Duration</th>
                      <td colSpan={3}>5.5 Years <p>(4.5 Year Academic Study + 1 Year Internship)</p></td>

                    </tr>
                    <tr>
                      <th scope="row">Entrance Exam</th>
                      <td colSpan={3}>NEET-UG</td>

                    </tr>
                    <tr>
                      <th scope="row">MBBS Eligibility Criteria</th>

                      <td colSpan={3} className='d-flex justify-content-center'>
                        <ul className='text-start ps-4'>
                          <li>Passed 10+2/  2nd PUC or Equivalent with English and <br /> 50% Aggregate Marks in PCB Subjects</li>
                          <li>Qualified NEET-UG entrance exam</li>
                        </ul>
                      </td>

                    </tr>
                    <tr>
                      <th scope="row">Regulatory Body</th>
                      <td colSpan={3}>National Medical Commission (NMC)</td>

                    </tr>
                  </tbody>
                </table>


              </div>
            </div>




            <div className=' gap-2 gap-md-3 bodrdertext mbbscard  divfont d-flex justify-content-around align-items-center mt-5  flex-column p-2 p-md-3 ' style={{ maxWidth: "1024px", margin: "auto" }}>
              <div className='text-center'>Get all the Details Related to MBBS Counselling Process, Cut-offs, Seat Matrix & More.</div>
              <Button variant="primary" className="btn btn-success submit btnmbbs" style={{ width: 276 }} onClick={handleShow}>
                Connect with our Experts!

              </Button>

            </div>





          </div>



        </div>
      </section >

      <section className="container-fluid  p-0 pb-0 pt-3" >
        <div className="container">
          <div className="row ">
            <div className="col-lg-12 text-center pt-3">
              <h3 className=' f3mbbs'>MBBS  Syllabus</h3>
            </div>

            <div className="col-lg-12 text-center pt-3 pb-3"><p className='textmbbs '>MBBS syllabus in India covers all the core concepts related to Medicine and Surgery. The subjects are divided under 3 main categories i.e: Pre-Clinical, Para-Clinical and Clinical. MBBS is a 5.5 year undergraduate course which includes 4.5 years of academic study and 1 year mandatory rotatory internship. The modern and up-to-date curriculum helps students build a strong foundation in the field of Medicine.

            </p></div>
            <div className="col-lg-12 text-center pt-3 pb-3">

              <div className="row row-cols-1 row-cols-md-3 g-2 customberder-mbbs-admission">
                <div className="col">
                  <div className="card   text-bg-success  bgmbbs  text-center h-100">
                    <div className="card-header">
                      <h4 className="card-title ">Pre-Clinical</h4>
                    </div>
                    <div className="card-body">

                      <ul className='text-start'>
                        <li>Human Anatomy</li>
                        <li>Introduction to Community Medicine</li>
                        <li>Biochemistry</li>
                        <li>Physiology</li>
                        <li>Humanities</li>

                      </ul>

                      <Button variant="primary" className="btn btn-success mb-4  mbbdbtnpadding" onClick={handleShow}>
                        Know More
                      </Button>

                    </div>

                  </div>


                </div>
                <div className="col">
                  <div className="card   text-bg-success  bgmbbs  text-center h-100">
                    <div className="card-header">
                      <h4 className="card-title ">Para-Clinical</h4>
                    </div>
                    <div className="card-body">

                      <ul className='text-start'>
                        <li>Pharmacology</li>
                        <li>Microbiology</li>
                        <li>Forensic Medicine and Toxicology</li>
                        <li>Pathology</li>
                        <li>Community Medicine</li>
                      </ul>

                      <Button variant="primary" className="btn btn-success mb-4 mbbdbtnpadding" onClick={handleShow}>
                        Know More
                      </Button>

                    </div>

                  </div>


                </div>
                <div className="col">
                  <div className="card   text-bg-success  bgmbbs  text-center h-100">
                    <div className="card-header">
                      <h4 className="card-title ">Clinical</h4>
                    </div>
                    <div className="card-body">

                      <ul className='text-start'>
                        <li>General Medicine</li>
                        <li>Obstetrics & Gynaecology</li>
                        <li>Psychiatry</li>
                        <li>Pediatrics</li>
                        <li>General Surgery        </li>
                      </ul>
                      <span id="Scope" style={{ display: "hidden" }}></span>
                      <Button variant="primary" className="btn btn-success mb-4 mbbdbtnpadding" onClick={handleShow}>
                        Know More
                      </Button>

                    </div>

                  </div>


                </div>

              </div>
            </div>


          </div>



        </div>
      </section >
      <section className="container-fluid  pb-0 pt-4"  >
        <div className="container textmbbs">
          <div className="row justify-content-center align-items-center  mbbsscope mb-3">
            <div className="col-lg-12  pb-md-3">
              <h3 className='f3mbbs text-center'>Scope of MBBS
              </h3>
            </div>
            <div className="col-lg-12 text-center pt-3 pt-md-2 pb-3"><p className='textmbbs pb-3'>The requirement of Doctors will never Decay. As new diseases keep sprouting every now and then, the demand for Doctors has soared up drastically over the years. Although many students choose Medicine as their field of study, the career opportunities for Medical students have never decreased. Therefore, students who pick Medicine as their career choice and kickstart their career from one of the top MBBS Colleges in Karnataka will never run out of career opportunities.

            </p>
            </div>

            <div className="col-lg-6 d-flex  align-items-center scope">

              <img src={Scope1} alt="Scope1" />


              <p>Work for Leading Medical Chains and Hospitals</p>

            </div>
            <div className="col-lg-6 d-flex  align-items-center scope">

              <img src={Scope2} alt="scope2" />


              <p>Career in Hospital Management/ Health Administration</p>
            </div>
            <div className="col-lg-6 d-flex  align-items-center scope">

              <img src={Scope3} alt="scope3" />


              <p> Begin Own Clinical Practice
              </p>
            </div>
            <div className="col-lg-6 d-flex  align-items-center scope">

              <img src={Scope4} alt="scope4" />


              <p>Work in Defence Services
              </p>
            </div>
            <div className="col-lg-6 d-flex  align-items-center scope">

              <img src={Scope5} alt="scope5" />


              <p> Work in Primary Health Care Centres
              </p>
            </div>
            <div className="col-lg-6 d-flex   align-items-center scope">

              <img src={Scope6} alt="scope6" />


              <p id="admission">Specialize with a MD/ MS, DNB or PG Diploma Course</p>
            </div>
            {/* <div className="col-lg-6 d-flex  align-items-center scope">

              <img src={Scope7} alt="scope6" />


              <p id="bdsaddmission">Cafeteria, Fitness & Athletics Centre</p>
            </div>
            <div id="BDS" className="col-lg-6 d-flex  align-items-center scope">
              <img src={Scope8} alt="scope6" />


              <p >Advanced Research Centre
              </p>

            </div> */}





          </div>

        </div >
      </section >
      <section className="container-fluid  p-0 pb-0 pt-4" >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center ">
              <h3 className='f3mbbs'>MBBS Admission Process</h3>
            </div>

            <div className="col-lg-12 text-center pt-4 pb-3"><p className='textmbbs'>Acquiring an MBBS seat in India is not easy. Students need to qualify in one of toughest competitive exams called National Eligibility-cum-Entrance Test for Undergraduate (NEET-UG). After clearing the exam, they need to participate in either State or National level counselling processes to acquire a seat in one of the top Medical Colleges in Karnataka or anywhere in India.

            </p>

              <p className="text-center pt-3 textmbbs " >Multiple Counselling Rounds are conducted for students. During the admission process, students need to submit the following documents to acquire a seat.
              </p>
            </div>
          </div>
          <div className="col-lg-12 text-center pb-3 pt-3">
            <h3 className='bamsfont25'>Documents Required for MBBS  Admission 2024-25</h3>
          </div>

          <div className="col-lg-12 text-center pt-3 pb-3">
            <div className="mbbsscopecustomborder border-none mx-md-5 mx-0">
              <table className="table table-bordered documents">
                <tbody>
                  <tr className="d-flex flex-column  flex-md-row justify-content-center ">
                    <td className='pb-0 '>
                      <ul className='ps-3 ps-md-5 text-start '>
                        <li>10th, 11th and 12th Marksheets</li>
                        <li>Graduation Marksheets <span className='fontweigth400'>(if any) </span></li>
                        <li>NEET-UG Application Form</li>
                        <li>NEET-UG Admit Card</li>
                        <li>NEET-UG Scorecard</li>
                        <li>NEET-UG Rank Letter</li>
                        <li>Valid Government-issued ID Proof</li>
                        <li>Rural Service Bond</li>

                      </ul>

                    </td>
                    <td className='pt-0  pt-md-2'>
                      <ul className='ps-3 ps-md-5 text-start' >
                        <li>Seat Leaving Bond</li>
                        <li>Character Certificate</li>
                        <li>Transfer Certificate</li>
                        <li>Recent Passport Size Photographs</li>
                        <li>Migration Certificate <span className='fontweigth400'> (if applicable)</span></li>
                        <li>Caste Certificate <span className='fontweigth400'> (if applicable)</span></li>
                        <li id="topcolleges">Income Certificate <span className='fontweigth400'> (if applicable)</span></li>
                        <li >PwD Certificate <span className='fontweigth400'> (if applicable)</span></li>

                      </ul>

                    </td>


                  </tr>

                </tbody>
              </table>

            </div>
          </div>

          {/* <div id="FAQs" className='bodrdertext mbbscard py-3 divfont d-flex justify-content-around align-items-center mt-3 px-3 px-md-0 mb-3 talkdiv-mbd' >
            <Button variant="primary" className="btn btn-success submit btnmbbs" style={{ width: 200 }} onClick={handleShow}>
              Talk to our Experts

            </Button>

          </div> */}



        </div>
      </section >


      <section className="container-fluid  p-0 pb-0" >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center ">
              <h3 className=' f3mbbs'>Best MBBS Colleges in Karnataka</h3>
            </div>

            <div className="col-lg-12 text-center pt-4 pb-3"><p className='textmbbs'>Students need to acquire required professional skills along with theoretical knowledge while pursuing their degree. As quality education is the key to success, students prefer to study in colleges which will provide them with all the required skills for a bright future. Karnataka has some of the Top ranked colleges in India. Therefore, students can choose to study in one of the best Medical Colleges in Karnataka.
              <br />
              <br />
              As Bangalore has some of the top ranked medical colleges, the list mentioned below has a mixture of the best MBBS colleges in Bangalore and also other places in Karnataka.


            </p></div>

            <div className="col-lg-12 text-center pt-3 pb-4">
              <h3 className='bamsfont25'>List of Top MBBS Colleges in Karnataka
              </h3>
            </div>
          </div>
          <div className="col-lg-12 text-center">
            <div className="row row-cols-1 row-cols-md-4 g-4 bamscolllege bdscollegeimage btnsmbb">


              <div className="col">
                <div className="card h-100">
                  <img className="card-img-top imgcontain" src={Kasturba} alt="Kasturba Medical College (KMC)" />
                  <div className="card-body">
                    <h5 className="card-title h100">Kasturba Medical College (KMC)</h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker " style={{ color: "red" }} aria-hidden="true"></i>
                      &nbsp; Mangalore & Manipal
                    </p>
                    <Button variant="primary" className="btn btn-success m-2 btnbdscolour" onClick={handleShow}>
                      Enquire Now
                    </Button>

                  </div>
                </div>
              </div>
              <div className="col">

                <div className="card h-100">
                  <img className="card-img-top imgcontain" src={JSS} alt="Card image cap" />
                  <div className="card-body">
                    <h5 className="card-title h100">JSS Medical College</h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker " style={{ color: "red" }} aria-hidden="true"></i>
                      &nbsp; Mysore
                    </p>
                    <Button variant="primary" className="btn btn-success m-2 btnbdscolour" onClick={handleShow}>
                      Enquire Now
                    </Button>


                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card h-100">
                  <img className="card-img-top imgcontain" src={Ramaiah} alt="Amrutha Ayurvedic Medical College (AAMC)" />
                  <div className="card-body">
                    <h5 className="card-title h100">Ramaiah Medical College (RMC)</h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker" style={{ color: "red" }} aria-hidden="true"></i>
                      &nbsp; Bangalore
                    </p>
                    <Button variant="primary" className="btn btn-success m-2 btnbdscolour" onClick={handleShow}>
                      Enquire Now
                    </Button>

                  </div>
                </div>
              </div>

              <div className="col ">

                <div className="card h-100">
                  <img className="card-img-top imgcontain" src={Yenepoya} alt="Ramaiah" />
                  <div className="card-body">
                    <h5 className="card-title h100 h100">Yenepoya Medical College (YMC)</h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker " style={{ color: "red" }} aria-hidden="true"></i>
                      &nbsp; Mangalore
                    </p>
                    <Button variant="primary" className="btn btn-success m-2 btnbdscolour" onClick={handleShow}>
                      Enquire Now
                    </Button>


                  </div>
                </div>
              </div>
              <div className="col">

                <div className="card h-100">
                  <img className="card-img-top imgcontain" src={Sapthagiri} alt="MVJ Medical College & Research Hospital " />
                  <div className="card-body">
                    <h5 className="card-title h100">Sapthagiri Institute of Medical Sciences & Research Centre (SIMSRC)
                    </h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker " style={{ color: "red" }} aria-hidden="true"></i>
                      &nbsp; Bangalore
                    </p>
                    <Button variant="primary" className="btn btn-success m-2 btnbdscolour" onClick={handleShow}>
                      Enquire Now
                    </Button>


                  </div>
                </div>
              </div>
              <div className="col">

                <div className="card h-100">
                  <img className="card-img-top imgcontain" src={Vydehi} alt="Sushrutha Ayurvedic Medical College & Hospital
 " />
                  <div className="card-body">
                    <h5 className="card-title h100">Vydehi Institute of Medical Sciences & Research Centre

                    </h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker " style={{ color: "red" }} aria-hidden="true"></i>
                      &nbsp; Bangalore
                    </p>
                    <Button variant="primary" className="btn btn-success m-2 btnbdscolour" onClick={handleShow}>
                      Enquire Now
                    </Button>


                  </div>
                </div>
              </div>
              <div className="col">

                <div className="card h-100">
                  <img className="card-img-top imgcontain" src={RajaRajeshwari} alt="Dr. B.R. Ambedkar Medical College" />
                  <div className="card-body">
                    <h5 className="card-title h100">Rajarajeswari Medical College & Hospital</h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker " style={{ color: "red" }} aria-hidden="true"></i>
                      &nbsp; Bangalore
                    </p>
                    <Button variant="primary" className="btn btn-success m-2 btnbdscolour" onClick={handleShow}>
                      Enquire Now
                    </Button>


                  </div>
                </div>
              </div>
              <div className="col">

                <div className="card h-100">
                  <img className="card-img-top imgcontain" src={Father} alt="Father Muller Medical College
" />
                  <div className="card-body">
                    <h5 className="card-title h100">Father Muller Medical College

                    </h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker " style={{ color: "red" }} aria-hidden="true"></i>
                      &nbsp; Mangalore
                    </p>
                    <Button variant="primary" className="btn btn-success m-2 btnbdscolour" onClick={handleShow}>
                      Enquire Now
                    </Button>


                  </div>
                </div>
              </div>

              <div className="col">

                <div className="card h-100">
                  <img className="card-img-top imgcontain" src={Hegde} alt="Akash Institute of Medical Sciences & Research Centre" />
                  <div className="card-body">
                    <h5 className="card-title h100"> K.S. Hegde Medical Academy
                    </h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker " style={{ color: "red" }} aria-hidden="true"></i>
                      &nbsp; Mangalore
                    </p>
                    <Button variant="primary" className="btn btn-success m-2 btnbdscolour" onClick={handleShow}>
                      Enquire Now
                    </Button>


                  </div>
                </div>
              </div>
              <div className="col">

                <div className="card h-100">
                  <img className="card-img-top imgcontain" src={Oxford} alt="Yenepoya Medical College (YMC)" />
                  <div className="card-body">
                    <h5 className="card-title h100">The Oxford Medical College, Hospital & Research Centre



                    </h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker " style={{ color: "red" }} aria-hidden="true"></i>
                      &nbsp; Bangalore
                    </p>
                    <Button variant="primary" className="btn btn-success m-2 btnbdscolour" onClick={handleShow}>
                      Enquire Now
                    </Button>


                  </div>
                </div>
              </div>
              <div className="col">

                <div className="card h-100">
                  <img className="card-img-top imgcontain" src={Medical} alt="Yenepoya Medical College (YMC)" />
                  <div className="card-body">
                    <h5 className="card-title h100">M.V.J Medical College & Research Hospital




                    </h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker " style={{ color: "red" }} aria-hidden="true"></i>
                      &nbsp; Bangalore
                    </p>
                    <Button variant="primary" className="btn btn-success m-2 btnbdscolour" onClick={handleShow}>
                      Enquire Now
                    </Button>


                  </div>
                </div>
              </div>
              <div className="col">

                <div className="card h-100">
                  <img className="card-img-top imgcontain" src={Akash} alt="Yenepoya Medical College (YMC)" />
                  <div className="card-body">
                    <h5 className="card-title h100"> Akash Institute of Medical Sciences & Research Centre (AIMSRC)




                    </h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker " style={{ color: "red" }} aria-hidden="true"></i>
                      &nbsp; Bangalore
                    </p>
                    <Button variant="primary" className="btn btn-success m-2 btnbdscolour" onClick={handleShow}>
                      Enquire Now
                    </Button>


                  </div>
                </div>
              </div>






            </div>
          </div>
          <div className='col-lg-12 text-center p-3 f700 ' style={{ fontSize: 27 }} id="FAQs">
            & Many More!</div>


        </div>
      </section >





      <div className="faq-section p-3 bg-gray-2" >
        <div className="container ">
          <div className="faq-header ">
            <h2
              style={{
                color: "#1D3355",
                fontWeight: 600,
                textAlign: "center",
                paddingTop: 10
              }}
            >
              FAQs
            </h2>
          </div>
          <div className="faq-outer ">
            <div className="faq-img Ambfaq-img ">
              <img src="preprod/nursing_admission/assets/images/faq-img.png" alt="FAQs " />
            </div>
            <div
              className="faq-sec Ambfaq-sec "
              style={{
                background:
                  "linear-gradient(90deg,transparent, #ebebfb, transparent)"
              }}
            >
              <div className="">
                <div className="Accordions ">
                  <div className="Accordion_item ">
                    <div className="title_tab ">
                      <h3 className="title ">
                        What is the MBBS eligibility criteria?
                        <span className="icon ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg "
                            width={16}
                            height={16}
                            fill="currentColor "
                            className="bi bi-chevron-down "
                            viewBox="0 0 16 16 "
                          >
                            <path
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z "
                            />
                          </svg>
                        </span>
                      </h3>
                    </div>
                    <div className="inner_content ">
                      <p>
                        The MBBS eligibility criteria is passed 10+2/ 2nd PUC/ Equivalent with English and 50% aggregate marks in Physics, Chemistry and Biology and should have qualified in NEET-UG.


                      </p>
                    </div>
                  </div>
                  <div className="Accordion_item ">
                    <div className="title_tab ">
                      <h3 className="title ">
                        What is the MBBS course duration and fees?


                        <span className="icon ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg "
                            width={16}
                            height={16}
                            fill="currentColor "
                            className="bi bi-chevron-down "
                            viewBox="0 0 16 16 "
                          >
                            <path
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z "
                            />
                          </svg>
                        </span>
                      </h3>
                    </div>
                    <div className="inner_content ">
                      <p>
                        MBBS is an undergraduate program of 5.5 years which includes 1 year internship. The MBBS course fees vary as per the quota and the college.



                      </p>
                    </div>
                  </div>
                  <div className="Accordion_item ">
                    <div className="title_tab ">
                      <h3 className="title ">
                        Name some of the best private Medical Colleges in Karnataka?


                        <span className="icon ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg "
                            width={16}
                            height={16}
                            fill="currentColor "
                            className="bi bi-chevron-down "
                            viewBox="0 0 16 16 "
                          >
                            <path
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z "
                            />
                          </svg>
                        </span>
                      </h3>
                    </div>
                    <div className="inner_content ">
                      <p>
                        Some of the private medical colleges in Karnataka include Kasturba Medical College (KMC), JSS Medical College, Vydehi Institute of Medical Sciences & Research Centre, Sapthagiri Institute of Medical Sciences & Research Centre (SIMSRC) and more.



                      </p>
                    </div>
                  </div>
                  <div className="Accordion_item ">
                    <div className="title_tab ">
                      <h3 className="title ">
                        How to get MBBS admission in Bangalore?



                        <span className="icon ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg "
                            width={16}
                            height={16}
                            fill="currentColor "
                            className="bi bi-chevron-down "
                            viewBox="0 0 16 16 "
                          >
                            <path
                              // fillRule="evenodd "
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z "
                            />
                          </svg>
                        </span>
                      </h3>
                    </div>
                    <div className="inner_content ">
                      <p>
                        It is mandatory to qualify in NEET-UG. After qualifying in the entrance exam, students need to participate in the NEET-UG counselling process to acquire a seat in one of the best MBBS Colleges in Bangalore.


                      </p>
                    </div>
                  </div>
                  <div className="Accordion_item ">
                    <div className="title_tab ">
                      <h3 className="title ">
                        Which are the top MBBS Colleges in Bangalore?


                        <span className="icon ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg "
                            width={16}
                            height={16}
                            fill="currentColor "
                            className="bi bi-chevron-down "
                            viewBox="0 0 16 16 "
                          >
                            <path
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z "
                            />
                          </svg>
                        </span>
                      </h3>
                    </div>
                    <div className="inner_content ">
                      <p>
                        Some of the best Medical Colleges in Bangalore include Ramaiah Medical College (RMC), Vydehi Institute of Medical Sciences & Research Centre, Rajarajeswari Medical College & Hospital, The Oxford Medical College, Hospital & Research Centre, M.V.J Medical College & Research Hospital, Akash Institute of Medical Sciences & Research Centre (AIMSRC) and more.



                      </p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>




      <section className="container-fluid d-flex align-items-center pt-3 pb-0 px-0 " id="Contactus">
        <div className="container-fluid bgimage text-center d-flex justify-content-center align-items imgh" style={{ background: `linear-gradient(341deg, rgb(0 0 0 / 0%), rgb(0 0 0 / 0%)) , url(${contactbanner})` }}>
          <div className='ddAmb pe-md-5 me-md-5 '><h2 className='text-white p-3' style={{
            background: "rgb(0 2 62 / 48%)",
            borderRadius: "11px"
          }}>Fulfil your Medical Dreams from Top Medical Colleges in Karnataka.</h2>
            <Button variant="primary" className="btn btn-success mt-5 mb-4 m-3 m-md-5 font-white23 btnbdscolour " onClick={handleShow}>
              Apply Now!
            </Button>
          </div>
        </div>


      </section>




      <footer className='pb-1 bgfooter'>
        <div className="container-fluid align-item-start justify-content-between d-none d-md-flex flex-wrap px-5">
          <div className="footer-left  col-md-7 d-flex ps-5">
            <div className="col-md-8">

              <div className="ft-left mb-3 px-5" style={{ marginBottom: 20 }}>
                <img src="preprod/nursing_admission/assets/images/footer-bg.png" className="imgfooter" alt="bangalorestudy " />
              </div>


            </div>

            <div className="col-md-6 footer-left text-center pe-5" >
              <p className="font-white23 pe-5">Social Connect</p>
              <p style={{ marginTop: 10, marginBottom: 14 }} className='d-block pe-5'>
                <a target="_blank " href="https://www.facebook.com/bangalorestudy ">
                  <i className="fa fa-facebook" />&nbsp; &nbsp;&nbsp;
                </a>
                <a target="_blank " href="https://twitter.com/BangaloreStudy2 ">
                  <i className="fa-brands fa-x-twitter" />&nbsp; &nbsp;&nbsp;
                </a>
                <a
                  target="_blank "
                  href="https://www.youtube.com/channel/UCZP40_ivVcdelNOVhmQFr7w "
                >
                  {" "}
                  <i className="fa fa-youtube" />&nbsp;&nbsp;&nbsp;
                </a>
                <a target="_blank " href="https://instagram.com/bangalorestudy ">
                  <i className="fa fa-instagram" />&nbsp;&nbsp;&nbsp;
                </a>
                <a
                  target="_blank "
                  href="https://www.linkedin.com/company/learntech-edu-solutions-pvt-ltd/"
                >
                  {" "}
                  <i className="fa fa-linkedin" />&nbsp;&nbsp;&nbsp;
                </a>
                <a target="_blank " href="https://in.pinterest.com/bangalorestudy7608/ ">
                  <i className="fa fa-pinterest" />&nbsp;&nbsp;&nbsp;
                </a>
              </p>

            </div>

          </div>




          <div className="footer-right col-md-5 offeset-md-1  d-flex">
            <div className="social-unit col-md-5 w-100 px-3 d-flex justify-content-end" >
              <div>
                <p className=" px-5 font-white23" style={{ marginBottom: 10 }}>Connect with us</p>

                <a
                  href="tel:08022454991 "
                  style={{ color: "white", fontSize: 14, fontWeight: 400 }}
                >
                  <i className="fa fa-phone" style={{ fontSize: 13 }} /> 080-22454991
                </a>
                ,
                <a
                  href="tel:08026631169 "
                  style={{ color: "white", fontSize: 14, fontWeight: 400 }}
                >
                  <i className="fa fa-phone" style={{ fontSize: 13 }} /> 080-26631169
                </a>
                <br />
                <div className="bs-phone " style={{ display: "contents" }}>
                  <a
                    href="tel:18001208696 "
                    style={{ color: "white", fontWeight: 400, fontSize: 14 }}
                  >
                    <i className="fa fa-phone" style={{ fontSize: 13 }} /> 1800 120 8696 (Toll
                    Free)
                  </a>
                </div>
                <p className='row px-3'>
                  <div className='col-3 px-0'>Dubai :{" "}</div>
                  <div className='col-8 px-0'>
                    <a
                      href="tel:971502436552 "
                      style={{ color: "white", fontSize: 14, fontWeight: 400 }}
                    >
                      <i className="fa fa-phone" style={{ fontSize: 13 }} /> +971502436552{" "}
                    </a>
                    ,{" "}
                    <br />
                    <a
                      href="tel:971504955123 "
                      style={{ color: "white", fontSize: 14, fontWeight: 400 }}
                    >
                      <i className="fa fa-phone" style={{ fontSize: 14 }} /> +971504955123{" "}
                    </a>
                  </div>
                </p>



              </div>
            </div>
          </div>

          <div className='col-12 text-center footerp '><p >
            {" "}
            © 2025 Bangalorestudy.com. All Rights Reserved.
          </p>

          </div>


        </div>
        <div className="container align-item-start justify-content-between d-md-none">
          <div className="footer-left pt-3 col-md-7 text-center">

            <div
              className="ft-left mb-3"
              style={{ justifyContent: "flex-start" }}
            >
              <img src="preprod/nursing_admission/assets/images/footer-bg.png " className="imgfooter" alt="bangalorestudy " />
            </div>
          </div>
          <div className="footer-right col-md-5 offeset-md-1 py-t d-flex">
            <div className="app-unit col-md-7  col-md-5 ">
              <div className="store-wrapper">
                <p className='font-white23 pb-2'>Social Connect</p>
                <a target="_blank " href="https://www.facebook.com/bangalorestudy ">
                  <i className="fa fa-facebook" />&nbsp;&nbsp;
                </a>
                <a target="_blank " href="https://twitter.com/BangaloreStudy2 ">
                  <i className="fa fa-twitter" />&nbsp;&nbsp;
                </a>
                <a
                  target="_blank "
                  href="https://www.youtube.com/channel/UCZP40_ivVcdelNOVhmQFr7w "
                >
                  {" "}
                  <i className="fa fa-youtube" />&nbsp;&nbsp;
                </a>
                <a target="_blank " href="https://instagram.com/bangalorestudy ">
                  <i className="fa fa-instagram" />&nbsp;&nbsp;
                </a>
                <a
                  target="_blank "
                  href="https://www.linkedin.com/company/learntech-edu-solutions-pvt-ltd/"
                >
                  {" "}
                  <i className="fa fa-linkedin" />&nbsp;&nbsp;
                </a>
                <a
                  target="_blank "
                  href="https://in.pinterest.com/bangalorestudy7608/ "
                >
                  <i className="fa fa-pinterest" />&nbsp;&nbsp;
                </a>
              </div>
            </div>
            <div className="social-unit col-md-5 mt-4">
              <div>
                <p className="font-white23">Connect with us</p><a
                  href="tel:08022454991 "
                  style={{ color: "white", fontSize: 14, fontWeight: 400 }}
                ><i className="fa fa-phone" style={{ fontSize: 13 }} />080-22454991</a>,
                <a
                  href="tel:08026631169 "
                  style={{ color: "white", fontSize: 14, fontWeight: 400 }}
                >
                  <i className="fa fa-phone" style={{ fontSize: 13 }} />
                  080-26631169
                </a>
                <div className="bs-phone " style={{ display: "contents" }}>
                  {" "}
                  <br />
                  <a
                    href="tel:18001208696 "
                    style={{ color: "white", fontWeight: 400, fontSize: 14 }}
                  >
                    <i className="fa fa-phone" style={{ fontSize: 13 }} />
                    1800 120 8696(Toll Free)
                  </a>
                </div>
                <p>
                  Dubai :{" "}
                  <a
                    href="tel:970502436552 "
                    style={{ color: "white", fontSize: 14, fontWeight: 400 }}
                  >
                    <i className="fa fa-phone" style={{ fontSize: 13 }} />{" "}
                    +970502436552{" "}
                  </a>
                  ,{" "}
                  <br/>
                  <a
                    href="tel:971504955123 "
                    style={{ color: "white", fontSize: 14, fontWeight: 400 }}
                  >
                    <i className="fa fa-phone" style={{ fontSize: 13 }} />{" "}
                    +971504955123{" "}
                  </a>
                </p>
              </div>
            </div>
            <p className="footerp">
              {" "}
              © 2025 Bangalorestudy.com. All Rights Reserved.
            </p>
          </div>
        </div>


      </footer>
      <a
        href="https://wa.me/+919036020016"
        style={{
          position: "fixed",
          width: 63,
          height: 64,
          bottom: 7,
          right: 24,
          // backgroundColor: "#25d366",
          color: "#fff",
          borderRadius: 50,
          textAlign: "center",
          fontSize: 44,
          boxShadow: "2px 2px 3px #999",
          zIndex: 1059
        }}
        target="_blank "
      >
        {/* <i className="bi bi-whatsapp my-float " /> */}
                <img src={whtsp} alt='whtsplogo' style={{ width: "66px" }}></img>
        
      </a>

      <Modal show={show} onHide={handleClose}>

        <Modal.Body>

          <div className="heading-popup ">
            <h4 className='text-center'>Fill in the Details to Get in Touch with our Experts.</h4>
          </div>
          <form
            onSubmit={onSubmit}

          >

            <fieldset>
              <input
                type="text"
                id="name1"
                name="name"
                placeholder="Name* "
                required
                value={values.name} onChange={set('name')}
              />
            </fieldset>
            <small className="text-danger " />
            <fieldset className="form-group group_field">
              <input
                name="contact"
                type="number"
                id="contact1"
                // min={0}
                required
                placeholder="Contact No. * "
                // aria-required="true"
                value={values.contact} onChange={set('contact')}
              />
            </fieldset>
            <small className="text-danger " />
            <fieldset className="form-group group_field">
              <input
                type="email"
                id="email1"
                name="email"
                placeholder="Email ID* "
                required
                value={values.email} onChange={set('email')}
              />
            </fieldset>
            <fieldset className="form-group group_field">
              <input
                name="course_in_mind"
                type="number"
                id="course_in_mind"
                // min={0}
                required
                placeholder="NEET Rank"
                // aria-required="true"
                value={values.course_in_mind} onChange={set('course_in_mind')}
              />
            </fieldset>
            <small className="text-danger " />
            <fieldset className="form-group group_field">
              <small className="text-danger " />
              <fieldset className="form-group group_field">

                <select name="location" id="location1"
                  value={values.location} onChange={set('location')} required>
                  <option value=" ">--select state--</option>
                  <option value="Andhra Pradesh ">Andhra Pradesh</option>
                  <option value="Arunachal Pradesh ">Arunachal Pradesh</option>
                  <option value="Assam ">Assam</option>
                  <option value="Bihar ">Bihar</option>
                  <option value="Chhattisgarh ">Chhattisgarh</option>
                  <option value="Goa ">Goa</option>
                  <option value="Gujarat ">Gujarat</option>
                  <option value="Haryana ">Haryana</option>
                  <option value="Himachal Pradesh ">Himachal Pradesh</option>
                  <option value="Jammu & Kashmir ">Jammu &amp; Kashmir</option>
                  <option value="Jharkhand ">Jharkhand</option>
                  <option value="Karnataka ">Karnataka</option>
                  <option value="Kerala ">Kerala</option>
                  <option value="Madhya Pradesh ">Madhya Pradesh</option>
                  <option value="Maharashtra ">Maharashtra</option>
                  <option value="Manipur ">Manipur</option>
                  <option value="Meghalaya ">Meghalaya</option>
                  <option value="Mizoram ">Mizoram</option>
                  <option value="Nagaland ">Nagaland</option>
                  <option value="Odisha ">Odisha</option>
                  <option value="Punjab ">Punjab</option>
                  <option value="Rajasthan ">Rajasthan</option>
                  <option value="Sikkim ">Sikkim</option>
                  <option value="Tamil Nadu ">Tamil Nadu</option>
                  <option value="Tripura ">Tripura</option>
                  <option value="Telangana ">Telangana</option>
                  <option value="Uttarakhand ">Uttarakhand</option>
                  <option value="Uttar Pradesh ">Uttar Pradesh</option>
                  <option value="West Bengal ">West Bengal</option>
                  <option value="Andaman & Nicobar ">Andaman &amp; Nicobar</option>
                  <option value="Chandigarh ">Chandigarh</option>
                  <option value="Dadra and Nagar Haveli ">
                    Dadra and Nagar Haveli
                  </option>
                  <option value="Daman & Diu ">Daman &amp; Diu</option>
                  <option value="Delhi ">Delhi</option>
                  <option value="Lakshadweep ">Lakshadweep</option>
                  <option value="Puducherry ">Puducherry</option>
                </select>


              </fieldset>
              <small className="text-danger " />
              <fieldset className="form-group ">
                <input
                  value={values.description} onChange={set('description')}
                  type="text "
                  name="description"
                  id="description1"
                  placeholder="Message (optional) "
                />
              </fieldset>
              <small className="text-danger " />
              <fieldset className="form-group ">
                <button type="submit" className='btn btn-primary'>Submit</button>
              </fieldset>
            </fieldset>
          </form>

        </Modal.Body>

      </Modal>





    </>
  );
};

export default BscNursing;