import React, { FC, useEffect, useState, useContext, useCallback } from 'react';
import axios from 'src/utils/axiosintercpt';
import logo from 'src/assets/assets/img/logo1.png';
import { pConfig } from 'src/config';
import Modal from 'react-bootstrap/Modal';
import Helmet from 'react-helmet';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import ManagementCourseBG from 'src/assets/allcourselandingpage/NewBanner2.png';
import Ramahia from 'src/assets/allcourselandingpage/Ramaiah.png';
import Jain from 'src/assets/allcourselandingpage/Jain.png';
import Christ from 'src/assets/allcourselandingpage/Christ.png';
import St from 'src/assets/allcourselandingpage/St.png';
import Yenepoya from 'src/assets/allcourselandingpage/Yenepoya.png';
import Manipal from 'src/assets/allcourselandingpage/Manipal.png';
import Dayanand from 'src/assets/allcourselandingpage/Dayanand Sagar.png';
import PES from 'src/assets/allcourselandingpage/PES.png';
import CMR from 'src/assets/allcourselandingpage/CMR.png';
import JSS from 'src/assets/allcourselandingpage/JSS.png';
import KLE from 'src/assets/allcourselandingpage/KLE.png';
import NITTE from 'src/assets/allcourselandingpage/NITTE.png';
import bannertalk from 'src/assets/allcourselandingpage/banner5.png';
import OwlCarousel from 'react-owl-carousel';
import Engineering from 'src/assets/allcourselandingpage/Engineering.png';
import Allied from 'src/assets/allcourselandingpage/Allied Health Sciences.png';
import Architecture from 'src/assets/allcourselandingpage/Architecture.png';
import Ayush from 'src/assets/allcourselandingpage/Ayush.png';
import Commerce from 'src/assets/allcourselandingpage/Commerce.png';
import Computer from 'src/assets/allcourselandingpage/Computer Science.png';
import Dental from 'src/assets/allcourselandingpage/Dental.png';
import Law from 'src/assets/allcourselandingpage/Law.png';
import Management from 'src/assets/allcourselandingpage/Management.png';
import Medical from 'src/assets/allcourselandingpage/Medical.png';
import Nursing from 'src/assets/allcourselandingpage/Nursing.png';
import Pharmacy from 'src/assets/allcourselandingpage/Pharmacy.png';
import Science from 'src/assets/allcourselandingpage/Science.png';
import Veterinary from 'src/assets/allcourselandingpage/Veterinary.png';



import {



  Hidden
} from '@material-ui/core';
import "./styles.css";
import 'animate.css';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import whtsp from 'src/assets/Alliancecollege/whatsapp.gif';
const BscNursing: FC = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const owlOptions = {
    margin: 10, nav: true, autoplay: true, autoplayTimeout: 2000,
    responsiveClass: true,
    responsive: {
      0: { items: 1, dots: false, nav: false, loop: true },
      400: { items: 1, dots: false, nav: false, loop: true },
      600: { items: 1, dots: false, nav: true },
      700: { items: 2, dots: false, nav: true },
      1000: {
        items: 4,
        nav: false,
        loop: true
      },
      1440: {
        items: 5,
        nav: false,
        loop: true
      }
    },
  };
  const owlOptions2 = {
    margin: 10, nav: true, autoplay: true,
    responsiveClass: true,
    responsive: {
      0: { items: 1, dots: false, nav: false, loop: true },
      400: { items: 1, dots: false, nav: false, loop: true },
      600: { items: 1, dots: false, nav: true },
      700: { items: 2, dots: false, nav: true },
      1000: {
        items: 2,
        nav: true,
        loop: false
      },
      1440: {
        items: 3,
        nav: true,
        loop: false,
        video: true,
        lazyLoad: true
      }
    },
  };

  const [validated, setValidated] = useState(false);


  const handleSubmit = (event: { currentTarget: any; preventDefault: () => void; stopPropagation: () => void; },) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    // <GlobalPopupEnquiry className="btn btn btn-primary" title="Please fill in your details to receive your detailed rank prediction." />
    event.preventDefault();
    console.log(event);
    setValidated(true);
    // <GlobalPopupEnquiry className="btn btn btn-primary" title="Please fill in your details to receive your detailed rank prediction." />

  };

  const [values, setValues] = useState({
    name: '', email: '', contact: '', course_in_mind: '', location: '', description: ''
  });
  const set = name => {
    return ({ target: { value } }) => {
      setValues(oldValues => ({ ...oldValues, [name]: value }));
    }
  };

  const onSubmit = async (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('email', values.email);
    formData.append('contact', values.contact);
    formData.append('course_in_mind', values.course_in_mind);
    formData.append('location', values.location);
    formData.append('description', values.description);
    formData.append('current_url', window.location.href);
    formData.append('sourse', 'All Courses Admission 2025-26');

    try {
      const response = await axios.post('api/website/home/landingpageenquiry', formData);
      // console.log('response: ', response);


      if (response.data.status) {
 
        history.push('/thank-you');
      }
    } catch (err) {
      console.error(err);

      enqueueSnackbar('Please try again', {
        variant: 'error',
      });
    }


  }

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const navigate = (e, id) => {
    e && e.preventDefault(); // to avoid the link from redirecting
    const elementToView = document.getElementById(id);
    elementToView.scrollIntoView();
  }

  return (


    <>
      <Helmet>
        <title>All Courses Admission Guidance 2025in Karnataka</title>
        <meta name="description"
          content=" Looking for UG and PG Admissions in Karnataka’s Top Colleges? Avail expert admission guidance and counselling assistance for all courses in best colleges and top universities in Bangalore, Mysore, Mangalore, Belgavi, etc. Get started with the application process. Apply Now!
          " />
        <link rel="canonical" href="https://bangalorestudy.com/all_courses" />
        <meta
          name="viewport"
          content="width=device-width; initial-scale=1.0; maximum-scale=1.0; user-scalable=0;"
        />
        <link rel="stylesheet" href="preprod/nursing_admission/assets/css/dental.css" />
        <link rel="stylesheet" href="preprod/nursing_admission/assets/css/style.min.css" />
        <link rel="stylesheet" href="preprod/nursing_admission/assets/css/resp.min.css" />
        <link
          href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
          rel="stylesheet"
          integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN"
          crossOrigin="anonymous"
        />
        <script>
          {`
        window.dataLayer = window.dataLayer || [];
        function gtag() {dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', 'AW-990332405');
        `}
        </script>


        <script>{`
      (function (w, d, s, l, i) {
        w[l] = w[l] || []; w[l].push({
          'gtm.start':
        new Date().getTime(), event: 'gtm.js'
			}); var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
        'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
		})(window, document, 'script', 'dataLayer', 'GTM-MGDJ5RD');
    `}</script>

        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-990332405"></script>
        <script src="preprod/medical_admission_asset/js/jquery.min.js"></script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-140595938-1"></script>





        <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>

        <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.16.0/umd/popper.min.js"></script>

        {/* <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script> */}

        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/2.1.3/jquery.min.js"></script>
        <script src="https://cdn.jsdelivr.net/jquery.slick/1.4.1/slick.min.js"></script>

        <script src="preprod/medical_admission_asset/js/jquery.min.js"></script>
        <script src="preprod/nursing_admission/assets/js/jquery.min.js"></script>
        <script type="text/javascript" src="preprod/nursing_admission/assets/js/jquery.fancybox.pack.js"></script>
        <script src="preprod/nursing_admission/assets/js/owl.carousel.min.js"></script>

        <script type="text/javascript"
          src="https://bangalorestudy.com/veterinary_assets/asset/js/owl.carousel.min.js "></script>


        <script type="text/javascript">{`
      

setTimeout(function () {
  $('.widget-visible').remove();
}, 15000);
setTimeout(function () {
  $('.widget-visible').remove();
}, 10000);

setTimeout(function () {
  $('.widget-visible').remove();
}, 5000);

setTimeout(function () {
  $('.widget-visible').remove();
}, 3000);

setTimeout(function () {
  $('.widget-visible').remove();
}, 2000);


setTimeout(function () {
  $('.widget-visible').remove();
}, 1000);
$(document).ready(function () {
  $("#formoid").submit(function (event) {
    console.log("12");
    /* stop form from submitting normally */
    event.preventDefault();

    /* get the action attribute from the <form action=""> element */
    var $form = $(this),
      url = $form.attr('action');

    /* Send the data using post with element id name and name2*/

    var posting = $.post(url, {
      name: $('#name').val(),
      email: $('#email').val(),
      contact: $('#contact').val(),
      college_name: $('#college_name').val(),
      current_url: window.location.href,
      course_in_mind: $('#course_name').val(),
      location: $('#location').val(),
      sourse: "All Courses Admission 2025-26",
      description: $('#description').val(),

    });

    // console.log($('#name').val(),$('#email').val(), $('#contact').val(),$('#college_name').val(),);

    /* Alerts the results */
    posting.done(function (data) {
      // console.log(data);
      // console.log("1");
      $('#result').text('Thank you we will get back to you');
      $('#result').addClass('alert alert-success');
      $('#result').addClass('mt-1');
      $("#result").show().delay(5000).fadeOut();

      $('#formoid').each(function () {
        this.reset();
        location.href = "https://bangalorestudy.com/thank-you"
      });

    });
    posting.fail(function () {
      // console.log(data);
      // console.log("2");
      $('#result').text('Sorry please try again');
      $('#result').addClass('alert alert-warning');
      $('#result').addClass('mt-1');
      $("#result").show().delay(5000).fadeOut();
      $('#formoid').each(function () {
        this.reset();
      });
    });
  });
  $("#formoid1").submit(function (event) {
    console.log("12");
    /* stop form from submitting normally */
    event.preventDefault();

    /* get the action attribute from the <form action=""> element */
    var $form = $(this),
      url = $form.attr('action');

    /* Send the data using post with element id name and name2*/
    var posting = $.post(url, {
      name: $('#name1').val(),
      email: $('#email1').val(),
      contact: $('#contact1').val(),
      college_name: $('#college_name1').val(),
      current_url: window.location.href,
      // course_in_mind: $('#course_name').val(),
      location: $('#location1').val(),
      // description: $('#description1').val(),

    });

    /* Alerts the results */
    posting.done(function (data) {
      // console.log(data);
      // console.log("1");
      $('#result').text('Thank you we will get back to you');
      $('#result').addClass('alert alert-success');
      $('#result').addClass('mt-1');
      $("#result").show().delay(5000).fadeOut();

      $('#formoid1').each(function () {
        this.reset();
        location.href = "https://bangalorestudy.com/thank-you"
      });

    });
    posting.fail(function () {
      // console.log(data);
      // console.log("2");
      $('#result').text('Sorry please try again');
      $('#result').addClass('alert alert-warning');
      $('#result').addClass('mt-1');
      $("#result").show().delay(5000).fadeOut();
      $('#formoid1').each(function () {
        this.reset();
      });
    });
  });
})


`}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(
            {
              "@context": "https://schema.org",
              "@type": "LocalBusiness",
              "name": "Bangalore Study",
              "image": `${pConfig.siteUrl}home_page/images/logo.png`,
              "@id": `${pConfig.siteUrl}contact`,
              "url": `${pConfig.siteUrl}`,
              "telephone": "+91-9036020016",
              "priceRange": "INR",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "#3/75, 'D' Main, East End,Jayanagar 9th Block,Bangalore - 560069",
                "addressLocality": "Bengaluru",
                "postalCode": "560069",
                "addressCountry": "IN"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 12.918769,
                "longitude": 77.598285
              },
              "openingHoursSpecification": {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": [
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday",
                  "Saturday"
                ],
                "opens": "10:00",
                "closes": "19:00"
              }
            }
          )}
        </script>
        <script>
          {`
        var $titleTab = $('.title_tab');
        $('.Accordion_item:eq(0)').find('.title_tab').addClass('active').next().stop().slideDown(300);
        $('.Accordion_item:eq(0)').find('.inner_content').find('p').addClass('show');
        $titleTab.on('click', function (e) {
            e.preventDefault();
            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
                $(this).next().stop().slideUp(500);
                $(this).next().find('p').removeClass('show');
            } else {
                $(this).addClass('active');
                $(this).next().stop().slideDown(500);
                $(this).parent().siblings().children('.title_tab').removeClass('active');
                $(this).parent().siblings().children('.inner_content').slideUp(500);
                $(this).parent().siblings().children('.inner_content').find('p').removeClass('show');
                $(this).next().find('p').addClass('show');
            }
        });
      `}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(
            {
              "@context": "https://schema.org",
              "@type": "Website",
              "name": "Bangalore Study",
              "url": `${pConfig.siteUrl}`,
              "headline": "Bangalore Study | #1 College Admissions in Bangalore",
              "description": "Bangalorestudy.com by Learntech Edu Solution Pvt. Ltd is an online education platform that operates for counselling students on college admissions",
              "image": {
                "@type": "ImageObject",
                "url": `${pConfig.siteUrl}home_page/images/logo.png`,
                "sameAs": [
                  "https://www.facebook.com/bangalorestudy/",
                  "https://twitter.com/BangaloreStudy2",
                  "https://www.youtube.com/channel/UCZP40_ivVcdelNOVhmQFr7w",
                  "https://www.instagram.com/bangalorestudy/"
                ]
              }
            }
          )}
        </script>



      </Helmet>
      <div className="col-md-12  desktop-view">
        <div className="right-sec">
          <div className="mob-sec cont-sec">
            <a href="tel:+91 9036020016">
              <span> 9036020016/05/24 &nbsp; &nbsp; </span>
              <i className="fa fa-phone mr-2" />
            </a>
          </div>
        </div>
        <div className=" right-sec2 ">
          <div className="mail-sec cont-sec">
            <a href="mailto:info@bangalorestudy.com">
              <span>info@bangalorestudy.com &nbsp; &nbsp;</span>
              <i className="fa fa-envelope" />
            </a>
          </div>
        </div>
      </div>
      <div className="col-md-12 mobile-view">
        <div className="right-sec4">
          <div className="mob-sec cont-sec">
            <a href="tel:+91 9036020016">
              <i className="fa fa-phone mr-2" />
            </a>
          </div>
        </div>
        <div className=" right-sec3">
          <div className="mail-sec cont-sec">
            <a href="mailto:info@bangalorestudy.com">
              <i className="bi bi-envelope" />
            </a>
          </div>
        </div>
      </div>
      <header>
        <Navbar collapseOnSelect expand="lg" bg="light" fixed="top" variant="light">
          <Container fluid>
            <Navbar.Brand className="hov m-3" >
              <img
                src={logo}
                width="172"
                height="57"

                className="d-inline-block align-top hov"
                alt="NEET-UG MBBS 2023-24"
              /></Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav style={{ marginLeft: "auto" }}>
                <Nav.Link href="" onClick={(e => navigate(e, 'home'))}>Home</Nav.Link>
                <Nav.Link href="" onClick={(e => navigate(e, 'whychoose'))}>Why Us</Nav.Link>
                <Nav.Link href="" onClick={(e => navigate(e, 'courses'))}>Courses</Nav.Link>
                <Nav.Link href="" onClick={(e => navigate(e, 'topuniversities'))}>Top Universities</Nav.Link>
                <Nav.Link href="" onClick={(e => navigate(e, 'testimonials'))}>Testimonials</Nav.Link>
                <Nav.Link href="" onClick={(e => navigate(e, 'Contactus'))}>Contact Us</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>

      <section className="d-flex align-items-center pb-0" id="home" style={{ paddingTop: 95 }} >
        <div className="container-fluid bgimage" style={{ background: `linear-gradient(341deg, rgb(0 0 0 / 0%), rgb(0 0 0 / 0%)) , url(${ManagementCourseBG})` }}>
          <div
            className="container-fluid aos-init aos-animate p-md-2 p-4"
            data-aos="zoom-out"
            data-aos-delay={100}
          >
            <div className="row mt-md-5 pt-md-5 gap-md-0 gap-4" >
              <div className="col-xl-7 col-lg-7 col-md-7 text-center">
                <div className='bgblack slide-in'>
                  <h1 className='text-center h1all' >
                    Here’s Your Chance to Study Your Desired Course

                    <span className='d-block'>in India’s Top Colleges!</span >
                  </h1>
                  <p className='text-center cblack p font23' >Choose the Right Career Path with Expert Guidance and
                    <span className="d-block">Get Started with the Admission Processes Now.</span>
                  </p>
                  <Button variant="primary" className="btn btn-success mb-4" onClick={handleShow}>
                    Connect with our Experts!

                  </Button>
                </div>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-5">

                <div className="form-box p-md-4 slide-in2">
                  <div>
                    <h3
                      className="f700 pb-3"
                      style={{ color: "white", textAlign: "center", fontSize: 23 }}
                    >
                      Get in Touch with us for Admission Guidance


                    </h3>
                  </div>

                  <form id="formoid"
                    action="https://api.bangalorestudy.com:3000/api/website/home/landingpageenquiry"

                    method="post">
                    <div className="form-group mb-3">

                      <input
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Name"
                        required
                        className="form-control"
                      />

                    </div>
                    <div className="form-group mb-3">
                      <input
                        className="form-control"
                        id="email"
                        type="email"
                        name="email"
                        placeholder="Your Email "
                        required
                      />
                    </div>
                    <div className="form-group mb-3">
                      <input
                        className="form-control"
                        name="contact"
                        id="contact"
                        type="number"

                        required
                        placeholder="Contact No."
                      />
                    </div>
                    <div className="form-group mb-3">
                      <input
                        className="form-control"
                        name="course_name"
                        id="course_name"
                        type="text"

                        required
                        placeholder="Interested Course"
                      />

                    </div>

                    <div className="form-group mb-3">
                      <select
                        className="browser-default custom-select form-control"
                        name="location"
                        id="location"
                        required
                      >
                        <option value=" ">State</option>
                        <option value="Andhra Pradesh ">Andhra Pradesh</option>
                        <option value="Arunachal Pradesh ">Arunachal Pradesh</option>
                        <option value="Assam ">Assam</option>
                        <option value="Bihar ">Bihar</option>
                        <option value="Chhattisgarh ">Chhattisgarh</option>
                        <option value="Goa ">Goa</option>
                        <option value="Gujarat ">Gujarat</option>
                        <option value="Haryana ">Haryana</option>
                        <option value="Himachal Pradesh ">Himachal Pradesh</option>
                        <option value="Jammu & Kashmir ">Jammu &amp; Kashmir</option>
                        <option value="Jharkhand ">Jharkhand</option>
                        <option value="Karnataka ">Karnataka</option>
                        <option value="Kerala ">Kerala</option>
                        <option value="Madhya Pradesh ">Madhya Pradesh</option>
                        <option value="Maharashtra ">Maharashtra</option>
                        <option value="Manipur ">Manipur</option>
                        <option value="Meghalaya ">Meghalaya</option>
                        <option value="Mizoram ">Mizoram</option>
                        <option value="Nagaland ">Nagaland</option>
                        <option value="Odisha ">Odisha</option>
                        <option value="Punjab ">Punjab</option>
                        <option value="Rajasthan ">Rajasthan</option>
                        <option value="Sikkim ">Sikkim</option>
                        <option value="Tamil Nadu ">Tamil Nadu</option>
                        <option value="Tripura ">Tripura</option>
                        <option value="Telangana ">Telangana</option>
                        <option value="Uttarakhand ">Uttarakhand</option>
                        <option value="Uttar Pradesh ">Uttar Pradesh</option>
                        <option value="West Bengal ">West Bengal</option>
                        <option value="Andaman & Nicobar ">
                          Andaman &amp; Nicobar
                        </option>
                        <option value="Chandigarh ">Chandigarh</option>
                        <option value="Dadra and Nagar Haveli ">
                          Dadra and Nagar Haveli
                        </option>
                        <option value="Daman & Diu ">Daman &amp; Diu</option>
                        <option value="Delhi ">Delhi</option>
                        <option value="Lakshadweep ">Lakshadweep</option>
                        <option value="Puducherry ">Puducherry</option>
                      </select>
                    </div>

                    <div className="form-group mb-3">
                      <textarea

                        cols={3}
                        name="description"
                        id="description"
                        placeholder="Message (optional) "
                        className="form-control"
                        defaultValue={""}
                      />
                    </div>
                    <div className="form-group text-center" id="whychoose" >
                      <button className="btn btn-success submit" name="submit" defaultValue="Submit" type="submit">Submit</button>

                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="osec-with-right-form pb-0 pt-4" style={{ backgroundColor: "rgb(18 34 55)", color: "white" }}  >


        <div className="container-fluid " >
          <div className='row px-0 px-md-3'>
            <div className='col-12 h2why h2 pb-3' >Why Us?
            </div>
            <div className='col-12 text-center h2 pb-3' ><p className='font-size23'>Here’s why you should have us on board!</p></div>
            <div className="col-12 col-md-3">     <div style={{ textAlign: "center" }}>
              <i
                className="fa fa-users"
                style={{ fontSize: 100, marginBottom: 17 }}
              />
              <h3 className='cyellow' >Team of Experts</h3>
              <p className='pt-3 pb-3 pt-md-5 pb-md-4'>
                We are a team of experts and mentors with decades of experience in career counselling and admission guidance.
              </p>
            </div></div>
            <div className="col-12 col-md-3">     <div style={{ textAlign: "center" }}>
              <i
                className="fa fa-bars"
                style={{ fontSize: 100, marginBottom: 17 }}
              />
              <h3 className='cyellow' >Simplify the Process</h3>
              <p className='pt-3 pb-3 pt-md-5 pb-md-4'>We simplify college admissions and counselling procedures to make sure nothing gets in the way of your dreams.

              </p>
            </div></div>

            <div className="col-12 col-md-3">     <div style={{ textAlign: "center" }}>
              <i
                className="fa fa-list-alt"
                style={{ fontSize: 100, marginBottom: 17 }}
              />

              <h3 className='cyellow'>Right Strategy</h3>
              <p className='pt-3 pb-3 pt-md-5 pb-md-4' >
                Each course admission has its own requirements and challenges. We make you aware of it all and devise the right plan that will secure your dream seat.


              </p>
            </div></div>
            <div className="col-12 col-md-3">     <div style={{ textAlign: "center" }}>
              <i
                className="fa fa-bullhorn"
                style={{ fontSize: 100, marginBottom: 17 }}
              />
              <h3 className='cyellow'  >Latest Updates</h3>
              <p className='pt-2 pb-3 pt-md-4 pb-md-4 pt-xl-5' id="courses">
                Stay informed about every latest development in the education field and make the right choices that maximise your potential.


              </p>
            </div></div>
          </div>

        </div>


      </section >

      <section className="container-fluid   pb-0 pt-3">

        <div className="container  pt-md-0">
          <div className="row">
            <div className="col-lg-12 text-center ">
              <h3 className='fh3allcourses text-center'>Streams & Courses
              </h3>
              <p className='textmbbs pt-3'>Are you the kind of student who is clear from the get go as to what you want to become and which course to study to get there? Or are you unsure about which career path will work for you and confused about which course to pursue? Either way, making an informed decision at the right stage is critical to having a successful and fulfilling career.</p>
            </div>
          </div>
        </div>

        <div className="container-fluid pt-3 pt-md-0">
          <div className="row">
            <div className="col-lg-12 text-center  pt-3">
              <div className="row">
                <OwlCarousel {...owlOptions} className="topTrendingCourses loop_nav owl-carousel owl-theme ">



                  <div className="item">
                    <div className="card card-body-background">
                      <img
                        className="img-fluid"
                        alt="Medical"
                        src={Medical}
                      />
                      <div className="card-body">
                        <h5 className="card-title">Medical</h5>
                      </div>
                    </div>

                  </div>
                  <div className="item">
                    <div className="card card-body-background">
                      <img
                        className="img-fluid"
                        alt="Dental"
                        src={Dental}
                      />
                      <div className="card-body">
                        <h5 className="card-title">Dental</h5>
                      </div>
                    </div>

                  </div>
                  <div className="item">
                    <div className="card card-body-background">
                      <img
                        className="img-fluid"
                        alt="Ayush"
                        src={Ayush}
                      />
                      <div className="card-body">
                        <h5 className="card-title">Ayush</h5>
                      </div>
                    </div>

                  </div>
                  <div className="item">
                    <div className="card card-body-background">
                      <img
                        className="img-fluid"
                        alt="Nursing"
                        src={Nursing}
                      />
                      <div className="card-body">
                        <h5 className="card-title">Nursing</h5>
                      </div>
                    </div>

                  </div>
                  <div className="item">
                    <div className="card card-body-background">
                      <img
                        className="img-fluid"
                        alt="Pharmacy"
                        src={Pharmacy}
                      />
                      <div className="card-body">
                        <h5 className="card-title">Pharmacy</h5>
                      </div>
                    </div>

                  </div>
                  <div className="item">
                    <div className="card card-body-background">
                      <img
                        className="img-fluid"
                        alt="Allied"
                        src={Allied}
                      />
                      <div className="card-body">
                        <h5 className="card-title">Allied</h5>
                      </div>
                    </div>

                  </div>
                  <div className="item">
                    <div className="card card-body-background">
                      <img
                        className="img-fluid"
                        alt="Veterinary"
                        src={Veterinary}
                      />
                      <div className="card-body">
                        <h5 className="card-title">Veterinary</h5>
                      </div>
                    </div>

                  </div>
                  <div className="item">
                    <div className="card card-body-background">
                      <img
                        className="img-fluid"
                        alt="Engineering"
                        src={Engineering}
                      />
                      <div className="card-body">
                        <h5 className="card-title">Engineering</h5>
                      </div>
                    </div>

                  </div>
                  <div className="item">
                    <div className="card card-body-background">
                      <img
                        className="img-fluid"
                        alt="Architecture"
                        src={Architecture}
                      />
                      <div className="card-body">
                        <h5 className="card-title">Architecture</h5>
                      </div>
                    </div>

                  </div>
                  <div className="item">
                    <div className="card card-body-background">
                      <img
                        className="img-fluid"
                        alt="Management"
                        src={Management}
                      />
                      <div className="card-body">
                        <h5 className="card-title">Management</h5>
                      </div>
                    </div>

                  </div>
                  <div className="item">
                    <div className="card card-body-background">
                      <img
                        className="img-fluid"
                        alt="Science"
                        src={Science}
                      />
                      <div className="card-body">
                        <h5 className="card-title">Science</h5>
                      </div>
                    </div>

                  </div>
                  <div className="item">
                    <div className="card card-body-background">
                      <img
                        className="img-fluid"
                        alt="Commerce"
                        src={Commerce}
                      />
                      <div className="card-body">
                        <h5 className="card-title">Commerce</h5>
                      </div>
                    </div>

                  </div>
                  <div className="item">
                    <div className="card card-body-background">
                      <img
                        className="img-fluid"
                        alt="Computer"
                        src={Computer}
                      />
                      <div className="card-body">
                        <h5 className="card-title">Computer</h5>
                      </div>
                    </div>

                  </div>
                  <div className="item">
                    <div className="card card-body-background">
                      <img
                        className="img-fluid"
                        alt="Law"
                        src={Law}
                      />
                      <div className="card-body">
                        <h5 className="card-title">Law</h5>
                      </div>
                    </div>

                  </div>




                </OwlCarousel>


              </div>
            </div>
          </div>
        </div>

        <div className="container  pt-md-0">
          <div className="row">
            <div className="col-lg-12 text-center pt-3">

              <p className='textmbbs'>Our experts are here to assist you in securing admission in the best courses offered in the top colleges of Karnataka. We provide end-to-end admission guidance and counselling assistance for all UG and PG courses. Let’s check out the various streams and trending courses in India.</p>
            </div>
          </div>
        </div>


        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center  pt-3">

              <div id="lefttabs-block">
                <div className="col-md-3 col-lg-3 left-tabs-nav-widgets d-none d-sm-block d-lg-block d-md-block" >
                  <ul className="nav nav-tabs" id="myTab">

                    <li className="nav-item"><a href="#sh1" className="nav-link active f700" data-bs-toggle="tab">Medical</a></li>
                    <li className="nav-item"><a href="#sh2" className="nav-link  f700" data-bs-toggle="tab">Dental</a></li>
                    <li className="nav-item"><a href="#sh3" className="nav-link  f700" data-bs-toggle="tab">AYUSH</a></li>
                    <li className="nav-item"><a href="#sh4" className="nav-link  f700" data-bs-toggle="tab">Nursing</a></li>
                    <li className="nav-item"><a href="#sh5" className="nav-link  f700" data-bs-toggle="tab">Pharmacy</a></li>
                    <li className="nav-item"><a href="#sh6" className="nav-link  f700" data-bs-toggle="tab">Allied Health Sciences</a></li>
                    <li className="nav-item"><a href="#sh7" className="nav-link  f700" data-bs-toggle="tab">Veterinary</a></li>
                    <li className="nav-item"><a href="#sh8" className="nav-link  f700" data-bs-toggle="tab">Engineering</a></li>
                    <li className="nav-item"><a href="#sh9" className="nav-link  f700" data-bs-toggle="tab">Architecture</a></li>
                    <li className="nav-item"><a href="#sh10" className="nav-link  f700" data-bs-toggle="tab">Management</a></li>
                    <li className="nav-item"><a href="#sh11" className="nav-link  f700" data-bs-toggle="tab">Science</a></li>
                    <li className="nav-item"><a href="#sh12" className="nav-link  f700" data-bs-toggle="tab">Commerce</a></li>
                    <li className="nav-item" ><a href="#sh13" className="nav-link  f700" data-bs-toggle="tab">Computer Science
                    </a></li>
                    <li className="nav-item" id="topuniversities"><a href="#sh14" className="nav-link  f700" data-bs-toggle="tab">Law</a></li>


                  </ul>
                </div>

                <div className="col-md-9 col-lg-9 right-content-widgets mynewclass mynewclasslandingpage allcourses" >


                  <Hidden lgUp mdUp smUp>

                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id={`panel1a-header`}
                      >
                        <Typography className='f700 ff23'>Medical</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className='d-flex flex-wrap'>

                          <div className="card " >
                            <div className="card-body card-body-background p-2">
                              <h5 className="card-title">UG</h5>
                            </div>
                            <ul className="list-group list-group-flush">


                              <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                                Bachelor of Medicine & Bachelor of Surgery (MBBS)
                              </div> <div ><i className="bi bi-clock colorred"></i>  &nbsp;5.5 Years  <Button className="btnlist " onClick={handleShow}>
                                Apply Now!
                              </Button></div> </li>





                            </ul>

                          </div>
                          <div className="card mt-2" >
                            <div className="card-body card-body-background p-2">
                              <h5 className="card-title">PG</h5>
                            </div>
                            <ul className="list-group list-group-flush">

                              <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                                Doctor of Medicine (MD)
                              </div> <div ><i className="bi bi-clock colorred"></i>  &nbsp;3 Years  <Button className="btnlist " onClick={handleShow}>
                                Apply Now!
                              </Button></div> </li>
                              <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                                Master of Surgery (MS)

                              </div> <div ><i className="bi bi-clock colorred"></i>  &nbsp;3 Years  <Button className="btnlist " onClick={handleShow}>
                                Apply Now!
                              </Button></div> </li>


                            </ul>

                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id={`panel1a-header`}
                      >
                        <Typography className='f700 ff23'>Dental</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className='d-flex flex-wrap'>

                          <div className="card " >
                            <div className="card-body card-body-background p-2">
                              <h5 className="card-title">UG</h5>
                            </div>
                            <ul className="list-group list-group-flush">


                              <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                                Bachelor of Dental Surgery (BDS)
                              </div> <div ><i className="bi bi-clock colorred"></i>  &nbsp;5 Years  <Button className="btnlist " onClick={handleShow}>
                                Apply Now!
                              </Button></div> </li>





                            </ul>

                          </div>
                          <div className="card mt-2" >
                            <div className="card-body card-body-background p-2">
                              <h5 className="card-title">PG</h5>
                            </div>
                            <ul className="list-group list-group-flush">

                              <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                                Master of Dental Surgery (MDS)
                              </div> <div ><i className="bi bi-clock colorred"></i>  &nbsp;3 Years  <Button className="btnlist " onClick={handleShow}>
                                Apply Now!
                              </Button></div> </li>


                            </ul>

                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id={`panel1a-header`}
                      >
                        <Typography className='f700 ff23'>AYUSH</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className='d-flex flex-wrap'>

                          <div className="card " >
                            <div className="card-body card-body-background p-2">
                              <h5 className="card-title">UG</h5>
                            </div>
                            <ul className="list-group list-group-flush">


                              <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                                Bachelor of Ayurvedic Medicine & Surgery (BAMS)
                              </div> <div ><i className="bi bi-clock colorred"></i>  &nbsp;5.5 Years  <Button className="btnlist " onClick={handleShow}>
                                Apply Now!
                              </Button></div> </li>
                              <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                                Bachelor of Unani Medicine & Surgery (BUMS)
                              </div> <div ><i className="bi bi-clock colorred"></i>  &nbsp;5.5 Years  <Button className="btnlist " onClick={handleShow}>
                                Apply Now!
                              </Button></div> </li>
                              <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                                Bachelor of Siddha Medicine & Surgery (BSMS)</div> <div ><i className="bi bi-clock colorred"></i>  &nbsp;5.5 Years  <Button className="btnlist " onClick={handleShow}>
                                  Apply Now!
                                </Button></div> </li>
                              <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                                Bachelor of Homoeopathic Medicine & Surgery (BHMS)
                              </div> <div ><i className="bi bi-clock colorred"></i>  &nbsp;5.5 Years  <Button className="btnlist " onClick={handleShow}>
                                Apply Now!
                              </Button></div> </li>
                              <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                                Bachelor of Naturopathy & Yogic Sciences (BNYS)
                              </div> <div ><i className="bi bi-clock colorred"></i>  &nbsp;5.5 Years  <Button className="btnlist " onClick={handleShow}>
                                Apply Now!
                              </Button></div> </li>




                            </ul>

                          </div>
                          <div className="card mt-2" >
                            <div className="card-body card-body-background p-2">
                              <h5 className="card-title">PG</h5>
                            </div>
                            <ul className="list-group list-group-flush">

                              <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                                Doctor of Medicine (MD)
                              </div> <div ><i className="bi bi-clock colorred"></i>  &nbsp;3 Years  <Button className="btnlist " onClick={handleShow}>
                                Apply Now!
                              </Button></div> </li>
                              <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                                Master of Surgery (MS)
                              </div> <div ><i className="bi bi-clock colorred"></i>  &nbsp;3 Years  <Button className="btnlist " onClick={handleShow}>
                                Apply Now!
                              </Button></div> </li>


                            </ul>

                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id={`panel1a-header`}
                      >
                        <Typography className='f700 ff23'>Nursing</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className='d-flex flex-wrap'>

                          <div className="card " >
                            <div className="card-body card-body-background p-2">
                              <h5 className="card-title">UG</h5>
                            </div>
                            <ul className="list-group list-group-flush">


                              <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                                General Nursing & Midwifery (GNM)
                              </div> <div ><i className="bi bi-clock colorred"></i>  &nbsp;3 Years  <Button className="btnlist " onClick={handleShow}>
                                Apply Now!
                              </Button></div> </li>
                              <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                                Bachelor of Science (B.Sc)
                              </div> <div ><i className="bi bi-clock colorred"></i>  &nbsp;4 Years  <Button className="btnlist " onClick={handleShow}>
                                Apply Now!
                              </Button></div> </li>
                              <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                                Post Basic Bachelor of Science (P.B.B.Sc)
                              </div> <div ><i className="bi bi-clock colorred"></i>  &nbsp;2 Years  <Button className="btnlist " onClick={handleShow}>
                                Apply Now!
                              </Button></div> </li>




                            </ul>

                          </div>
                          <div className="card mt-2" >
                            <div className="card-body card-body-background p-2">
                              <h5 className="card-title">PG</h5>
                            </div>
                            <ul className="list-group list-group-flush">

                              <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                                Master of Science (M.Sc)
                              </div> <div ><i className="bi bi-clock colorred"></i>  &nbsp;2 Years  <Button className="btnlist " onClick={handleShow}>
                                Apply Now!
                              </Button></div> </li>


                            </ul>

                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id={`panel1a-header`}
                      >
                        <Typography className='f700 ff23'>Pharmacy</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className='d-flex flex-wrap'>

                          <div className="card " >
                            <div className="card-body card-body-background p-2">
                              <h5 className="card-title">UG</h5>
                            </div>
                            <ul className="list-group list-group-flush">


                              <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                                Bachelor of Pharmacy (B.Pharm)
                              </div> <div ><i className="bi bi-clock colorred"></i>  &nbsp;4 Years  <Button className="btnlist " onClick={handleShow}>
                                Apply Now!
                              </Button></div> </li>
                              <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                                Diploma in Pharmacy (D.Pharm)
                              </div> <div ><i className="bi bi-clock colorred"></i>  &nbsp;2 Years  <Button className="btnlist " onClick={handleShow}>
                                Apply Now!
                              </Button></div> </li>




                            </ul>

                          </div>
                          <div className="card mt-2" >
                            <div className="card-body card-body-background p-2">
                              <h5 className="card-title">PG</h5>
                            </div>
                            <ul className="list-group list-group-flush">

                              <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                                Doctor of Pharmacy (Pharm.D)
                              </div> <div ><i className="bi bi-clock colorred"></i>  &nbsp;6 years/ 3 Years  <Button className="btnlist " onClick={handleShow}>
                                Apply Now!
                              </Button></div> </li>
                              <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                                Master of Pharmacy (M. Pharm)
                              </div> <div ><i className="bi bi-clock colorred"></i>  &nbsp;2 Years  <Button className="btnlist " onClick={handleShow}>
                                Apply Now!
                              </Button></div> </li>


                            </ul>

                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id={`panel1a-header`}
                      >
                        <Typography className='f700 ff23'>Allied Health Sciences
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className='d-flex flex-wrap'>

                          <div className="card " >
                            <div className="card-body card-body-background p-2">
                              <h5 className="card-title">UG</h5>
                            </div>
                            <ul className="list-group list-group-flush">


                              <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                                Bachelor of Science (B.Sc)
                              </div> <div ><i className="bi bi-clock colorred"></i>  &nbsp;3 Years  <Button className="btnlist " onClick={handleShow}>
                                Apply Now!
                              </Button></div> </li>
                              <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                                Bachelor in Audiology and Speech-Language Pathology (BASLP)
                              </div> <div ><i className="bi bi-clock colorred"></i>  &nbsp;4 Years  <Button className="btnlist " onClick={handleShow}>
                                Apply Now!
                              </Button></div> </li>
                              <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                                Bachelor of Occupational Therapy (BOT)
                              </div> <div ><i className="bi bi-clock colorred"></i>  &nbsp;4 Years  <Button className="btnlist " onClick={handleShow}>
                                Apply Now!
                              </Button></div> </li>




                            </ul>

                          </div>
                          <div className="card mt-2" >
                            <div className="card-body card-body-background p-2">
                              <h5 className="card-title">PG</h5>
                            </div>
                            <ul className="list-group list-group-flush">

                              <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                                Master of Science (M.Sc)
                              </div> <div ><i className="bi bi-clock colorred"></i>  &nbsp;2 Years  <Button className="btnlist " onClick={handleShow}>
                                Apply Now!
                              </Button></div> </li>
                              <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                                Master in Audiology and Speech-Language Pathology (MASLP)
                              </div> <div ><i className="bi bi-clock colorred"></i>  &nbsp;2 Years  <Button className="btnlist " onClick={handleShow}>
                                Apply Now!
                              </Button></div> </li>
                              <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                                Master of Occupational Therapy (MOT)
                              </div> <div ><i className="bi bi-clock colorred"></i>  &nbsp;2 Years  <Button className="btnlist " onClick={handleShow}>
                                Apply Now!
                              </Button></div> </li>


                            </ul>

                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id={`panel1a-header`}
                      >
                        <Typography className='f700 ff23'>Veterinary Science
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className='d-flex flex-wrap'>

                          <div className="card " >
                            <div className="card-body card-body-background p-2">
                              <h5 className="card-title">UG</h5>
                            </div>
                            <ul className="list-group list-group-flush">


                              <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                                Bachelor of Veterinary and Animal Husbandry (B.V.Sc & A.H)
                              </div> <div ><i className="bi bi-clock colorred"></i>  &nbsp;5.5 Years  <Button className="btnlist " onClick={handleShow}>
                                Apply Now!
                              </Button></div> </li>





                            </ul>

                          </div>
                          <div className="card mt-2" >
                            <div className="card-body card-body-background p-2">
                              <h5 className="card-title">PG</h5>
                            </div>
                            <ul className="list-group list-group-flush">

                              <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                                Bachelor of Veterinary Science (M.V.Sc.)
                              </div> <div ><i className="bi bi-clock colorred"></i>  &nbsp;2 Years  <Button className="btnlist " onClick={handleShow}>
                                Apply Now!
                              </Button></div> </li>


                            </ul>

                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id={`panel1a-header`}
                      >
                        <Typography className='f700 ff23'>Engineering</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className='d-flex flex-wrap'>

                          <div className="card " >
                            <div className="card-body card-body-background p-2">
                              <h5 className="card-title">UG</h5>
                            </div>
                            <ul className="list-group list-group-flush">


                              <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                                Bachelor of Engineering (BE)
                              </div> <div ><i className="bi bi-clock colorred"></i>  &nbsp;4 Years  <Button className="btnlist " onClick={handleShow}>
                                Apply Now!
                              </Button></div> </li>
                              <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                                Bachelor of Technology (B.Tech)
                              </div> <div ><i className="bi bi-clock colorred"></i>  &nbsp;4 Years  <Button className="btnlist " onClick={handleShow}>
                                Apply Now!
                              </Button></div> </li>




                            </ul>

                          </div>
                          <div className="card mt-2" >
                            <div className="card-body card-body-background p-2">
                              <h5 className="card-title">PG</h5>
                            </div>
                            <ul className="list-group list-group-flush">

                              <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                                Master of Engineering (ME)
                              </div> <div ><i className="bi bi-clock colorred"></i>  &nbsp;2 Years  <Button className="btnlist " onClick={handleShow}>
                                Apply Now!
                              </Button></div> </li>
                              <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                                Master of Technology (M.Tech)
                              </div> <div ><i className="bi bi-clock colorred"></i>  &nbsp;2 Years  <Button className="btnlist " onClick={handleShow}>
                                Apply Now!
                              </Button></div> </li>


                            </ul>

                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id={`panel1a-header`}
                      >
                        <Typography className='f700 ff23'>Architecture</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className='d-flex flex-wrap'>

                          <div className="card " >
                            <div className="card-body card-body-background p-2">
                              <h5 className="card-title">UG</h5>
                            </div>
                            <ul className="list-group list-group-flush">


                              <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                                Bachelor of Architecture (B.Arch)
                              </div> <div ><i className="bi bi-clock colorred"></i>  &nbsp;5 Years  <Button className="btnlist " onClick={handleShow}>
                                Apply Now!
                              </Button></div> </li>
                              <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                                Bachelor of Planning (B.Plan)
                              </div> <div ><i className="bi bi-clock colorred"></i>  &nbsp;4 Years  <Button className="btnlist " onClick={handleShow}>
                                Apply Now!
                              </Button></div> </li>




                            </ul>

                          </div>
                          <div className="card mt-2" >
                            <div className="card-body card-body-background p-2">
                              <h5 className="card-title">PG</h5>
                            </div>
                            <ul className="list-group list-group-flush">

                              <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                                Master of Architecture (M.Arch)
                              </div> <div ><i className="bi bi-clock colorred"></i>  &nbsp;2 Years  <Button className="btnlist " onClick={handleShow}>
                                Apply Now!
                              </Button></div> </li>

                              <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                                Master of Planning (M.Plan)
                              </div> <div ><i className="bi bi-clock colorred"></i>  &nbsp;2 Years  <Button className="btnlist " onClick={handleShow}>
                                Apply Now!
                              </Button></div> </li>


                            </ul>

                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id={`panel1a-header`}
                      >
                        <Typography className='f700 ff23'>Management</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className='d-flex flex-wrap'>

                          <div className="card " >
                            <div className="card-body card-body-background p-2">
                              <h5 className="card-title">UG</h5>
                            </div>
                            <ul className="list-group list-group-flush">


                              <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                                Bachelor of Business Administration (BBA)
                              </div> <div ><i className="bi bi-clock colorred"></i>  &nbsp;3 Years  <Button className="btnlist " onClick={handleShow}>
                                Apply Now!
                              </Button></div> </li>
                              <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                                Bachelor of Business Management (BBM)
                              </div> <div ><i className="bi bi-clock colorred"></i>  &nbsp;3 Years  <Button className="btnlist " onClick={handleShow}>
                                Apply Now!
                              </Button></div> </li>




                            </ul>

                          </div>
                          <div className="card mt-2" >
                            <div className="card-body card-body-background p-2">
                              <h5 className="card-title">PG</h5>
                            </div>
                            <ul className="list-group list-group-flush">

                              <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                                Master of Business Administration (MBA)
                              </div> <div ><i className="bi bi-clock colorred"></i>  &nbsp;2 Years  <Button className="btnlist " onClick={handleShow}>
                                Apply Now!
                              </Button></div> </li>
                              <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                                Post Graduate Diploma in Management (PGDM)
                              </div> <div ><i className="bi bi-clock colorred"></i>  &nbsp;2 Years  <Button className="btnlist " onClick={handleShow}>
                                Apply Now!
                              </Button></div> </li>


                            </ul>

                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id={`panel1a-header`}
                      >
                        <Typography className='f700 ff23'>Science</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className='d-flex flex-wrap'>

                          <div className="card " >
                            <div className="card-body card-body-background p-2">
                              <h5 className="card-title">UG</h5>
                            </div>
                            <ul className="list-group list-group-flush">


                              <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                                Bachelor of Science (B.Sc)
                              </div> <div ><i className="bi bi-clock colorred"></i>  &nbsp;3 Years  <Button className="btnlist " onClick={handleShow}>
                                Apply Now!
                              </Button></div> </li>





                            </ul>

                          </div>
                          <div className="card mt-2" >
                            <div className="card-body card-body-background p-2">
                              <h5 className="card-title">PG</h5>
                            </div>
                            <ul className="list-group list-group-flush">

                              <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                                Master of Science (M.Sc)
                              </div> <div ><i className="bi bi-clock colorred"></i>  &nbsp;2 Years  <Button className="btnlist " onClick={handleShow}>
                                Apply Now!
                              </Button></div> </li>


                            </ul>

                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id={`panel1a-header`}
                      >
                        <Typography className='f700 ff23'>Commerce</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className='d-flex flex-wrap'>

                          <div className="card " >
                            <div className="card-body card-body-background p-2">
                              <h5 className="card-title">UG</h5>
                            </div>
                            <ul className="list-group list-group-flush">


                              <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                                Bachelor of Commerce (B.Com)
                              </div> <div ><i className="bi bi-clock colorred"></i>  &nbsp;3 Years  <Button className="btnlist " onClick={handleShow}>
                                Apply Now!
                              </Button></div> </li>




                            </ul>

                          </div>
                          <div className="card mt-2" >
                            <div className="card-body card-body-background p-2">
                              <h5 className="card-title">PG</h5>
                            </div>
                            <ul className="list-group list-group-flush">

                              <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                                Master of Commerce (M.Com)
                              </div> <div ><i className="bi bi-clock colorred"></i>  &nbsp;2 Years  <Button className="btnlist " onClick={handleShow}>
                                Apply Now!
                              </Button></div> </li>


                            </ul>

                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id={`panel1a-header`}
                      >
                        <Typography className='f700 ff23'>Computer Science
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className='d-flex flex-wrap'>

                          <div className="card " >
                            <div className="card-body card-body-background p-2">
                              <h5 className="card-title">UG</h5>
                            </div>
                            <ul className="list-group list-group-flush">


                              <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                                Bachelor of Computer Application (BCA)
                              </div> <div ><i className="bi bi-clock colorred"></i>  &nbsp;3 Years  <Button className="btnlist " onClick={handleShow}>
                                Apply Now!
                              </Button></div> </li>
                              <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                                Bachelor of Science (B.Sc)
                              </div> <div ><i className="bi bi-clock colorred"></i>  &nbsp;3 Years  <Button className="btnlist " onClick={handleShow}>
                                Apply Now!
                              </Button></div> </li>




                            </ul>

                          </div>
                          <div className="card mt-2" >
                            <div className="card-body card-body-background p-2">
                              <h5 className="card-title">PG</h5>
                            </div>
                            <ul className="list-group list-group-flush">

                              <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                                Master of Computer Application (MCA)
                              </div> <div ><i className="bi bi-clock colorred"></i>  &nbsp;3 Years  <Button className="btnlist " onClick={handleShow}>
                                Apply Now!
                              </Button></div> </li>
                              <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                                Master of Science (M.Sc)
                              </div> <div ><i className="bi bi-clock colorred"></i>  &nbsp;2 Years  <Button className="btnlist " onClick={handleShow}>
                                Apply Now!
                              </Button></div> </li>


                            </ul>

                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id={`panel1a-header`}
                      >
                        <Typography className='f700 ff23'  id="topuniversities">Law</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className='d-flex flex-wrap'>

                          <div className="card " >
                            <div className="card-body card-body-background p-2">
                              <h5 className="card-title">UG</h5>
                            </div>
                            <ul className="list-group list-group-flush">


                              <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                                Bachelor of Law (LLB)
                              </div> <div ><i className="bi bi-clock colorred"></i>  &nbsp;3 Years  <Button className="btnlist " onClick={handleShow}>
                                Apply Now!
                              </Button></div> </li>
                              <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                                Bachelor of Law (LLB) Integrated
                              </div> <div ><i className="bi bi-clock colorred"></i>  &nbsp;5 Years  <Button className="btnlist " onClick={handleShow}>
                                Apply Now!
                              </Button></div> </li>




                            </ul>

                          </div>
                          <div className="card mt-2" >
                            <div className="card-body card-body-background p-2">
                              <h5 className="card-title">PG</h5>
                            </div>
                            <ul className="list-group list-group-flush">

                              <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                                Master of Law (LLM)
                              </div> <div ><i className="bi bi-clock colorred"></i>  &nbsp;2 Years  <Button className="btnlist " onClick={handleShow}>
                                Apply Now!
                              </Button></div> </li>


                            </ul>

                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>





                  </Hidden>
                  <div className="tab-content left-overviews-section d-none d-sm-block d-lg-block d-md-block">


                    <div className="tab-pane fade show active" id="sh1">
                      <div className="card " >
                        <div className="card-body card-body-background p-2">
                          <h5 className="card-title">UG</h5>
                        </div>
                        <ul className="list-group list-group-flush ">
                          <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>Bachelor of Medicine & Bachelor of Surgery (MBBS)</div> <div className='fright'><i className="bi bi-clock colorred"></i>  &nbsp;5.5 Years  <Button className="btnlist " onClick={handleShow}>
                            Apply Now!
                          </Button></div> </li>

                        </ul>

                      </div>
                      <div className="card mt-4" >
                        <div className="card-body card-body-background p-2">
                          <h5 className="card-title">PG</h5>
                        </div>
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>Doctor of Medicine (MD)
                          </div> <div className='fright'><i className="bi bi-clock colorred"></i>  &nbsp;3 Years  <Button className="btnlist " onClick={handleShow}>
                            Apply Now!
                          </Button></div> </li>
                          <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>Master of Surgery (MS)
                          </div> <div className='fright'><i className="bi bi-clock colorred"></i>  &nbsp;3 Years  <Button className="btnlist " onClick={handleShow}>
                            Apply Now!
                          </Button></div> </li>






                        </ul>

                      </div>


                    </div>
                    <div className="tab-pane fade show " id="sh2">
                      <div className="card " >
                        <div className="card-body card-body-background p-2">
                          <h5 className="card-title">UG</h5>
                        </div>
                        <ul className="list-group list-group-flush ">
                          <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                            Bachelor of Dental Surgery (BDS)
                          </div> <div className='fright'><i className="bi bi-clock colorred"></i>  &nbsp;5 Years  <Button className="btnlist " onClick={handleShow}>
                            Apply Now!
                          </Button></div> </li>

                        </ul>

                      </div>
                      <div className="card mt-4" >
                        <div className="card-body card-body-background p-2">
                          <h5 className="card-title">PG</h5>
                        </div>
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                            Master of Dental Surgery (MDS)

                          </div> <div className='fright'><i className="bi bi-clock colorred"></i>  &nbsp;3 Years  <Button className="btnlist " onClick={handleShow}>
                            Apply Now!
                          </Button></div> </li>







                        </ul>

                      </div>


                    </div>
                    <div className="tab-pane fade show " id="sh3">
                      <div className="card " >
                        <div className="card-body card-body-background p-2">
                          <h5 className="card-title">UG</h5>
                        </div>
                        <ul className="list-group list-group-flush ">
                          <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                            Bachelor of Ayurvedic Medicine & Surgery (BAMS)
                          </div> <div className='fright'><i className="bi bi-clock colorred"></i>  &nbsp;5.5 Years  <Button className="btnlist " onClick={handleShow}>
                            Apply Now!
                          </Button></div> </li>
                          <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                            Bachelor of Unani Medicine & Surgery (BUMS)

                          </div> <div className='fright'><i className="bi bi-clock colorred"></i>  &nbsp;5.5 Years  <Button className="btnlist " onClick={handleShow}>
                            Apply Now!
                          </Button></div> </li>
                          <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                            Bachelor of Siddha Medicine & Surgery (BSMS)

                          </div> <div className='fright'><i className="bi bi-clock colorred"></i>  &nbsp;5.5 Years  <Button className="btnlist " onClick={handleShow}>
                            Apply Now!
                          </Button></div> </li>
                          <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                            Bachelor of Homoeopathic Medicine & Surgery (BHMS)

                          </div> <div className='fright'><i className="bi bi-clock colorred"></i>  &nbsp;5.5 Years  <Button className="btnlist " onClick={handleShow}>
                            Apply Now!
                          </Button></div> </li>
                          <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                            Bachelor of Naturopathy & Yogic Sciences (BNYS)

                          </div> <div className='fright'><i className="bi bi-clock colorred"></i>  &nbsp;5.5 Years  <Button className="btnlist " onClick={handleShow}>
                            Apply Now!
                          </Button></div> </li>
                        </ul>

                      </div>
                      <div className="card mt-4" >
                        <div className="card-body card-body-background p-2">
                          <h5 className="card-title">PG</h5>
                        </div>
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                            Doctor of Medicine (MD)

                          </div> <div className='fright'><i className="bi bi-clock colorred"></i>  &nbsp;3 Years  <Button className="btnlist " onClick={handleShow}>
                            Apply Now!
                          </Button></div> </li>
                          <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                            Master of Surgery (MS)

                          </div> <div className='fright'><i className="bi bi-clock colorred"></i>  &nbsp;3 Years  <Button className="btnlist " onClick={handleShow}>
                            Apply Now!
                          </Button></div> </li>






                        </ul>

                      </div>


                    </div>
                    <div className="tab-pane fade show " id="sh4">
                      <div className="card " >
                        <div className="card-body card-body-background p-2">
                          <h5 className="card-title">UG</h5>
                        </div>
                        <ul className="list-group list-group-flush ">
                          <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                            General Nursing & Midwifery (GNM)
                          </div> <div className='fright'><i className="bi bi-clock colorred"></i>  &nbsp;3 Years  <Button className="btnlist " onClick={handleShow}>
                            Apply Now!
                          </Button></div> </li>
                          <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                            Bachelor of Science (B.Sc)

                          </div> <div className='fright'><i className="bi bi-clock colorred"></i>  &nbsp;4 Years  <Button className="btnlist " onClick={handleShow}>
                            Apply Now!
                          </Button></div> </li>
                          <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                            Post Basic Bachelor of Science (P.B.B.Sc)

                          </div> <div className='fright'><i className="bi bi-clock colorred"></i>  &nbsp;2 Years  <Button className="btnlist " onClick={handleShow}>
                            Apply Now!
                          </Button></div> </li>
                        </ul>

                      </div>
                      <div className="card mt-4" >
                        <div className="card-body card-body-background p-2">
                          <h5 className="card-title">PG</h5>
                        </div>
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                            Master of Science (M.Sc)

                          </div> <div className='fright'><i className="bi bi-clock colorred"></i>  &nbsp;2 Years  <Button className="btnlist " onClick={handleShow}>
                            Apply Now!
                          </Button></div> </li>







                        </ul>

                      </div>


                    </div>
                    <div className="tab-pane fade show " id="sh5">
                      <div className="card " >
                        <div className="card-body card-body-background p-2">
                          <h5 className="card-title">UG</h5>
                        </div>
                        <ul className="list-group list-group-flush ">
                          <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                            Bachelor of Pharmacy (B.Pharm)
                          </div> <div className='fright'><i className="bi bi-clock colorred"></i>  &nbsp;4 Years  <Button className="btnlist " onClick={handleShow}>
                            Apply Now!
                          </Button></div> </li>
                          <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                            Diploma in Pharmacy (D.Pharm)

                          </div> <div className='fright'><i className="bi bi-clock colorred"></i>  &nbsp;2 Years  <Button className="btnlist " onClick={handleShow}>
                            Apply Now!
                          </Button></div> </li>
                        </ul>

                      </div>
                      <div className="card mt-4" >
                        <div className="card-body card-body-background p-2">
                          <h5 className="card-title">PG</h5>
                        </div>
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                            Doctor of Pharmacy (Pharm.D)

                          </div> <div className='fright'><i className="bi bi-clock colorred"></i>  &nbsp;6 years/3 Years  <Button className="btnlist " onClick={handleShow}>
                            Apply Now!
                          </Button></div> </li>
                          <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                            Master of Pharmacy (M. Pharm)

                          </div> <div className='fright'><i className="bi bi-clock colorred"></i>  &nbsp;2 Years  <Button className="btnlist " onClick={handleShow}>
                            Apply Now!
                          </Button></div> </li>






                        </ul>

                      </div>


                    </div>
                    <div className="tab-pane fade show " id="sh6">
                      <div className="card " >
                        <div className="card-body card-body-background p-2">
                          <h5 className="card-title">UG</h5>
                        </div>
                        <ul className="list-group list-group-flush ">
                          <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                            Bachelor of Science (B.Sc)
                          </div> <div className='fright'><i className="bi bi-clock colorred"></i>  &nbsp;3 Years  <Button className="btnlist " onClick={handleShow}>
                            Apply Now!
                          </Button></div> </li>
                          <li className="list-group-item  p-1 fw-bold row d-flex justify-content-center align-content-center"><div className='fw-bold  col-8 pb-2 '>
                            Bachelor in Audiology and Speech-Language Pathology (BASLP)

                          </div> <div className='fright col-4 pe-0'> <div className='custompaddingall'><i className="bi bi-clock colorred"></i>  &nbsp;4 Years  <Button className="btnlist " onClick={handleShow}>
                            Apply Now!
                          </Button></div></div> </li>
                          <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                            Bachelor of Occupational Therapy (BOT)

                          </div> <div className='fright'><i className="bi bi-clock colorred"></i>  &nbsp;4 Years  <Button className="btnlist " onClick={handleShow}>
                            Apply Now!
                          </Button></div> </li>
                        </ul>

                      </div>
                      <div className="card mt-4" >
                        <div className="card-body card-body-background p-2">
                          <h5 className="card-title">PG</h5>
                        </div>
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                            Master of Science (M.Sc)

                          </div> <div className='fright'><i className="bi bi-clock colorred"></i>  &nbsp;2 Years  <Button className="btnlist " onClick={handleShow}>
                            Apply Now!
                          </Button></div> </li>
                          <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                            Master in Audiology and Speech-Language Pathology (MASLP)

                          </div> <div className='fright'><i className="bi bi-clock colorred"></i>  &nbsp;2 Years  <Button className="btnlist " onClick={handleShow}>
                            Apply Now!
                          </Button></div> </li>
                          <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                            Master of Occupational Therapy (MOT)

                          </div> <div className='fright'><i className="bi bi-clock colorred"></i>  &nbsp;2 Years  <Button className="btnlist " onClick={handleShow}>
                            Apply Now!
                          </Button></div> </li>






                        </ul>

                      </div>


                    </div>
                    <div className="tab-pane fade show " id="sh7">
                      <div className="card " >
                        <div className="card-body card-body-background p-2">
                          <h5 className="card-title">UG</h5>
                        </div>
                        <ul className="list-group list-group-flush ">
                          <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                            Bachelor of Veterinary and Animal Husbandry (B.V.Sc & A.H)
                          </div> <div className='fright'><i className="bi bi-clock colorred"></i>  &nbsp;5.5 Years  <Button className="btnlist " onClick={handleShow}>
                            Apply Now!
                          </Button></div> </li>

                        </ul>

                      </div>
                      <div className="card mt-4" >
                        <div className="card-body card-body-background p-2">
                          <h5 className="card-title">PG</h5>
                        </div>
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                            Bachelor of Veterinary Science (M.V.Sc.)

                          </div> <div className='fright'><i className="bi bi-clock colorred"></i>  &nbsp;2 Years  <Button className="btnlist " onClick={handleShow}>
                            Apply Now!
                          </Button></div> </li>







                        </ul>

                      </div>


                    </div>
                    <div className="tab-pane fade show " id="sh8">
                      <div className="card " >
                        <div className="card-body card-body-background p-2">
                          <h5 className="card-title">UG</h5>
                        </div>
                        <ul className="list-group list-group-flush ">
                          <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                            Bachelor of Engineering (BE)
                          </div> <div className='fright'><i className="bi bi-clock colorred"></i>  &nbsp;4 Years  <Button className="btnlist " onClick={handleShow}>
                            Apply Now!
                          </Button></div> </li>
                          <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                            Bachelor of Technology (B.Tech)

                          </div> <div className='fright'><i className="bi bi-clock colorred"></i>  &nbsp;4 Years  <Button className="btnlist " onClick={handleShow}>
                            Apply Now!
                          </Button></div> </li>
                        </ul>

                      </div>
                      <div className="card mt-4" >
                        <div className="card-body card-body-background p-2">
                          <h5 className="card-title">PG</h5>
                        </div>
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                            Master of Engineering (ME)

                          </div> <div className='fright'><i className="bi bi-clock colorred"></i>  &nbsp;2 Years  <Button className="btnlist " onClick={handleShow}>
                            Apply Now!
                          </Button></div> </li>
                          <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                            Master of Technology (M.Tech)

                          </div> <div className='fright'><i className="bi bi-clock colorred"></i>  &nbsp;2 Years  <Button className="btnlist " onClick={handleShow}>
                            Apply Now!
                          </Button></div> </li>






                        </ul>

                      </div>


                    </div>
                    <div className="tab-pane fade show " id="sh9">
                      <div className="card " >
                        <div className="card-body card-body-background p-2">
                          <h5 className="card-title">UG</h5>
                        </div>
                        <ul className="list-group list-group-flush ">
                          <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                            Bachelor of Architecture (B.Arch)
                          </div> <div className='fright'><i className="bi bi-clock colorred"></i>  &nbsp;5 Years  <Button className="btnlist " onClick={handleShow}>
                            Apply Now!
                          </Button></div> </li>
                          <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                            Bachelor of Planning (B.Plan)

                          </div> <div className='fright'><i className="bi bi-clock colorred"></i>  &nbsp;4 Years  <Button className="btnlist " onClick={handleShow}>
                            Apply Now!
                          </Button></div> </li>
                        </ul>

                      </div>
                      <div className="card mt-4" >
                        <div className="card-body card-body-background p-2">
                          <h5 className="card-title">PG</h5>
                        </div>
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                            Master of Architecture (M.Arch)

                          </div> <div className='fright'><i className="bi bi-clock colorred"></i>  &nbsp;2 Years  <Button className="btnlist " onClick={handleShow}>
                            Apply Now!
                          </Button></div> </li>
                          <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                            Master of Planning (M.Plan)

                          </div> <div className='fright'><i className="bi bi-clock colorred"></i>  &nbsp;2 Years  <Button className="btnlist " onClick={handleShow}>
                            Apply Now!
                          </Button></div> </li>






                        </ul>

                      </div>


                    </div>
                    <div className="tab-pane fade show " id="sh10">
                      <div className="card " >
                        <div className="card-body card-body-background p-2">
                          <h5 className="card-title">UG</h5>
                        </div>
                        <ul className="list-group list-group-flush ">
                          <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                            Bachelor of Business Administration (BBA)
                          </div> <div className='fright'><i className="bi bi-clock colorred"></i>  &nbsp;3 Years  <Button className="btnlist " onClick={handleShow}>
                            Apply Now!
                          </Button></div> </li>
                          <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                            Bachelor of Business Management (BBM)

                          </div> <div className='fright'><i className="bi bi-clock colorred"></i>  &nbsp;3 Years  <Button className="btnlist " onClick={handleShow}>
                            Apply Now!
                          </Button></div> </li>
                        </ul>

                      </div>
                      <div className="card mt-4" >
                        <div className="card-body card-body-background p-2">
                          <h5 className="card-title">PG</h5>
                        </div>
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                            Master of Business Administration (MBA)

                          </div> <div className='fright'><i className="bi bi-clock colorred"></i>  &nbsp;2 Years  <Button className="btnlist " onClick={handleShow}>
                            Apply Now!
                          </Button></div> </li>
                          <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                            Post Graduate Diploma in Management (PGDM)

                          </div> <div className='fright'><i className="bi bi-clock colorred"></i>  &nbsp;2 Years  <Button className="btnlist " onClick={handleShow}>
                            Apply Now!
                          </Button></div> </li>






                        </ul>

                      </div>


                    </div>
                    <div className="tab-pane fade show " id="sh11">
                      <div className="card " >
                        <div className="card-body card-body-background p-2">
                          <h5 className="card-title">UG</h5>
                        </div>
                        <ul className="list-group list-group-flush ">
                          <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                            Bachelor of Science (B.Sc)
                          </div> <div className='fright'><i className="bi bi-clock colorred"></i>  &nbsp;3 Years  <Button className="btnlist " onClick={handleShow}>
                            Apply Now!
                          </Button></div> </li>

                        </ul>

                      </div>
                      <div className="card mt-4" >
                        <div className="card-body card-body-background p-2">
                          <h5 className="card-title">PG</h5>
                        </div>
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                            Master of Science (M.Sc)

                          </div> <div className='fright'><i className="bi bi-clock colorred"></i>  &nbsp;2 Years  <Button className="btnlist " onClick={handleShow}>
                            Apply Now!
                          </Button></div> </li>







                        </ul>

                      </div>


                    </div>
                    <div className="tab-pane fade show " id="sh12">
                      <div className="card " >
                        <div className="card-body card-body-background p-2">
                          <h5 className="card-title">UG</h5>
                        </div>
                        <ul className="list-group list-group-flush ">
                          <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                            Bachelor of Commerce (B.Com)
                          </div> <div className='fright'><i className="bi bi-clock colorred"></i>  &nbsp;3 Years  <Button className="btnlist " onClick={handleShow}>
                            Apply Now!
                          </Button></div> </li>

                        </ul>

                      </div>
                      <div className="card mt-4" >
                        <div className="card-body card-body-background p-2">
                          <h5 className="card-title">PG</h5>
                        </div>
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                            Master of Commerce (M.Com)

                          </div> <div className='fright'><i className="bi bi-clock colorred"></i>  &nbsp;2 Years  <Button className="btnlist " onClick={handleShow}>
                            Apply Now!
                          </Button></div> </li>

                        </ul>

                      </div>


                    </div>
                    <div className="tab-pane fade show " id="sh13">
                      <div className="card " >
                        <div className="card-body card-body-background p-2">
                          <h5 className="card-title">UG</h5>
                        </div>
                        <ul className="list-group list-group-flush ">
                          <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                            Bachelor of Computer Application (BCA)
                          </div> <div className='fright'><i className="bi bi-clock colorred"></i>  &nbsp;3 Years  <Button className="btnlist " onClick={handleShow}>
                            Apply Now!
                          </Button></div> </li>
                          <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                            Bachelor of Science (B.Sc)

                          </div> <div className='fright'><i className="bi bi-clock colorred"></i>  &nbsp;3 Years  <Button className="btnlist " onClick={handleShow}>
                            Apply Now!
                          </Button></div> </li>
                        </ul>

                      </div>
                      <div className="card mt-4" >
                        <div className="card-body card-body-background p-2">
                          <h5 className="card-title">PG</h5>
                        </div>
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                            Master of Computer Application (MCA)

                          </div> <div className='fright'><i className="bi bi-clock colorred"></i>  &nbsp;3 Years  <Button className="btnlist " onClick={handleShow}>
                            Apply Now!
                          </Button></div> </li>
                          <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                            Master of Science (M.Sc)

                          </div> <div className='fright'><i className="bi bi-clock colorred"></i>  &nbsp;2 Years  <Button className="btnlist " onClick={handleShow}>
                            Apply Now!
                          </Button></div> </li>






                        </ul>

                      </div>


                    </div>
                    <div className="tab-pane fade show " id="sh14">
                      <div className="card " >
                        <div className="card-body card-body-background p-2">
                          <h5 className="card-title">UG</h5>
                        </div>
                        <ul className="list-group list-group-flush ">
                          <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                            Bachelor of Law (LLB)
                          </div> <div className='fright'><i className="bi bi-clock colorred"></i>  &nbsp;3 Years  <Button className="btnlist " onClick={handleShow}>
                            Apply Now!
                          </Button></div> </li>
                          <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                            Bachelor of Law (LLB) Integrated

                          </div> <div className='fright'><i className="bi bi-clock colorred"></i>  &nbsp;5 Years  <Button className="btnlist " onClick={handleShow}>
                            Apply Now!
                          </Button></div> </li>
                        </ul>

                      </div>
                      <div className="card mt-4" >
                        <div className="card-body card-body-background p-2">
                          <h5 className="card-title">PG</h5>
                        </div>
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item  p-1 fw-bold"><div className='fw-bold d-inline'>
                            Master of Law (LLM)

                          </div> <div className='fright' ><i className="bi bi-clock colorred"></i>  &nbsp;2 Years  <Button className="btnlist " onClick={handleShow}>
                            Apply Now!
                          </Button></div> </li>







                        </ul>

                      </div>


                    </div>






                  </div>

                </div>
              </div>
            </div>
            <div className='col-lg-12 text-center p-3 f700 ' style={{ fontSize: 27 }}>
              & Many More!
              </div>


          </div>

        </div>



      </section >




      <section className="container-fluid  p-0 pb-0"  >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center pt-3">
              <h3 className='fh3allcourses'>Top Universities in Karnataka</h3>
            </div>

            <div className="col-lg-12 text-center pt-3 pb-3"><p className='textmbbs'>Everyone dreams of studying in the best colleges and top universities in India but not everyone gets through. Even students who have secured excellent marks miss out on top college admissions because of lack of clarity and proper guidance. Check out the top universities in Karnataka here and get started with the application process with the guidance of our experts!</p></div>
          </div>
          <div className="col-lg-12 text-center">
            <div className="row row-cols-1 row-cols-md-4 g-4 landgingcolleges">


              <div className="col">
                <div className="card h-100">
                  <img className="card-img-top imgcontain" src={Ramahia} alt="Card image cap" />
                  <div className="card-body">
                    <h5 className="card-title">Ramaiah University of Applied Sciences (RUAS)</h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker" aria-hidden="true"></i>
                      &nbsp; Bangalore
                    </p>
                    <Button variant="primary" className="btn btn-success m-2" onClick={handleShow}>
                      Enquire Now
                    </Button>

                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card h-100">
                  <img className="card-img-top imgcontain" src={Jain} alt="Card image cap" />
                  <div className="card-body">
                    <h5 className="card-title">Jain (Deemed-to-be University)</h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker" aria-hidden="true"></i>
                      &nbsp; Bangalore
                    </p>
                    <Button variant="primary" className="btn btn-success m-2" onClick={handleShow}>
                      Enquire Now
                    </Button>

                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card h-100">
                  <img className="card-img-top imgcontain" src={Christ} alt="Card image cap" />
                  <div className="card-body">
                    <h5 className="card-title">Christ (Deemed-to-be University)</h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker" aria-hidden="true"></i>
                      &nbsp; Bangalore
                    </p>
                    <Button variant="primary" className="btn btn-success m-2" onClick={handleShow}>
                      Enquire Now
                    </Button>

                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card h-100">
                  <img className="card-img-top imgcontain" src={St} alt="Card image cap" />
                  <div className="card-body">
                    <h5 className="card-title">St. Joseph’s University</h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker" aria-hidden="true"></i>
                      &nbsp; Bangalore
                    </p>
                    <Button variant="primary" className="btn btn-success m-2" onClick={handleShow}>
                      Enquire Now
                    </Button>

                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card h-100">
                  <img className="card-img-top imgcontain" src={Yenepoya} alt="Card image cap" />
                  <div className="card-body">
                    <h5 className="card-title">Yenepoya (Deemed-to-be University)</h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker" aria-hidden="true"></i>
                      &nbsp; Bangalore
                    </p>
                    <Button variant="primary" className="btn btn-success m-2" onClick={handleShow}>
                      Enquire Now
                    </Button>

                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card h-100">
                  <img className="card-img-top imgcontain" src={Manipal} alt="Card image cap" />
                  <div className="card-body">
                    <h5 className="card-title">Manipal Academy of Higher Education (MAHE)</h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker" aria-hidden="true"></i>
                      &nbsp; Bangalore
                    </p>
                    <Button variant="primary" className="btn btn-success m-2" onClick={handleShow}>
                      Enquire Now
                    </Button>

                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card h-100">
                  <img className="card-img-top imgcontain" src={Dayanand} alt="Card image cap" />
                  <div className="card-body">
                    <h5 className="card-title">Dayananda Sagar University (DSU)</h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker" aria-hidden="true"></i>
                      &nbsp; Bangalore
                    </p>
                    <Button variant="primary" className="btn btn-success m-2" onClick={handleShow}>
                      Enquire Now
                    </Button>

                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card h-100">
                  <img className="card-img-top imgcontain" src={PES} alt="Card image cap" />
                  <div className="card-body">
                    <h5 className="card-title">PES University</h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker" aria-hidden="true"></i>
                      &nbsp; Bangalore
                    </p>
                    <Button variant="primary" className="btn btn-success m-2" onClick={handleShow}>
                      Enquire Now
                    </Button>

                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card h-100">
                  <img className="card-img-top imgcontain" src={CMR} alt="Card image cap" />
                  <div className="card-body">
                    <h5 className="card-title">CMR University (CMRU)</h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker" aria-hidden="true"></i>
                      &nbsp; Bangalore
                    </p>
                    <Button variant="primary" className="btn btn-success m-2" onClick={handleShow}>
                      Enquire Now
                    </Button>

                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card h-100">
                  <img className="card-img-top imgcontain" src={JSS} alt="Card image cap" />
                  <div className="card-body">
                    <h5 className="card-title">JSS Academy of Higher Education & Research</h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker" aria-hidden="true"></i>
                      &nbsp; Mysore & Bangalore
                    </p>
                    <Button variant="primary" className="btn btn-success m-2" onClick={handleShow}>
                      Enquire Now
                    </Button>

                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card h-100">
                  <img className="card-img-top imgcontain" src={KLE} alt="Card image cap" />
                  <div className="card-body">
                    <h5 className="card-title">KLE Academy of Higher Education & Research
                    </h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker" aria-hidden="true"></i>
                      &nbsp; Belagavi & Bangalore
                    </p>
                    <Button variant="primary" className="btn btn-success m-2" onClick={handleShow}>
                      Enquire Now
                    </Button>

                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card h-100">
                  <img className="card-img-top imgcontain" src={NITTE} alt="Card image cap" />
                  <div className="card-body">
                    <h5 className="card-title">NITTE (Deemed-to-be University)</h5>
                    <p className="card-text">
                      <i className="fa fa-map-marker" id="testimonials" aria-hidden="true"></i>
                      &nbsp; Mangalore & Bangalore
                    </p>
                    <Button variant="primary" className="btn btn-success m-2" onClick={handleShow}>
                      Enquire Now
                    </Button>

                  </div>
                </div>
              </div>






            </div>
          </div>
          <div className='col-lg-12 text-center p-3 f700 ' style={{ fontSize: 27 }}>
            & Many More!</div>


        </div>
      </section >

      <section
        className="testimonial bg-custom-gray pt-3 pb-0"

      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="swin-sc swin-sc-title style-01 text-left dark">
                <h2 className="title dark text-center fh3allcourses">
                  Testimonials
                </h2>
                <div className="title-shape">
                  <span className="shape" />
                </div>
              </div>
            </div>

          </div>
          <div className="swin-sc swin-sc-testimonial builday-mtop35">


            <div className="testi-slider testimonial-slidder">

              <OwlCarousel {...owlOptions2} className="topTrendingCourses loop_nav owl-carousel owl-theme testimonials ">

                <div className="item">
                  <div className="testi-item-inner">
                    <div className="testi-info">
                      <span className="name">
                        Amit Kumar Das
                        <span style={{ color: "gray", fontSize: 12 }}>
                          <i> 3d ago</i>
                        </span>
                      </span>
                    </div>
                    <div className="testi-content">

                      <div className="testi-con ">
                        <iframe src="https://www.youtube.com/embed/I9QCXtvsj54" style={{ width: '100%', height: '310px' }}></iframe>

                        <div className="bs-video-thumbnail">
                          <img
                            src="https://bangalorestudy.com/web_dashboard/assets/images/video-bg-img.jpg"
                            alt="Amit Kumar Das's Testimonial"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="testi-item-inner">
                    <div className="testi-info">
                      <span className="name">
                        Dr. Rajas Sanjeev Mahalle
                        <span style={{ color: "gray", fontSize: 12 }}>
                          <i> 5d ago</i>
                        </span>
                      </span>
                    </div>
                    <div className="testi-content">

                      <div className="testi-con ">
                        <iframe src="https://www.youtube.com/embed/b7wSfcDHBxk" style={{ width: '100%', height: '310px' }}></iframe>

                        <div className="bs-video-thumbnail">
                          <img
                            src="https://bangalorestudy.com/web_dashboard/assets/images/video-bg-img.jpg"
                            alt=" Dr. Rajas Sanjeev Mahalle"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="testi-item-inner">
                    <div className="testi-info">
                      <span className="name">
                        Shalvi
                        <span style={{ color: "gray", fontSize: 12 }}>
                          <i> 1w ago</i>
                        </span>
                      </span>
                    </div>
                    <div className="testi-content">

                      <div className="testi-con ">
                        <iframe src="https://www.youtube.com/embed/eoc9E9pw7Kg" style={{ width: '100%', height: '310px' }}></iframe>


                        <div className="bs-video-thumbnail">
                          <img
                            src="https://bangalorestudy.com/web_dashboard/assets/images/video-bg-img.jpg"
                            alt="Shalvi"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="testi-item-inner">
                    <div className="testi-info">
                      <span className="name">
                        Shonali
                        <span style={{ color: "gray", fontSize: 12 }}>
                          <i> 1m ago</i>
                        </span>
                      </span>
                    </div>
                    <div className="testi-content">

                      <div className="testi-con ">
                        <iframe src="https://www.youtube.com/embed/SpbaSsxIons" style={{ width: '100%', height: '310px' }}></iframe>

                        <div className="bs-video-thumbnail">
                          <img
                            src="https://bangalorestudy.com/web_dashboard/assets/images/video-bg-img.jpg"
                            alt="Shonali"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item ">
                  <div className="testi-item-inner">
                    <div className="testi-info">
                      <span className="name">
                        Rohan Sen
                        <span style={{ color: "gray", fontSize: 12 }}>
                          <i> 1m ago</i>
                        </span>
                      </span>
                    </div>
                    <div className="testi-content">

                      <div className="testi-con ">
                        <iframe src="https://www.youtube.com/embed/sSXI6fFk-Fs" style={{ width: '100%', height: '310px' }}></iframe>

                        <div className="bs-video-thumbnail">
                          <img
                            src="https://bangalorestudy.com/web_dashboard/assets/images/video-bg-img.jpg"
                            alt="Rohan Sen"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </OwlCarousel>
            </div>


          </div>
        </div>
      </section>
      <section className="container-fluid d-flex align-items-center  p-0  " id="Contactus">
        <div className="container-fluid bgimage text-center d-flex justify-content-center align-items imgh" style={{ background: `linear-gradient(341deg, rgb(0 0 0 / 0%), rgb(0 0 0 / 0%)) , url(${bannertalk})` }}>
          <div className='dd '><h2 className='bgblack p-3 font40'  >Take the Right Steps Towards your Dream Career.</h2>
            <Button variant="primary" className="btn btn-success mb-4 m-3 m-md-5 font-white23" onClick={handleShow}>
              Talk to an Expert Now!
            </Button>
          </div>
        </div>
      </section>



      <footer className='pb-1 bgfooter'>
        <div className="container-fluid align-item-start justify-content-between d-none d-md-flex flex-wrap">
          <div className="footer-left  col-md-7 d-flex ps-5">
            <div className="col-md-8">

              <div className="ft-left mb-3 px-5" style={{ marginBottom: 20 }}>
                <img src="preprod/nursing_admission/assets/images/footer-bg.png" className="imgfooter" alt="bangalorestudy " />
              </div>


            </div>

            <div className="col-md-6 footer-left text-center pe-5" >
              <p className="font-white23 pe-5">Social Connect</p>
              <p style={{ marginTop: 10, marginBottom: 14 }} className='d-block pe-5'>
                <a target="_blank " href="https://www.facebook.com/bangalorestudy ">
                  <i className="fa fa-facebook" />&nbsp; &nbsp;&nbsp;
                </a>
                <a target="_blank " href="https://twitter.com/BangaloreStudy2 ">
                <i className="fa-brands fa-x-twitter" />&nbsp; &nbsp;&nbsp;
                </a>
                <a
                  target="_blank "
                  href="https://www.youtube.com/channel/UCZP40_ivVcdelNOVhmQFr7w "
                >
                  {" "}
                  <i className="fa fa-youtube" />&nbsp;&nbsp;&nbsp;
                </a>
                <a target="_blank " href="https://instagram.com/bangalorestudy ">
                  <i className="fa fa-instagram" />&nbsp;&nbsp;&nbsp;
                </a>
                <a
                  target="_blank "
                  href="https://in.linkedin.com/in/bangalorestudy-com-63558488 "
                >
                  {" "}
                  <i className="fa fa-linkedin" />&nbsp;&nbsp;&nbsp;
                </a>
                <a target="_blank " href="https://in.pinterest.com/bangalorestudy7608/ ">
                  <i className="fa fa-pinterest" />&nbsp;&nbsp;&nbsp;
                </a>
              </p>

            </div>

          </div>




          <div className="footer-right col-md-5 offeset-md-1  d-flex">
            <div className="social-unit col-md-5 w-100 px-3 d-flex justify-content-end" >
              <div><p className=" px-5 font-white23" style={{ marginBottom: 10 }}>Connect with us</p>

                <a
                  href="tel:08022454991 "
                  style={{ color: "white", fontSize: 14, fontWeight: 400 }}
                >
                  <i className="fa fa-phone" style={{ fontSize: 13 }} /> 080-22454991
                </a>
                ,
                <a
                  href="tel:08026631169 "
                  style={{ color: "white", fontSize: 14, fontWeight: 400 }}
                >
                  <i className="fa fa-phone" style={{ fontSize: 13 }} /> 080-26631169
                </a>
                <br />
                <div className="bs-phone " style={{ display: "contents" }}>
                  <a
                    href="tel:18001208696 "
                    style={{ color: "white", fontWeight: 400, fontSize: 14 }}
                  >
                    <i className="fa fa-phone" style={{ fontSize: 13 }} /> 1800 120 8696(Toll
                    Free)
                  </a>
                </div>
                <p>
                  Dubai :{" "}
                  <a
                    href="tel:971502436552 "
                    style={{ color: "white", fontSize: 14, fontWeight: 400 }}
                  >
                    <i className="fa fa-phone" style={{ fontSize: 13 }} /> +971502436552{" "}
                  </a>
                  ,{" "}
                  <a
                    href="tel:971504955123 "
                    style={{ color: "white", fontSize: 14, fontWeight: 400 }}
                  >
                    <i className="fa fa-phone" style={{ fontSize: 14 }} /> +971504955123{" "}
                  </a>
                </p>



              </div>
            </div>
          </div>

          <div className='col-12 text-center'><p style={{ fontSize: 12 }}>
            {" "}
            © 2025Bangalorestudy.com. All Rights Reserved.
          </p>

          </div>


        </div>
        <div className="container align-item-start justify-content-between d-md-none">
          <div className="footer-left pt-5 col-md-7 text-center">

            <div
              className="ft-left mb-3"
              style={{ justifyContent: "flex-start" }}
            >
              <img src="preprod/nursing_admission/assets/images/footer-bg.png " className="imgfooter" alt="bangalorestudy " />
            </div>
          </div>
          <div className="footer-right col-md-5 offeset-md-1 py-t d-flex">
            <div className="app-unit col-md-7  col-md-5 ">
              <div className="store-wrapper">
                <p className='font-white23 pb-2'>Social Connect</p>
                <a target="_blank " href="https://www.facebook.com/bangalorestudy ">
                  <i className="fa fa-facebook" />&nbsp;&nbsp;
                </a>
                <a target="_blank " href="https://twitter.com/BangaloreStudy2 ">
                  <i className="fa fa-twitter" />&nbsp;&nbsp;
                </a>
                <a
                  target="_blank "
                  href="https://www.youtube.com/channel/UCZP40_ivVcdelNOVhmQFr7w "
                >
                  {" "}
                  <i className="fa fa-youtube" />&nbsp;&nbsp;
                </a>
                <a target="_blank " href="https://instagram.com/bangalorestudy ">
                  <i className="fa fa-instagram" />&nbsp;&nbsp;
                </a>
                <a
                  target="_blank "
                  href="https://in.linkedin.com/in/bangalorestudy-com-63558488 "
                >
                  {" "}
                  <i className="fa fa-linkedin" />&nbsp;&nbsp;
                </a>
                <a
                  target="_blank "
                  href="https://in.pinterest.com/bangalorestudy7608/ "
                >
                  <i className="fa fa-pinterest" />&nbsp;&nbsp;
                </a>
              </div>
            </div>
            <div className="social-unit col-md-5 mt-4">
              <div>
                <p className="font-white23">Connect with us</p><a
                  href="tel:08022454991 "
                  style={{ color: "white", fontSize: 14, fontWeight: 400 }}
                ><i className="fa fa-phone" style={{ fontSize: 13 }} />080-22454991</a>,
                <a
                  href="tel:08026631169 "
                  style={{ color: "white", fontSize: 14, fontWeight: 400 }}
                >
                  <i className="fa fa-phone" style={{ fontSize: 13 }} />
                  080-26631169
                </a>
                <div className="bs-phone " style={{ display: "contents" }}>
                  {" "}
                  <br />
                  <a
                    href="tel:18001208696 "
                    style={{ color: "white", fontWeight: 400, fontSize: 14 }}
                  >
                    <i className="fa fa-phone" style={{ fontSize: 13 }} />
                    1800 120 8696(Toll Free)
                  </a>
                </div>
                <p>
                  Dubai :{" "}
                  <a
                    href="tel:970502436552 "
                    style={{ color: "white", fontSize: 14, fontWeight: 400 }}
                  >
                    <i className="fa fa-phone" style={{ fontSize: 13 }} />{" "}
                    +970502436552{" "}
                  </a>
                  ,{" "}
                  <a
                    href="tel:971504955123 "
                    style={{ color: "white", fontSize: 14, fontWeight: 400 }}
                  >
                    <i className="fa fa-phone" style={{ fontSize: 13 }} />{" "}
                    +971504955123{" "}
                  </a>
                </p>
              </div>
            </div>
            <p className="">
              {" "}
              © 2023 Bangalorestudy.com. All Rights Reserved.
            </p>
          </div>
        </div>
      </footer>
      <a
        href="https://wa.me/+919036020016"
        style={{
          position: "fixed",
          width: 63,
          height: 64,
          bottom: 7,
          right: 24,
          // backgroundColor: "#25d366",
          color: "#fff",
          borderRadius: 50,
          textAlign: "center",
          fontSize: 44,
          boxShadow: "2px 2px 3px #999",
          zIndex: 1059
        }}
        target="_blank "
      >
        {/* <i className="bi bi-whatsapp my-float " /> */}
                <img src={whtsp} alt='whtsplogo' style={{ width: "66px" }}></img>
        
      </a>

      <Modal show={show} onHide={handleClose}>

        <Modal.Body>

          <div className="heading-popup ">
            <h4 className='text-center'>Fill in Your Details to Get Started with the Application Process.</h4>
          </div>
          <form
            onSubmit={onSubmit}

          >

            <fieldset>
              <input
                type="text"
                id="name1"
                name="name"
                placeholder="Name"
                required
                value={values.name} onChange={set('name')}
              />
            </fieldset>

            <small className="text-danger " />
            <fieldset className="form-group group_field">
              <input
                type="email"
                id="email1"
                name="email"
                placeholder="Email ID"
                required
                value={values.email} onChange={set('email')}
              />
            </fieldset>
            <small className="text-danger " />
            <fieldset className="form-group group_field">
              <input
                name="contact"
                type="number"
                id="contact1"

                required
                placeholder="Contact No."
                value={values.contact} onChange={set('contact')}
              />
            </fieldset>
            <fieldset className="form-group group_field">
              <input
                type="text"
                id="course_in_mind"
                name="course_in_mind"
                placeholder="Interested Course"
                required
                value={values.course_in_mind} onChange={set('course_in_mind')}
              />
            </fieldset>


            <fieldset className="form-group group_field">
              <small className="text-danger " />
              <fieldset className="form-group group_field">

                <select name="location" id="location1"
                  value={values.location} onChange={set('location')} required>
                  <option value=" ">--select state--</option>
                  <option value="Andhra Pradesh ">Andhra Pradesh</option>
                  <option value="Arunachal Pradesh ">Arunachal Pradesh</option>
                  <option value="Assam ">Assam</option>
                  <option value="Bihar ">Bihar</option>
                  <option value="Chhattisgarh ">Chhattisgarh</option>
                  <option value="Goa ">Goa</option>
                  <option value="Gujarat ">Gujarat</option>
                  <option value="Haryana ">Haryana</option>
                  <option value="Himachal Pradesh ">Himachal Pradesh</option>
                  <option value="Jammu & Kashmir ">Jammu &amp; Kashmir</option>
                  <option value="Jharkhand ">Jharkhand</option>
                  <option value="Karnataka ">Karnataka</option>
                  <option value="Kerala ">Kerala</option>
                  <option value="Madhya Pradesh ">Madhya Pradesh</option>
                  <option value="Maharashtra ">Maharashtra</option>
                  <option value="Manipur ">Manipur</option>
                  <option value="Meghalaya ">Meghalaya</option>
                  <option value="Mizoram ">Mizoram</option>
                  <option value="Nagaland ">Nagaland</option>
                  <option value="Odisha ">Odisha</option>
                  <option value="Punjab ">Punjab</option>
                  <option value="Rajasthan ">Rajasthan</option>
                  <option value="Sikkim ">Sikkim</option>
                  <option value="Tamil Nadu ">Tamil Nadu</option>
                  <option value="Tripura ">Tripura</option>
                  <option value="Telangana ">Telangana</option>
                  <option value="Uttarakhand ">Uttarakhand</option>
                  <option value="Uttar Pradesh ">Uttar Pradesh</option>
                  <option value="West Bengal ">West Bengal</option>
                  <option value="Andaman & Nicobar ">Andaman &amp; Nicobar</option>
                  <option value="Chandigarh ">Chandigarh</option>
                  <option value="Dadra and Nagar Haveli ">
                    Dadra and Nagar Haveli
                  </option>
                  <option value="Daman & Diu ">Daman &amp; Diu</option>
                  <option value="Delhi ">Delhi</option>
                  <option value="Lakshadweep ">Lakshadweep</option>
                  <option value="Puducherry ">Puducherry</option>
                </select>


              </fieldset>
              <small className="text-danger " />
              <fieldset className="form-group ">
                <input
                  value={values.description} onChange={set('description')}
                  type="text "
                  name="description"
                  id="description1"
                  placeholder="Message (optional) "
                />
              </fieldset>
              <small className="text-danger " />
              <fieldset className="form-group ">
                <button type="submit" className='btn btn-primary'>Submit</button>
              </fieldset>
            </fieldset>
          </form>

        </Modal.Body>

      </Modal>





    </>
  );
};

export default BscNursing;