import React, { FC, useEffect, useState, useContext, useCallback } from 'react';
import axios from 'src/utils/axiosintercpt';



// import logo from 'src/assets/assets/img/logo1.png';
import logo from 'src/assets/yenepoyalandingpage/Yenepoya-Logo-web.png';
import Ibm from 'src/assets/yenepoyalandingpage/IBM_logo.png';
import Ibmicon from 'src/assets/yenepoyalandingpage/IBM Icon.png';
import Duration from 'src/assets/yenepoyalandingpage/Durtion Icon.png';
import Mode from 'src/assets/yenepoyalandingpage/Mode Icon.png';
import microsoft from 'src/assets/yenepoyalandingpage/microsoft.png';
import tcsion from 'src/assets/yenepoyalandingpage/tcsion.png';
import logo2 from 'src/assets/synslandingpage/logo2.png';
import { pConfig } from 'src/config';
import Modal from 'react-bootstrap/Modal';
import Helmet from 'react-helmet';
import Button from 'react-bootstrap/Button';
import ManagementCourseBG from 'src/assets/yenepoyalandingpage/bgbanner.jpg';
import Navbar0 from '../Navbar';
import Footer from '../Footer';
import whtsp from 'src/assets/Alliancecollege/whatsapp.gif';

import Sri from 'src/assets/BdsKarnataka/Sri.png';

import Ramaiah from 'src/assets/BdsKarnataka/Ramaiah.png';
import Yenepoya from 'src/assets/BdsKarnataka/Yenepoya.png';
import Vydehi from 'src/assets/BdsKarnataka/Vydehi.png';
import RajaRajeswari from 'src/assets/BdsKarnataka/RajaRajeswari.png';
import Oxford from 'src/assets/BdsKarnataka/Oxford.png';




import Manipal from 'src/assets/BdsKarnataka/Manipal.png';
import Shetty from 'src/assets/BdsKarnataka/Shetty.png';
import JSS from 'src/assets/BdsKarnataka/JSS.png';
import KLE from 'src/assets/BdsKarnataka/KLE.png';
import SDM from 'src/assets/BdsKarnataka/SDM.png';
import Dayananda from 'src/assets/BdsKarnataka/Dayananda.png';
import Hassle from 'src/assets/BdsKarnataka/Hassle.png';
import Assistance from 'src/assets/BdsKarnataka/Assistance.png';
import Step from 'src/assets/BdsKarnataka/Step.png';
import Guidance from 'src/assets/BdsKarnataka/Guidance.png';
import Exclusive from 'src/assets/BdsKarnataka/Exclusive.png';
import Stay from 'src/assets/BdsKarnataka/Stay.png';







import Increased from 'src/assets/BdsKarnataka/Increased.png';
import Low from 'src/assets/BdsKarnataka/Low.png';
import Renowned from 'src/assets/BdsKarnataka/Renowned.png';




import contactbanner from 'src/assets/yenepoyalandingpage/banner2.jpg';
import middilebanner from 'src/assets/BdsKarnataka/Middle-Banner.jpg';
import inf1 from 'src/assets/yenepoyalandingpage/Yenepoya-Photos-1.png';
import inf2 from 'src/assets/yenepoyalandingpage/Yenepoya Photos-2.png';
import inf3 from 'src/assets/yenepoyalandingpage/Yenepoya Photos-3.png';
import inf4 from 'src/assets/yenepoyalandingpage/Yenepoya Photos-4.png';
import inf5 from 'src/assets/yenepoyalandingpage/Yenepoya Photos-5.png';
import inf6 from 'src/assets/yenepoyalandingpage/Yenepoya Photos-6.png';
import Faqimg from 'src/assets/yenepoyalandingpage/FAQ.png';

import acc1 from 'src/assets/yenepoyalandingpage/Accreditation-1.png';
import acc2 from 'src/assets/yenepoyalandingpage/Accreditation-2.png';
import acc3 from 'src/assets/yenepoyalandingpage/Accreditation-3.png';
import acc4 from 'src/assets/yenepoyalandingpage/Accreditation-4.png';
import acc5 from 'src/assets/yenepoyalandingpage/Accreditation-5.png';
import acc6 from 'src/assets/yenepoyalandingpage/Accreditation-6.png';
import acc7 from 'src/assets/yenepoyalandingpage/Accreditation-7.png';

import "./styles.css";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';


const BdsKarnataka: FC = () => {
  const navLinks = [
    { label: 'Home', id: 'home' },
    { label: 'Why Yenepoya?', id: 'why' },
    { label: 'About College', id: 'colleges' },
    { label: 'M.Sc Courses', id: 'courses' },
    { label: 'Infrastructure', id: 'infrastructure' },
    // { label: 'Top Dental Colleges', id: 'topcolleges' },
    { label: 'FAQs', id: 'FAQs' },
    { label: 'Contact Us', id: 'Contactus' },

  ];

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();


  const [validated, setValidated] = useState(false);


  const handleSubmit = (event: { currentTarget: any; preventDefault: () => void; stopPropagation: () => void; },) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    // <GlobalPopupEnquiry className="btn btn btn-primary" title="Please fill in your details to receive your detailed rank prediction." />
    event.preventDefault();
    console.log(event);
    setValidated(true);
    // <GlobalPopupEnquiry className="btn btn btn-primary" title="Please fill in your details to receive your detailed rank prediction." />

  };

  const [values, setValues] = useState({
    name: '', email: '', contact: '', course_in_mind: '', location: '', description: ''
  });
  const set = name => {
    return ({ target: { value } }) => {
      setValues(oldValues => ({ ...oldValues, [name]: value }));
    }
  };

  const onSubmit = async (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('email', values.email);
    formData.append('contact', values.contact);
    formData.append('course_in_mind', values.course_in_mind);
    formData.append('location', values.location);
    formData.append('description', values.description);
    formData.append('current_url', window.location.href);
    formData.append('sourse', 'M.Sc at Yenepoya Bangalore 2025-26');

    try {
      const response = await axios.post('api/website/home/landingpageenquiry', formData);
      // console.log('response: ', response);


      if (response.data.status) {

        history.push('/thank-you');
      }
    } catch (err) {
      console.error(err);

      enqueueSnackbar('Please try again', {
        variant: 'error',
      });
    }


  }

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const owlOptionsacc = {
    margin: 10, nav: true, autoplay: true, autoplayTimeout: 2000,
    // responsiveClass: true,
    responsive: {
      0: { items: 1, dots: false, nav: false, loop: true },
      400: { items: 1, dots: false, nav: false, loop: true },
      600: { items: 1, dots: false, nav: true },
      700: { items: 2, dots: false, nav: true },
      1000: {
        items: 4,
        nav: false,
        loop: true
      },
      1440: {
        items: 5,
        nav: false,
        loop: true
      }
    },
  };
  const owlOptions = {
    margin: 10, nav: true, autoplay: true, autoplayTimeout: 2000,
    responsiveClass: true,
    responsive: {
      0: { items: 1, dots: false, nav: false, loop: true },
      400: { items: 1, dots: false, nav: false, loop: true },
      600: { items: 1, dots: false, nav: true },
      700: { items: 2, dots: false, nav: true },
      1000: {
        items: 1,
        nav: false,
        loop: true
      },
      1440: {
        items: 1,
        nav: false,
        loop: true
      }
    },
  };

   // CSS style for carousel items
   const itemStyle = {
    width: '200px', // Set your desired width
    height: '200px',
   
     // Set your desired height
  };

  const navigate = (e, id) => {
    e && e.preventDefault(); // to avoid the link from redirecting
    const elementToView = document.getElementById(id);
    elementToView.scrollIntoView();
  }

  return (


    <>
      <Helmet>
        <title>Yenepoya University, Bangalore M.Sc Courses & Fees 2023</title>
        <meta name="description"
          content="Check out the list of M.Sc courses offered by Yenepoya (Deemed-to-be University), Bangalore with admission process, eligibility, duration, fees, infrastructure, etc. Apply Now!

          " />
        <link rel="canonical" href="https://bangalorestudy.com/msc-courses-yenepoya-university-bangalore" />
        <meta
          name="viewport"
          content="width=device-width; initial-scale=1.0; maximum-scale=1.0; user-scalable=0;"
        />
        <link rel="stylesheet" href="preprod/nursing_admission/assets/css/dental.css" />
        <link rel="stylesheet" href="preprod/nursing_admission/assets/css/style.min.css" />
        <link rel="stylesheet" href="preprod/nursing_admission/assets/css/resp.min.css" />
        <link
          href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
          rel="stylesheet"
          integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN"
          crossOrigin="anonymous"
        />
        <script>
          {`
        window.dataLayer = window.dataLayer || [];
        function gtag() {dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', 'AW-990332405');
        `}
        </script>


        <script>{`
      (function (w, d, s, l, i) {
        w[l] = w[l] || []; w[l].push({
          'gtm.start':
        new Date().getTime(), event: 'gtm.js'
			}); var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
        'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
		})(window, document, 'script', 'dataLayer', 'GTM-MGDJ5RD');
    `}</script>

        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-990332405"></script>
        <script src="preprod/medical_admission_asset/js/jquery.min.js"></script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-140595938-1"></script>





        <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>

        <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.16.0/umd/popper.min.js"></script>

        {/* <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script> */}

        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/2.1.3/jquery.min.js"></script>
        <script src="https://cdn.jsdelivr.net/jquery.slick/1.4.1/slick.min.js"></script>

        <script src="preprod/medical_admission_asset/js/jquery.min.js"></script>
        <script src="preprod/nursing_admission/assets/js/jquery.min.js"></script>
        <script type="text/javascript" src="preprod/nursing_admission/assets/js/jquery.fancybox.pack.js"></script>
        <script src="preprod/nursing_admission/assets/js/owl.carousel.min.js"></script>

        <script type="text/javascript"
          src="https://bangalorestudy.com/veterinary_assets/asset/js/owl.carousel.min.js "></script>


        <script type="text/javascript">{`
      

setTimeout(function () {
  $('.widget-visible').remove();
}, 15000);
setTimeout(function () {
  $('.widget-visible').remove();
}, 10000);

setTimeout(function () {
  $('.widget-visible').remove();
}, 5000);

setTimeout(function () {
  $('.widget-visible').remove();
}, 3000);

setTimeout(function () {
  $('.widget-visible').remove();
}, 2000);


setTimeout(function () {
  $('.widget-visible').remove();
}, 1000);
$(document).ready(function () {
  $("#formoid").submit(function (event) {
    console.log("12");
    /* stop form from submitting normally */
    event.preventDefault();

    /* get the action attribute from the <form action=""> element */
    var $form = $(this),
      url = $form.attr('action');

    /* Send the data using post with element id name and name2*/

    var posting = $.post(url, {
      name: $('#name').val(),
      email: $('#email').val(),
      contact: $('#contact').val(),
      college_name: $('#college_name').val(),
      current_url: window.location.href,
      course_in_mind: $('#course_name').val(),
      location: $('#location').val(),
      sourse: "M.Sc at Yenepoya Bangalore 2025-26",
      description: $('#description').val(),

    });

    // console.log($('#name').val(),$('#email').val(), $('#contact').val(),$('#college_name').val(),);

    /* Alerts the results */
    posting.done(function (data) {
      // console.log(data);
      // console.log("1");
      $('#result').text('Thank you we will get back to you');
      $('#result').addClass('alert alert-success');
      $('#result').addClass('mt-1');
      $("#result").show().delay(5000).fadeOut();

      $('#formoid').each(function () {
        this.reset();
        location.href = "https://bangalorestudy.com/thank-you"
      });

    });
    posting.fail(function () {
      // console.log(data);
      // console.log("2");
      $('#result').text('Sorry please try again');
      $('#result').addClass('alert alert-warning');
      $('#result').addClass('mt-1');
      $("#result").show().delay(5000).fadeOut();
      $('#formoid').each(function () {
        this.reset();
      });
    });
  });
  $("#formoid1").submit(function (event) {
    console.log("12");
    /* stop form from submitting normally */
    event.preventDefault();

    /* get the action attribute from the <form action=""> element */
    var $form = $(this),
      url = $form.attr('action');

    /* Send the data using post with element id name and name2*/
    var posting = $.post(url, {
      name: $('#name1').val(),
      email: $('#email1').val(),
      contact: $('#contact1').val(),
      college_name: $('#college_name1').val(),
      current_url: window.location.href,
      // course_in_mind: $('#course_name').val(),
      location: $('#location1').val(),
      // description: $('#description1').val(),

    });

    /* Alerts the results */
    posting.done(function (data) {
      // console.log(data);
      // console.log("1");
      $('#result').text('Thank you we will get back to you');
      $('#result').addClass('alert alert-success');
      $('#result').addClass('mt-1');
      $("#result").show().delay(5000).fadeOut();

      $('#formoid1').each(function () {
        this.reset();
        location.href = "https://bangalorestudy.com/thank-you"
      });

    });
    posting.fail(function () {
      // console.log(data);
      // console.log("2");
      $('#result').text('Sorry please try again');
      $('#result').addClass('alert alert-warning');
      $('#result').addClass('mt-1');
      $("#result").show().delay(5000).fadeOut();
      $('#formoid1').each(function () {
        this.reset();
      });
    });
  });
})


`}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(
            {
              "@context": "https://schema.org",
              "@type": "LocalBusiness",
              "name": "Bangalore Study",
              "image": `${pConfig.siteUrl}home_page/images/logo.png`,
              "@id": `${pConfig.siteUrl}contact`,
              "url": `${pConfig.siteUrl}`,
              "telephone": "+91-9973733030",
              "priceRange": "INR",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "#3/75, 'D' Main, East End,Jayanagar 9th Block,Bangalore - 560069",
                "addressLocality": "Bengaluru",
                "postalCode": "560069",
                "addressCountry": "IN"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 12.918769,
                "longitude": 77.598285
              },
              "openingHoursSpecification": {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": [
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday",
                  "Saturday"
                ],
                "opens": "10:00",
                "closes": "19:00"
              }
            }
          )}
        </script>
        <script>
          {`
        var $titleTab = $('.title_tab');
        $('.Accordion_item:eq(0)').find('.title_tab').addClass('active').next().stop().slideDown(300);
        $('.Accordion_item:eq(0)').find('.inner_content').find('p').addClass('show');
        $titleTab.on('click', function (e) {
            e.preventDefault();
            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
                $(this).next().stop().slideUp(500);
                $(this).next().find('p').removeClass('show');
            } else {
                $(this).addClass('active');
                $(this).next().stop().slideDown(500);
                $(this).parent().siblings().children('.title_tab').removeClass('active');
                $(this).parent().siblings().children('.inner_content').slideUp(500);
                $(this).parent().siblings().children('.inner_content').find('p').removeClass('show');
                $(this).next().find('p').addClass('show');
            }
        });
      `}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(
            {
              "@context": "https://schema.org",
              "@type": "Website",
              "name": "Bangalore Study",
              "url": `${pConfig.siteUrl}`,
              "headline": "Bangalore Study | #1 College Admissions in Bangalore",
              "description": "Bangalorestudy.com by Learntech Edu Solution Pvt. Ltd is an online education platform that operates for counselling students on college admissions",
              "image": {
                "@type": "ImageObject",
                "url": `${pConfig.siteUrl}home_page/images/logo.png`,
                "sameAs": [
                  "https://www.facebook.com/bangalorestudy/",
                  "https://twitter.com/BangaloreStudy2",
                  "https://www.youtube.com/channel/UCZP40_ivVcdelNOVhmQFr7w",
                  "https://www.instagram.com/bangalorestudy/"
                ]
              }
            }
          )}
        </script>

        <script type="application/ld+json">
          {JSON.stringify(
            {
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity":
                [{
                  "@type": "Question",
                  "name": "What are some Yenepoya University rankings?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Yenepoya University holds the 85th rank among the top 100 universities as per NIRF Ranking 2023. The university also falls in the 300 - 400 band in Times Higher Education World University Rankings."
                  }
                }, {
                  "@type": "Question",
                  "name": "Does Yenepoya University, Bangalore offer M.Sc in Food Technology?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Yes, Yenepoya University, Bangalore offers M.Sc in Food Technology. In this 2-year course, students will learn about food production, preservation, packaging, labelling and other techniques required from manufacturing to distribution of food."
                  }
                }, {
                  "@type": "Question",
                  "name": "What are the specializations offered by Yenepoya University in M.Sc Psychology?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "The Yenepoya University M.Sc Psychology specializations include Clinical Psychology, Counselling and Corporate Psychology, Counselling and Child Psychology & Counselling and Sports Psychology."
                  }
                }, {
                  "@type": "Question",
                  "name": "What is the duration of M.Sc Sports Psychology?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "M.Sc in Counselling and Sports Psychology is a 2-year postgraduate course."
                  }
                },
                {
                  "@type": "Question",
                  "name": "Are Yenepoya University, Bangalore, reviews good?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "With state-of-the-art facilities, modern pedagogy and teaching methodologies, Yenepoya University, Bangalore aims to fulfil the needs of the students and retain its trend of excellent reviews."
                  }
                },
                {
                  "@type": "Question",
                  "name": "Is M.Sc in Data Science and Big Data Analytics offered by Yenepoya University, Bangalore, associated with IBM?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Yes, M.Sc in Data Science & Data Analytics course provided by Yenepoya University, Bangalore is associated with IBM to help students gain knowledge of the latest technologies and to groom them into skilled professionals."
                  }
                }
                ]
            }
          )}
        </script>


      </Helmet>
      <div className="col-md-12  desktop-view">
        <div className="right-sec top60">
          <div className="mob-sec cont-sec">
            <a href="tel:+91 9973733030">
              <span> 9973733030/36/24 &nbsp; &nbsp; </span>
              <i className="fa fa-phone mr-2" />
            </a>
          </div>
        </div>
        <div className=" right-sec2 top70 yenepoyaleft" >
          <div className="mail-sec cont-sec">
            <a href="mailto:yenepoyab@gmail.com">
              <span>yenepoyab@gmail.com &nbsp; &nbsp;</span>
              <i className="fa fa-envelope" />
            </a>
          </div>
        </div>
      </div>
      <div className="col-md-12 mobile-view">
        <div className="right-sec4">
          <div className="mob-sec cont-sec">
            <a href="tel:+91 9973733030">
              <i className="fa fa-phone mr-2" />
            </a>
          </div>
        </div>
        <div className=" right-sec3">
          <div className="mail-sec cont-sec">
            <a href="mailto:yenepoyab@gmail.com">
              <i className="bi bi-envelope" />
            </a>
          </div>
        </div>
      </div>
      <header>
        <Navbar0 logo={logo}
          navLinks={navLinks}

        />

      </header>

      <section className="d-flex align-items-center pb-0 px-0" id="home" style={{ paddingTop: 83 }} >
        {/* <div className="container-fluid bgimage" style={{ background: `url(${ManagementCourseBG})` }}> */}
        <div className="container-fluid bamsimage" id="home" style={{ background: `linear-gradient(341deg, rgb(0 0 0 / 0%), rgb(0 0 0 / 0%)) , url(${ManagementCourseBG})` }}>
          <div
            className="container-fluid aos-init aos-animate p-md-2 p-4 "
            data-aos="zoom-out"
            data-aos-delay={100}
          >
            <div className="row mt-md-5 pt-md-5 gap-md-0 gap-4" >
              <div className="col-xl-7 col-lg-7 col-md-7 text-center">
                <div className='bgblackyenepoya slide-in'>

                  <h1 className='text-center h1yenepoya  pt-3 '>Earn a Master’s to Move Up the Career Ladder

                    <span className='d-block f25yene pb-2 pt-2' >from</span >
                    <span className='span pt-2 pb-4 d-block h1yenepoya' >Yenepoya Deemed-to-be University
                      Bangalore
                    </span>
                  </h1>

                  {/* <p className='text-center p pt-md-3 mt-3  mt-md-2 font23 '  >Don’t Miss the Opportunity to Pursue Your Education from the Most Renowned Institution.

                  </p> */}

                  <Button variant="primary" className="btn btn-success mb-4 btn-bds-add" onClick={handleShow}>
                    Get Started {'>'}

                  </Button>
                </div>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-5">

                <div className="form-box p-md-4">
                  <div>
                    <h3
                      className="font-weight-bold1 pb-2"
                      style={{ color: "white", textAlign: "center", }}
                    >

                      M.Sc Admissions Open
                      for 2023-24!


                    </h3>
                    <p className='pb-2 font-weight-bold' style={{ color: "#eeff00", textAlign: "center", }}>Fill up the Application Form to Get Started.
                    </p>
                  </div>

                  <form id="formoid"
                    action="https://api.bangalorestudy.com:3000/api/website/home/landingpageenquiry"
                    title=""
                    method="post">
                    <div className="form-group mb-3">

                      <input
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Name*"
                        required
                        className="form-control"
                      />

                    </div>
                    <div className="form-group mb-3">
                      <input
                        className="form-control"
                        id="email"
                        type="email"
                        name="email"
                        placeholder="Your Email "
                        required
                      />
                    </div>
                    <div className="form-group mb-3">
                      <input
                        className="form-control"
                        name="contact"
                        id="contact"
                        type="number"

                        required
                        placeholder="Contact No. * "
                      />
                    </div>

                    <div className="form-group mb-3">
                      <select
                        className="browser-default custom-select form-control"
                        name="location"
                        id="location"
                        required
                      >
                        <option value=" ">State</option>
                        <option value="Andhra Pradesh ">Andhra Pradesh</option>
                        <option value="Arunachal Pradesh ">Arunachal Pradesh</option>
                        <option value="Assam ">Assam</option>
                        <option value="Bihar ">Bihar</option>
                        <option value="Chhattisgarh ">Chhattisgarh</option>
                        <option value="Goa ">Goa</option>
                        <option value="Gujarat ">Gujarat</option>
                        <option value="Haryana ">Haryana</option>
                        <option value="Himachal Pradesh ">Himachal Pradesh</option>
                        <option value="Jammu & Kashmir ">Jammu &amp; Kashmir</option>
                        <option value="Jharkhand ">Jharkhand</option>
                        <option value="Karnataka ">Karnataka</option>
                        <option value="Kerala ">Kerala</option>
                        <option value="Madhya Pradesh ">Madhya Pradesh</option>
                        <option value="Maharashtra ">Maharashtra</option>
                        <option value="Manipur ">Manipur</option>
                        <option value="Meghalaya ">Meghalaya</option>
                        <option value="Mizoram ">Mizoram</option>
                        <option value="Nagaland ">Nagaland</option>
                        <option value="Odisha ">Odisha</option>
                        <option value="Punjab ">Punjab</option>
                        <option value="Rajasthan ">Rajasthan</option>
                        <option value="Sikkim ">Sikkim</option>
                        <option value="Tamil Nadu ">Tamil Nadu</option>
                        <option value="Tripura ">Tripura</option>
                        <option value="Telangana ">Telangana</option>
                        <option value="Uttarakhand ">Uttarakhand</option>
                        <option value="Uttar Pradesh ">Uttar Pradesh</option>
                        <option value="West Bengal ">West Bengal</option>
                        <option value="Andaman & Nicobar ">
                          Andaman &amp; Nicobar
                        </option>
                        <option value="Chandigarh ">Chandigarh</option>
                        <option value="Dadra and Nagar Haveli ">
                          Dadra and Nagar Haveli
                        </option>
                        <option value="Daman & Diu ">Daman &amp; Diu</option>
                        <option value="Delhi ">Delhi</option>
                        <option value="Lakshadweep ">Lakshadweep</option>
                        <option value="Puducherry ">Puducherry</option>
                      </select>
                    </div>
                    <div className="form-group mb-3">
                      <select
                        className="browser-default custom-select form-control "
                        name="course_name"
                        id="course_name"
                        required
                      >
                        <option value="" >
                          Preferred Course

                        </option>
                        <option value="M.Sc Cloud Computing & DevOps">
                          M.Sc Cloud Computing & DevOps
                        </option>
                        <option value="M.Sc Cybersecurity & Ethical Hacking">
                          M.Sc Cybersecurity & Ethical Hacking
                        </option>
                        <option value="M.Sc Artificial Intelligence, Machine Learning & Data Science">
                          M.Sc Artificial Intelligence, Machine Learning & Data Science
                        </option>
                        <option value="M.Sc Data Science and Big Data Analytics">
                          M.Sc Data Science and Big Data Analytics
                        </option>
                        <option value="M.Sc IoT & Cloud Computing">
                          M.Sc IoT & Cloud Computing
                        </option>
                        <option value="M.Sc Food Technology">
                          M.Sc Food Technology
                        </option>
                        <option value="M.Sc Clinical Psychology">
                          M.Sc Clinical Psychology
                        </option>
                        <option value="M.Sc Counselling & Corporate Psychology">
                          M.Sc Counselling & Corporate Psychology
                        </option>
                        <option value="M.Sc Counselling & Child Psychology">
                          M.Sc Counselling & Child Psychology
                        </option>
                        <option value="M.Sc Counselling & Sports Psychology">
                          M.Sc Counselling & Sports Psychology
                        </option>
                      </select>
                    </div>
                    <div className="form-group mb-3">
                      <textarea

                        cols={3}
                        name="description"
                        id="description"
                        placeholder="Message (optional) "
                        className="form-control"
                        defaultValue={""}
                      />
                    </div>
                    <div className="form-group text-center">
                      <button className="btn btn-success submit" name="submit" defaultValue="Submit" type="submit">Submit</button>

                    </div>
                  </form>
                </div>
              </div>

            </div>

          </div>

        </div>

      </section>
      <section className="container-fluid  pb-0 pt-4" id="about">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h3 className='f3bds-addmission pb-3 pb-md-0'>Yenepoya
                (Deemed-to-be University)
              </h3>
              {/* <p className='mb-0' style={{color:"Black"}}>Recognized under Sec 3(A) of the UGC Act, 1956
                <span className='d-block' style={{color:"Black"}}> Accredited by NAAC with ‘A+’ Grade</span>
              </p> */}
              <span id="why" style={{ display: "hidden" }}></span>
              <OwlCarousel {...owlOptionsacc} className="topTrendingCourses loop_nav owl-carousel owl-theme ">

             

                <div className="item  " style={itemStyle}>

                  <img src={acc1} className="img-fluid" alt="accredation1"  />
                </div>
                <div className="item" style={itemStyle}>

                  <img src={acc2} className="img-fluid" alt="accredation2" />
                </div>
                <div className="item" style={itemStyle}>

                  <img src={acc3} className="img-fluid" alt="accredation3" />
                </div>
                <div className="item" style={itemStyle}>

                  <img src={acc4} className="img-fluid" alt="accredation4" />
                </div>
                <div className="item" style={itemStyle}>

                  <img src={acc5} className="img-fluid" alt="accredation5" />
                </div>
                <div className="item" style={itemStyle}>

                  <img src={acc6} className="img-fluid" alt="accredation6" />
                </div>
                <div className="item" style={itemStyle}>

                  <img src={acc7} className="img-fluid" alt="accredation7" />
                </div>



              </OwlCarousel>
             
            </div>
          
          </div>

        </div >
      </section >





      <section className="osec-with-right-form pb-0 pt-4" style={{ backgroundColor: "rgb(18 34 55)", color: "white" }}  >

        <div className="container-fluid " >
          <div className='row px-0 px-md-3'>
            <div className='col-12 bds-add-h2 h2 pb-0 pb-md-4 pb-md-0 pt-3' >Why Yenepoya University?

            </div>
            <div className="col-12  row justify-content-between align-content-center countercards-yenepoya pb-5">
              <div className="col-12 mb-4 mb-md-0  mt-4 mt-md-0 col-md-2 ">
              5000+
                <p className='pt-2'> Qualified Faculties,
                  Admin & Support Personnel
                </p>
              </div>
              <div className="col-12 mb-4 mb-md-0 col-md-2">1st
                <p className='pt-2'> Deemed-to-be University
                  in
                  Dakshina Kannada

                </p>
              </div>
              <div className="col-12 mb-4 mb-md-0 col-md-2"> 25+
                <p className='pt-2'>National & Global Accreditations

                </p>
              </div>
              <div className="col-12 mb-4 mb-md-0 col-md-2">50+
                <p className='pt-2'>Specializations Offered

                </p>
              </div>
              <div className="col-12 mb-4 mb-md-0 col-md-2">10,000+
                <p className='pt-2' >Student Admissions

                </p>
              </div>







              <span id="colleges" style={{ display: "hidden" }}></span>


            </div>


          </div>
        </div>


      </section >


      <section className="container-fluid  p-0 pb-0 pt-3"  >
        <div className="container">
          <div className="row ">
            <div className="col-lg-12 text-center pt-3">
              <h3 className='f3bds-addmission pb-3 pb-md-0'>Yenepoya Deemed-to-be University, Bangalore</h3>
              <p style={{color:"Black"}}>Recognized under Sec 3(A) of the UGC Act, 1956</p>
              <p style={{color:"Black"}}>Accredited by NAAC with ‘A+’ Grade</p>
            </div>

            <div className="col-lg-12 text-center pt-5 pt-md-4 pb-3"><p className='textmbbs'>Located in Bhartiya City, the brand new campus of Yenepoya University in Bangalore, aims to sculpt students into skilled professionals with cutting-edge technologies and modern pedagogy. The university is one among the top 100 universities in NIRF Rankings. Many courses provided by Yenepoya University, Bangalore, are associated with leading multinational companies to help gain knowledge on the latest technologies.
            </p></div>



            <div className="col-lg-12 text-center">
              <div className="row row-cols-1 row-cols-md-3 g-4">
                <div className="col">
                  <div className="card border-0">
                    <img src={tcsion} className="card-img-top h150" alt="tcsion" />

                  </div>
                </div>
                <div className="col">
                  <div className="card border-0">
                    <img src={Ibm} className="card-img-top h150" alt="Ibm" />

                  </div>
                </div>
                <div className="col">
                  <div className="card border-0">
                    <span id="courses" style={{ display: "hidden" }}></span>
                    <img src={microsoft} className="card-img-top h150" alt="microsoft" />

                  </div>

                </div>


              </div>

            </div>

          </div>



        </div>
      </section >

      <section className="container-fluid  p-0 pb-0 pt-4" >
        <div className="container">
          <div className="row ">
            <div className="col-lg-12 text-center ">
              <h3 className='f3bds-addmission '>Yenepoya University Bangalore M.Sc Courses</h3>
            </div>

            <div className="col-lg-12 text-center pt-3 pb-5 "><p className='textmbbs '>Yenepoya University, Bangalore, offers many undergraduate and postgraduate courses. Master of Science (M.Sc) courses are one among the many postgraduate programs offered by the University. Yenepoya University courses are designed in a way to improve students' knowledge and understanding of the chosen specialization. The programs also enhance problem solving, leadership and other related skills needed to become better professionals.


            </p></div>

            <div className="col-lg-12 text-center ">
              <h3 className='f3bds-addmission ' style={{ color: "#621dce" }}>Are you Hunting For…</h3>
            </div>


            <div className="col-lg-12  pt-3 px-0  ">
              <div className="container mt-3 mt-md-5">
                <ul className="nav nav-tabs custom-tabs justify-content-evenly justify-content-md-between border-0">
                  <li className="nav-item p-3 p-md-0">
                    <a
                      className="nav-link active"
                      data-bs-toggle="tab"
                      data-bs-target="#tab1"
                    >
                      <div className='text-center'>
                        <p>  Master of Science (M.Sc) in</p>

                        <h3 className='pt-2'>Computer Applications</h3>
                      </div>


                    </a>
                  </li>
                  <li className="nav-item p-3 p-md-0">
                    <a className="nav-link" data-bs-toggle="tab" data-bs-target="#tab2">

                      <div className='text-center'>
                        <p> Master of Science (M.Sc) in</p>

                        <h3 className='pt-2'>Psychology</h3>
                      </div>




                    </a>
                  </li>
                  <li className="nav-item p-3 p-md-0">
                    <a className="nav-link" data-bs-toggle="tab" data-bs-target="#tab3">
                      <div className='text-center'>
                        <p>Master of Science (M.Sc) in</p>

                        <h3 className='pt-2'>   Food Technology</h3>
                      </div>






                    </a>
                  </li>
                </ul>
                <div className="tab-content mt-3">
                  <div className="tab-pane fade show active row" id="tab1">


                    <div className="col-lg-12 text-center pt-3">
                      <h3 className='f3yenepoya'>Computer Applications</h3>
                    </div>


                    <div className="col-lg-12 text-center pt-5 pb-3">

                      <div className="row row-cols-1 row-cols-md-3 g-4 ">


                        <div className="col">
                          <div className="card h-100 p-3 yenepoyacards">
                            <p>M.Sc in </p>
                            <h5 className="card-title h100 pt-2 pb-0 h99">Cloud Computing and DevOps</h5>
                            <p className='d-flex justify-content-evenly align-items-center'> <p>in association with </p>
                              <img src={Ibmicon} className="card-image-yenepoya" alt="Ibm" /> </p>
                            <div className="card-body">
                              <p className="card-text">
                                <ul className='px-0'>
                                  <li className='d-flex  align-items-center'><img src={Mode} className="card-icon-yenepoya" alt="Ibm" /> <span className='px-4'>Full-time</span></li>
                                  <li className='d-flex pt-2 align-items-center'><img src={Duration} className="card-icon-yenepoya" alt="Ibm" /> <span className='px-4'>2 Years</span></li>
                                </ul>


                              </p>

                            </div>
                            <Button variant="primary" className="btn btn-success m-2 btn-bds-addcolour" onClick={handleShow}>
                              Learn More
                            </Button>

                          </div>
                        </div>
                        <div className="col">
                          <div className="card h-100 p-3 yenepoyacards">
                            <p>M.Sc in </p>
                            <h5 className="card-title h100 pt-2 pb-0 h99">Cyber Security and Ethical Hacking</h5>
                            <p className='d-flex justify-content-evenly align-items-center'> <p>in association with </p>
                              <img src={Ibmicon} className="card-image-yenepoya" alt="Ibm" /> </p>
                            <div className="card-body">
                              <p className="card-text">
                                <ul className='px-0'>
                                  <li className='d-flex  align-items-center'><img src={Mode} className="card-icon-yenepoya" alt="Ibm" /> <span className='px-4'>Full-time</span></li>
                                  <li className='d-flex pt-2 align-items-center'><img src={Duration} className="card-icon-yenepoya" alt="Ibm" /> <span className='px-4'>2 Years</span></li>
                                </ul>


                              </p>

                            </div>
                            <Button variant="primary" className="btn btn-success m-2 btn-bds-addcolour" onClick={handleShow}>
                              Learn More
                            </Button>

                          </div>
                        </div>
                        <div className="col">
                          <div className="card h-100 p-3 yenepoyacards">
                            <p>M.Sc in </p>
                            <h5 className="card-title h100 pt-2 pb-0">Artificial Intelligence, Machine Learning and Data Science </h5>
                            <p className='d-flex justify-content-evenly align-items-center'> <p>in association with </p>
                              <img src={Ibmicon} className="card-image-yenepoya" alt="Ibm" /> </p>
                            <div className="card-body">
                              <p className="card-text">
                                <ul className='px-0'>
                                  <li className='d-flex  align-items-center'><img src={Mode} className="card-icon-yenepoya" alt="Ibm" /> <span className='px-4'>Full-time</span></li>
                                  <li className='d-flex pt-2 align-items-center'><img src={Duration} className="card-icon-yenepoya" alt="Ibm" /> <span className='px-4'>2 Years</span></li>
                                </ul>


                              </p>

                            </div>
                            <Button variant="primary" className="btn btn-success m-2 btn-bds-addcolour" onClick={handleShow}>
                              Learn More
                            </Button>

                          </div>
                        </div>
                        <div className="col">
                          <div className="card h-100 p-3 yenepoyacards">
                            <p>M.Sc in </p>
                            <h5 className="card-title h100 pt-2 pb-0">Data Science and Big Data Analytics </h5>
                            <p className='d-flex justify-content-evenly align-items-center'> <p>in association with </p>
                              <img src={Ibmicon} className="card-image-yenepoya" alt="Ibm" /> </p>
                            <div className="card-body">
                              <p className="card-text">
                                <ul className='px-0'>
                                  <li className='d-flex  align-items-center'><img src={Mode} className="card-icon-yenepoya" alt="Ibm" /> <span className='px-4'>Full-time</span></li>
                                  <li className='d-flex pt-2 align-items-center'><img src={Duration} className="card-icon-yenepoya" alt="Ibm" /> <span className='px-4'>2 Years</span></li>
                                </ul>


                              </p>

                            </div>
                            <Button variant="primary" className="btn btn-success m-2 btn-bds-addcolour" onClick={handleShow}>
                              Learn More
                            </Button>

                          </div>
                        </div>
                        <div className="col">
                          <div className="card h-100 p-3 yenepoyacards">
                            <p>M.Sc in </p>
                            <h5 className="card-title h100 pt-2 pb-0">IoT and Cloud Computing</h5>
                            <p className='d-flex justify-content-evenly align-items-center'> <p>in association with </p>
                              <img src={Ibmicon} className="card-image-yenepoya" alt="Ibm" /> </p>
                            <div className="card-body">
                              <p className="card-text">
                                <ul className='px-0'>
                                  <li className='d-flex  align-items-center'><img src={Mode} className="card-icon-yenepoya" alt="Ibm" /> <span className='px-4'>Full-time</span></li>
                                  <li className='d-flex pt-2 align-items-center'><img src={Duration} className="card-icon-yenepoya" alt="Ibm" /> <span className='px-4'>2 Years</span></li>
                                </ul>


                              </p>

                            </div>
                            <Button variant="primary" className="btn btn-success m-2 btn-bds-addcolour" onClick={handleShow}>
                              Learn More
                              <span id="infrastructure" style={{ display: "hidden" }}></span>
                            </Button>

                          </div>
                        </div>
                      </div>
                    </div>




                  </div>
                  <div className="tab-pane fade" id="tab2">
                    <div className="col-lg-12 text-center pt-3">
                      <h3 className='f3yenepoya'>Psychology
                      </h3>
                    </div>
                    <div className="col-lg-12 text-center pt-5 pb-3">

                      <div className="row row-cols-1 row-cols-md-3 g-4 ">


                        <div className="col">
                          <div className="card h-100 p-3 yenepoyacards">
                            <p>M.Sc in </p>
                            <h5 className="card-title h100 pt-2 pb-0 " style={{minHeight:67}} >Clinical Psychology</h5>
                            {/* <p className='d-flex justify-content-evenly align-items-center'> <p>in association with </p>
                              <img src={Ibmicon} className="card-image-yenepoya" alt="Ibm" /> </p> */}
                            <div className="card-body">
                              <p className="card-text">
                                <ul className='px-0'>
                                  <li className='d-flex  align-items-center'><img src={Mode} className="card-icon-yenepoya" alt="Ibm" /> <span className='px-4'>Full-time</span></li>
                                  <li className='d-flex pt-2 align-items-center'><img src={Duration} className="card-icon-yenepoya" alt="Ibm" /> <span className='px-4'>2 Years</span></li>
                                </ul>


                              </p>

                            </div>
                            <Button variant="primary" className="btn btn-success m-2 btn-bds-addcolour" onClick={handleShow}>
                              Learn More
                            </Button>

                          </div>
                        </div>
                        <div className="col">
                          <div className="card h-100 p-3 yenepoyacards">
                            <p>M.Sc in </p>
                            <h5 className="card-title h100 pt-2 pb-0 ">Counselling and Corporate Psychology</h5>
                            {/* <p className='d-flex justify-content-evenly align-items-center'> <p>in association with </p>
                              <img src={Ibmicon} className="card-image-yenepoya" alt="Ibm" /> </p> */}
                            <div className="card-body">
                              <p className="card-text">
                                <ul className='px-0'>
                                  <li className='d-flex  align-items-center'><img src={Mode} className="card-icon-yenepoya" alt="Ibm" /> <span className='px-4'>Full-time</span></li>
                                  <li className='d-flex pt-2 align-items-center'><img src={Duration} className="card-icon-yenepoya" alt="Ibm" /> <span className='px-4'>2 Years</span></li>
                                </ul>


                              </p>

                            </div>
                            <Button variant="primary" className="btn btn-success m-2 btn-bds-addcolour" onClick={handleShow}>
                              Learn More
                            </Button>

                          </div>
                        </div>
                        <div className="col">
                          <div className="card h-100 p-3 yenepoyacards">
                            <p>M.Sc in </p>
                            <h5 className="card-title h100 pt-2 pb-0 ">Counselling and Child Psychology</h5>
                            {/* <p className='d-flex justify-content-evenly align-items-center'> <p>in association with </p>
                              <img src={Ibmicon} className="card-image-yenepoya" alt="Ibm" /> </p> */}
                            <div className="card-body">
                              <p className="card-text">
                                <ul className='px-0'>
                                  <li className='d-flex  align-items-center'><img src={Mode} className="card-icon-yenepoya" alt="Ibm" /> <span className='px-4'>Full-time</span></li>
                                  <li className='d-flex pt-2 align-items-center'><img src={Duration} className="card-icon-yenepoya" alt="Ibm" /> <span className='px-4'>2 Years</span></li>
                                </ul>


                              </p>

                            </div>
                            <Button variant="primary" className="btn btn-success m-2 btn-bds-addcolour" onClick={handleShow}>
                              Learn More
                            </Button>

                          </div>
                        </div>
                        <div className="col">
                          <div className="card h-100 p-3 yenepoyacards">
                            <p>M.Sc in </p>
                            <h5 className="card-title h100 pt-2 pb-0 ">Counselling and Sports Psychology</h5>
                            {/* <p className='d-flex justify-content-evenly align-items-center'> <p>in association with </p>
                              <img src={Ibmicon} className="card-image-yenepoya" alt="Ibm" /> </p> */}
                            <div className="card-body">
                              <p className="card-text">
                                <ul className='px-0'>
                                  <li className='d-flex  align-items-center'><img src={Mode} className="card-icon-yenepoya" alt="Ibm" /> <span className='px-4'>Full-time</span></li>
                                  <li className='d-flex pt-2 align-items-center'><img src={Duration} className="card-icon-yenepoya" alt="Ibm" /> <span className='px-4'>2 Years</span></li>
                                </ul>


                              </p>

                            </div>
                            <Button variant="primary" className="btn btn-success m-2 btn-bds-addcolour" onClick={handleShow}>
                              Learn More
                            </Button>

                          </div>
                        </div>

                      </div>
                    </div>

                  </div>
                  <div className="tab-pane fade" id="tab3">
                    <div className="col-lg-12 text-center pt-3">
                      <h3 className='f3yenepoya'>Food Technology</h3>
                    </div>

                    <div className="col-lg-12 text-center pt-5 pb-3">

                      <div className="row row-cols-1 row-cols-md-3 g-4 ">



                        <div className="col">
                          <div className="card h-100 p-3 yenepoyacards">
                            <p>M.Sc in </p>
                            <h5 className="card-title h100 pt-2 pb-0 ">Food Technology</h5>
                            {/* <p className='d-flex justify-content-evenly align-items-center'> <p>in association with </p>
        <img src={Ibmicon} className="card-image-yenepoya" alt="Ibm" /> </p> */}
                            <div className="card-body">
                              <p className="card-text">
                                <ul className='px-0'>
                                  <li className='d-flex  align-items-center'><img src={Mode} className="card-icon-yenepoya" alt="Ibm" /> <span className='px-4'>Full-time</span></li>
                                  <li className='d-flex pt-2 align-items-center'><img src={Duration} className="card-icon-yenepoya" alt="Ibm" /> <span className='px-4'>2 Years</span></li>
                                </ul>


                              </p>

                            </div>
                            <Button variant="primary" className="btn btn-success m-2 btn-bds-addcolour" onClick={handleShow}>
                              Learn More
                            </Button>

                          </div>
                        </div>


                      </div>

                    </div>


                  </div>


                </div>


              </div>


            </div>



          </div>
        </div>
      </section >



      <section className="container-fluid  p-0 pb-0 pt-3"  >
        <div className="container">
          <div className="row ">
            <div className="col-lg-12 text-center pt-3">
              <h3 className='f3bds-addmission'>Yenepoya Campus Infrastructure and Facilities</h3>
            </div>

            <div className="col-lg-6 text-center pt-5 pb-3 d-flex justify-content-center ">
              <p className='textmbbs'>The new Yenepoya Deemed-to-be University is located in Bhartiya City, Bangalore. Modern technology and greenery come together to create an exceptional learning environment at Yenepoya, Bangalore. The university provides a Wifi-enabled campus with well-planned contemporary classrooms, gym, sports, commissary and other modern facilities to enhance problem solving and creative thinking of students.
              </p></div>
            <div className="col-lg-6 text-center pt-5 pb-3">


              <OwlCarousel {...owlOptions} className="topTrendingCourses loop_nav owl-carousel owl-theme crouselyenepoya">



                <div className="item">

                  <img src={inf1} className="img-fluid" alt="accredation1" />
                </div>
                <div className="item">

                  <img src={inf2} className="img-fluid" alt="accredation2" />
                </div>
                <div className="item">

                  <img src={inf3} className="img-fluid" alt="accredation3" />
                </div>
                <div className="item">

                  <img src={inf4} className="img-fluid" alt="accredation4" />
                </div>
                <div className="item">

                  <img src={inf5} className="img-fluid" alt="accredation5" />
                </div>
                <div className="item">

                  <img src={inf6} className="img-fluid" alt="accredation6" />
                  <span id="FAQs" style={{ display: "hidden" }}></span>
                </div>




              </OwlCarousel>




            </div>
          </div>
        </div>
      </section>






      <div className="faq-section p-3 bg-gray-2" >
        <div className="container ">
          <div className="faq-header ">
            <h2
              style={{
                color: "#1D3355",
                fontWeight: 600,
                textAlign: "center",
                paddingTop: 10
              }}
            >
              FAQs
            </h2>
          </div>
          <div className="faq-outer ">
            <div className="faq-img Ambfaq-img ">
              <img src={Faqimg} alt="FAQs " />
            </div>
            <div
              className="faq-sec Ambfaq-sec "
              style={{
                background:
                  "linear-gradient(90deg,transparent, #ebebfb, transparent)"
              }}
            >
              <div className="">
                <div className="Accordions ">
                  <div className="Accordion_item ">
                    <div className="title_tab ">
                      <h3 className="title ">
                        What are some Yenepoya University rankings?
                        <span className="icon ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg "
                            width={16}
                            height={16}
                            fill="currentColor "
                            className="bi bi-chevron-down "
                            viewBox="0 0 16 16 "
                          >
                            <path
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z "
                            />
                          </svg>
                        </span>
                      </h3>
                    </div>
                    <div className="inner_content ">
                      <p>
                        Yenepoya University holds the 85th rank among the top 100 universities as per NIRF Ranking 2023. The university also falls in the 300 - 400 band in Times Higher Education World University Rankings.



                      </p>
                    </div>
                  </div>
                  <div className="Accordion_item ">
                    <div className="title_tab ">
                      <h3 className="title ">
                        Does Yenepoya University, Bangalore offer M.Sc in Food Technology?

                        <span className="icon ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg "
                            width={16}
                            height={16}
                            fill="currentColor "
                            className="bi bi-chevron-down "
                            viewBox="0 0 16 16 "
                          >
                            <path
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z "
                            />
                          </svg>
                        </span>
                      </h3>
                    </div>
                    <div className="inner_content ">
                      <p>
                        Yes, Yenepoya University, Bangalore offers M.Sc in Food Technology. In this 2-year course, students will learn about food production, preservation, packaging, labelling and other techniques required from manufacturing to distribution of food.

                      </p>
                    </div>
                  </div>
                  <div className="Accordion_item ">
                    <div className="title_tab ">
                      <h3 className="title ">
                        What are the specializations offered by Yenepoya University in M.Sc Psychology?


                        <span className="icon ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg "
                            width={16}
                            height={16}
                            fill="currentColor "
                            className="bi bi-chevron-down "
                            viewBox="0 0 16 16 "
                          >
                            <path
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z "
                            />
                          </svg>
                        </span>
                      </h3>
                    </div>
                    <div className="inner_content ">
                      <p>
                        The Yenepoya University M.Sc Psychology specializations include Clinical Psychology, Counselling and Corporate Psychology, Counselling and Child Psychology & Counselling and Sports Psychology.

                      </p>
                    </div>
                  </div>
                  <div className="Accordion_item ">
                    <div className="title_tab ">
                      <h3 className="title ">
                        What is the duration of M.Sc Sports Psychology?




                        <span className="icon ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg "
                            width={16}
                            height={16}
                            fill="currentColor "
                            className="bi bi-chevron-down "
                            viewBox="0 0 16 16 "
                          >
                            <path
                              // fillRule="evenodd "
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z "
                            />
                          </svg>
                        </span>
                      </h3>
                    </div>
                    <div className="inner_content ">
                      <p>
                        M.Sc in Counselling and Sports Psychology is a 2-year postgraduate course.


                      </p>
                    </div>
                  </div>
                  <div className="Accordion_item ">
                    <div className="title_tab ">
                      <h3 className="title ">
                        Are Yenepoya University, Bangalore, reviews good?


                        <span className="icon ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg "
                            width={16}
                            height={16}
                            fill="currentColor "
                            className="bi bi-chevron-down "
                            viewBox="0 0 16 16 "
                          >
                            <path
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z "
                            />
                          </svg>
                        </span>
                      </h3>
                    </div>
                    <div className="inner_content ">
                      <p>
                        With state-of-the-art facilities, modern pedagogy and teaching methodologies, Yenepoya University, Bangalore aims to fulfil the needs of the students and retain its trend of excellent reviews.


                      </p>
                    </div>
                  </div>
                  <div className="Accordion_item ">
                    <div className="title_tab ">
                      <h3 className="title ">
                        Is M.Sc in Data Science and Big Data Analytics offered by Yenepoya University, Bangalore, associated with IBM?


                        <span className="icon ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg "
                            width={16}
                            height={16}
                            fill="currentColor "
                            className="bi bi-chevron-down "
                            viewBox="0 0 16 16 "
                          >
                            <path
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z "
                            />
                          </svg>
                        </span>
                      </h3>
                    </div>
                    <div className="inner_content ">
                      <p>
                        Yes, M.Sc in Data Science & Data Analytics course provided by Yenepoya University, Bangalore is associated with IBM to help students gain knowledge of the latest technologies and to groom them into skilled professionals.


                      </p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>




      <section className="container-fluid d-flex align-items-center pt-3 pb-0 px-0 " id="Contactus">
        <div className="container-fluid bgimage text-center d-flex justify-content-center align-items imgh" style={{ background: `linear-gradient(341deg, rgb(0 0 0 / 0%), rgb(0 0 0 / 0%)) , url(${contactbanner})` }}>
          <div className='ddAmb  '><h2 className='text-white p-3' style={{
            background: "rgb(0 2 62 / 48%)",
            borderRadius: "11px"
          }}>Want to Acquire an M.Sc Seat in the Most Reputed Educational Institution?
          </h2>
            <Button variant="primary" className="btn btn-success mt-5 mb-4 m-3 m-md-5 font-white23 btn-bds-addcolour " onClick={handleShow}>
              Yes!
            </Button>
          </div>
        </div>


      </section>



      <footer id="contact" className='bg-light p-0'>
        <div className="footer-top p-0">
          <div className="container-fluid ">
            <div className="row " style={{ justifyContent: "space-between" }}>
              <div className="col-lg-6 col-md-6 footer-contact">
                <img src={logo} alt="logo" className="img-responsive" />
              </div>
              <div
                className="col-lg-6 col-md-6 footer-newsletter text-black d-flex justify-content-md-end"

              >
                <div className='pt-5  pt-md-2 pe-5  ps-5'>
                  <h4>Yenepoya University Address:</h4>
                  <p>
                    <i className="bi bi-house"></i><span > Bhartiya City,</span> Thanisandra Main Road,
                    <br />
                    Bengaluru, Karnataka- 560064
                  </p>
                  <p><i className="bi bi-envelope"></i>  yenepoyab@gmail.com
                  </p>
                  <p> <i className="bi bi-telephone"></i>  9973733030
                  </p>
                </div>
              </div>
              <div className='col-12 text-center bg-dark mt-2'>Copyright @ Bangalore Study 2025</div>
            </div>
          </div>
        </div>
        


      </footer>

      <a
        href="https://wa.me/+919973733030"
        style={{
          position: "fixed",
          width: 63,
          height: 64,
          bottom: 7,
          right: 24,
          // backgroundColor: "#25d366",
          color: "#fff",
          borderRadius: 50,
          textAlign: "center",
          fontSize: 44,
          boxShadow: "2px 2px 3px #999",
          zIndex: 1059
        }}
        target="_blank "
      >
        {/* <i className="bi bi-whatsapp my-float " /> */}
                                 <img src={whtsp} alt='whtsplogo' style={{ width: "66px" }}></img>
        
      </a>

      <Modal show={show} onHide={handleClose}   >
        {/* <Modal.Header closeButton>

        </Modal.Header> */}

        <Modal.Body>

          <div className="heading-popup ">
            <h4>Fill-in the Details to Get Started with the Admission Process.</h4>
          </div>
          <form
            onSubmit={onSubmit}

          >

            <fieldset>
              <input
                type="text"
                id="name1"
                name="name"
                placeholder="Name* "
                required
                value={values.name} onChange={set('name')}
              />
            </fieldset>
            <small className="text-danger " />
            <fieldset className="form-group group_field">
              <input
                name="contact"
                type="number"
                id="contact1"
                // min={0}
                required
                placeholder="Contact No. * "
                // aria-required="true"
                value={values.contact} onChange={set('contact')}
              />
            </fieldset>
            <small className="text-danger " />
            <fieldset className="form-group group_field">
              <input
                type="email"
                id="email1"
                name="email"
                placeholder="Email ID* "
                required
                value={values.email} onChange={set('email')}
              />
            </fieldset>

            <fieldset className="form-group group_field">
              <small className="text-danger " />
              <fieldset className="form-group group_field">
                <select
                  name="course_in_mind"
                  style={{ height: 50, padding: "0px 22px" }}
                  id="course_in_mind"
                  required
                  value={values.course_in_mind} onChange={set('course_in_mind')}
                >
                  <option value=" ">Preferred Course</option>
                  <option value="M.Sc Cloud Computing & DevOps">
                    M.Sc Cloud Computing & DevOps
                  </option>
                  <option value="M.Sc Cybersecurity & Ethical Hacking">
                    M.Sc Cybersecurity & Ethical Hacking
                  </option>
                  <option value="M.Sc Artificial Intelligence, Machine Learning & Data Science">
                    M.Sc Artificial Intelligence, Machine Learning & Data Science
                  </option>
                  <option value="M.Sc Data Science and Big Data Analytics">
                    M.Sc Data Science and Big Data Analytics
                  </option>
                  <option value="M.Sc IoT & Cloud Computing">
                    M.Sc IoT & Cloud Computing
                  </option>
                  <option value="M.Sc Food Technology">
                    M.Sc Food Technology
                  </option>
                  <option value="M.Sc Clinical Psychology">
                    M.Sc Clinical Psychology
                  </option>
                  <option value="M.Sc Counselling & Corporate Psychology">
                    M.Sc Counselling & Corporate Psychology
                  </option>
                  <option value="M.Sc Counselling & Child Psychology">
                    M.Sc Counselling & Child Psychology
                  </option>
                  <option value="M.Sc Counselling & Sports Psychology">
                    M.Sc Counselling & Sports Psychology
                  </option>
                </select>
                <select name="location" id="location1"
                  value={values.location} onChange={set('location')} required>
                  <option value=" ">--select state--</option>
                  <option value="Andhra Pradesh ">Andhra Pradesh</option>
                  <option value="Arunachal Pradesh ">Arunachal Pradesh</option>
                  <option value="Assam ">Assam</option>
                  <option value="Bihar ">Bihar</option>
                  <option value="Chhattisgarh ">Chhattisgarh</option>
                  <option value="Goa ">Goa</option>
                  <option value="Gujarat ">Gujarat</option>
                  <option value="Haryana ">Haryana</option>
                  <option value="Himachal Pradesh ">Himachal Pradesh</option>
                  <option value="Jammu & Kashmir ">Jammu &amp; Kashmir</option>
                  <option value="Jharkhand ">Jharkhand</option>
                  <option value="Karnataka ">Karnataka</option>
                  <option value="Kerala ">Kerala</option>
                  <option value="Madhya Pradesh ">Madhya Pradesh</option>
                  <option value="Maharashtra ">Maharashtra</option>
                  <option value="Manipur ">Manipur</option>
                  <option value="Meghalaya ">Meghalaya</option>
                  <option value="Mizoram ">Mizoram</option>
                  <option value="Nagaland ">Nagaland</option>
                  <option value="Odisha ">Odisha</option>
                  <option value="Punjab ">Punjab</option>
                  <option value="Rajasthan ">Rajasthan</option>
                  <option value="Sikkim ">Sikkim</option>
                  <option value="Tamil Nadu ">Tamil Nadu</option>
                  <option value="Tripura ">Tripura</option>
                  <option value="Telangana ">Telangana</option>
                  <option value="Uttarakhand ">Uttarakhand</option>
                  <option value="Uttar Pradesh ">Uttar Pradesh</option>
                  <option value="West Bengal ">West Bengal</option>
                  <option value="Andaman & Nicobar ">Andaman &amp; Nicobar</option>
                  <option value="Chandigarh ">Chandigarh</option>
                  <option value="Dadra and Nagar Haveli ">
                    Dadra and Nagar Haveli
                  </option>
                  <option value="Daman & Diu ">Daman &amp; Diu</option>
                  <option value="Delhi ">Delhi</option>
                  <option value="Lakshadweep ">Lakshadweep</option>
                  <option value="Puducherry ">Puducherry</option>
                </select>


              </fieldset>
              <small className="text-danger " />
              <fieldset className="form-group ">
                <input
                  value={values.description} onChange={set('description')}
                  type="text "
                  name="description"
                  id="description1"
                  placeholder="Message (optional) "
                />
              </fieldset>
              <small className="text-danger " />
              <fieldset className="form-group ">
                <button type="submit" className='btn btn-primary'>Submit</button>
              </fieldset>
            </fieldset>
          </form>

        </Modal.Body>

      </Modal>





    </>
  );
};

export default BdsKarnataka;