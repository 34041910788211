import React, { FC, useEffect, useState, useContext, useCallback } from 'react';
import axios from 'src/utils/axiosintercpt';

import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Nav from 'react-bootstrap/Nav';

import logo from 'src/assets/Homoeopathic/logo.png';
import { pConfig } from 'src/config';
import Modal from 'react-bootstrap/Modal';
import Helmet from 'react-helmet';
import Button from 'react-bootstrap/Button';
import ManagementCourseBG from 'src/assets/Homoeopathic/Banner.jpg';

import whtsp from 'src/assets/Alliancecollege/whatsapp.gif';

import contactbanner from 'src/assets/Homoeopathic/Bottom.jpg';
import Research from 'src/assets/Homoeopathic/Research.jpg';
import Accreditations from 'src/assets/Homoeopathic/Accreditations.jpg';
import Practical from 'src/assets/Homoeopathic/Practical.jpg';
import Simulation from 'src/assets/Homoeopathic/Simulation.jpg';

import Library from 'src/assets/Homoeopathic/YHMCH-Library.jpg';
import SimulationCentre from 'src/assets/Homoeopathic/Simulation-Centre.jpg';
import girlhHostel from 'src/assets/Homoeopathic/Girls-Hostel.jpg';
import boyshHostel from 'src/assets/Homoeopathic/Boys-Hostel.jpg';
import Sports from 'src/assets/Homoeopathic/Sports-Facilities.jpg';
import Gym from 'src/assets/Homoeopathic/Gym.jpg';


import checkmark from 'src/assets/Homoeopathic/checkmark.png';

import Faqimg from 'src/assets/yenepoyalandingpage/FAQ.png';

import acc1 from 'src/assets/Homoeopathic/ARIIA.png';
import acc2 from 'src/assets/Homoeopathic/KSURF.png';
import acc3 from 'src/assets/Homoeopathic/NAAC.png';
import acc4 from 'src/assets/Homoeopathic/NABH-new.png';
import acc5 from 'src/assets/Homoeopathic/NABL.png';
import acc6 from 'src/assets/Homoeopathic/NIRF.png';
import acc7 from 'src/assets/Homoeopathic/Times Higher Education.png';
import UGC from 'src/assets/Homoeopathic/UGC-new1.png';

import "./styles.css";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { HmacSHA224 } from 'crypto-js';


const YenepoyaHomoeopathic: FC = () => {


  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();


  const [validated, setValidated] = useState(false);


  const handleSubmit = (event: { currentTarget: any; preventDefault: () => void; stopPropagation: () => void; },) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    // <GlobalPopupEnquiry className="btn btn btn-primary" title="Please fill in your details to receive your detailed rank prediction." />
    event.preventDefault();
    console.log(event);
    setValidated(true);
    // <GlobalPopupEnquiry className="btn btn btn-primary" title="Please fill in your details to receive your detailed rank prediction." />

  };

  const [values, setValues] = useState({
    name: '', email: '', contact: '', course_in_mind: '', location: '', description: ''
  });
  const set = name => {
    return ({ target: { value } }) => {
      setValues(oldValues => ({ ...oldValues, [name]: value }));
    }
  };

  const onSubmit = async (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('email', values.email);
    formData.append('contact', values.contact);
    formData.append('neetrank', values.course_in_mind);
    formData.append('location', values.location);
    formData.append('description', values.description);
    formData.append('current_url', window.location.href);
    formData.append('sourse', 'BHMS at Yenepoya Medical College 2025-26');

    try {
      const response = await axios.post('api/website/home/landingpageenquiry', formData);
      // console.log('response: ', response);


      if (response.data.status) {

        history.push('/thank-you');
      }
    } catch (err) {
      console.error(err);

      enqueueSnackbar('Please try again', {
        variant: 'error',
      });
    }


  }

  const [show, setShow] = useState(false);
  const [textmodal, setTextmodal] = useState('');


  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  const handleShow = () => {
    setTextmodal("Get Started with BHMS Admission Process.");

    setShow(true)
  };


  const handelshowCTA = () => {
    setTextmodal("Get Complete Details about BHMS course.");
    setShow(true)
  }

  const owlOptionsacc = {
    margin: 10, nav: true, autoplay: true, autoplayTimeout: 2000,
    // responsiveClass: true,
    responsive: {
      0: { items: 1, dots: false, nav: false, loop: true },
      400: { items: 1, dots: false, nav: false, loop: true },
      600: { items: 1, dots: false, nav: true },
      700: { items: 2, dots: false, nav: true },
      1000: {
        items: 4,
        nav: false,
        loop: true
      },
      1440: {
        items: 5,
        nav: false,
        loop: true
      }
    },
  };
  const owlOptions = {
    margin: 10, nav: true, autoplay: true, autoplayTimeout: 2000,
    responsiveClass: true,
    responsive: {
      0: { items: 1, dots: false, nav: false, loop: true },
      400: { items: 1, dots: false, nav: false, loop: true },
      600: { items: 1, dots: false, nav: true },
      700: { items: 2, dots: false, nav: true },
      1000: {
        items: 1,
        nav: false,
        loop: true
      },
      1440: {
        items: 1,
        nav: false,
        loop: true
      }
    },
  };

  // CSS style for carousel items
  const itemStyle = {
    width: '140px', // Set your desired width
    height: 'auto',

    // Set your desired height
  };

  const navigate = (e, id) => {
    e && e.preventDefault(); // to avoid the link from redirecting
    const elementToView = document.getElementById(id);
    elementToView.scrollIntoView();
  }

  return (


    <>
      <Helmet>
        <title>BHMS Admission at Yenepoya Homoeopathic Medical College</title>
        <meta name="description"
          content="Explore all the details related to BHMS Course in Yenepoya Homoeopathic Medical College, Mangalore: Admission Process, Course-Duration, Eligibility, and many more. Apply Now!

          " />
        <link rel="canonical" href="https://bangalorestudy.com/bhms-yenepoya-homoeopathic-medical-college-mangalore" />
        <meta
          name="viewport"
          content="width=device-width; initial-scale=1.0; maximum-scale=1.0; user-scalable=0;"
        />
        <link rel="stylesheet" href="preprod/nursing_admission/assets/css/dental.css" />
        <link rel="stylesheet" href="preprod/nursing_admission/assets/css/style.min.css" />
        <link rel="stylesheet" href="preprod/nursing_admission/assets/css/resp.min.css" />
        <link
          href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
          rel="stylesheet"
          integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN"
          crossOrigin="anonymous"
        />
        <script>
          {`
        window.dataLayer = window.dataLayer || [];
        function gtag() {dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', 'AW-990332405');
        `}
        </script>


        <script>{`
      (function (w, d, s, l, i) {
        w[l] = w[l] || []; w[l].push({
          'gtm.start':
        new Date().getTime(), event: 'gtm.js'
			}); var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
        'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
		})(window, document, 'script', 'dataLayer', 'GTM-MGDJ5RD');
    `}</script>

        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-990332405"></script>
        <script src="preprod/medical_admission_asset/js/jquery.min.js"></script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-140595938-1"></script>





        <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>

        <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.16.0/umd/popper.min.js"></script>

        {/* <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script> */}

        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/2.1.3/jquery.min.js"></script>
        <script src="https://cdn.jsdelivr.net/jquery.slick/1.4.1/slick.min.js"></script>

        <script src="preprod/medical_admission_asset/js/jquery.min.js"></script>
        <script src="preprod/nursing_admission/assets/js/jquery.min.js"></script>
        <script type="text/javascript" src="preprod/nursing_admission/assets/js/jquery.fancybox.pack.js"></script>
        <script src="preprod/nursing_admission/assets/js/owl.carousel.min.js"></script>

        <script type="text/javascript"
          src="https://bangalorestudy.com/veterinary_assets/asset/js/owl.carousel.min.js "></script>


        <script type="text/javascript">{`
      

setTimeout(function () {
  $('.widget-visible').remove();
}, 15000);
setTimeout(function () {
  $('.widget-visible').remove();
}, 10000);

setTimeout(function () {
  $('.widget-visible').remove();
}, 5000);

setTimeout(function () {
  $('.widget-visible').remove();
}, 3000);

setTimeout(function () {
  $('.widget-visible').remove();
}, 2000);


setTimeout(function () {
  $('.widget-visible').remove();
}, 1000);
$(document).ready(function () {
  $("#formoid").submit(function (event) {
    console.log("12");
    /* stop form from submitting normally */
    event.preventDefault();

    /* get the action attribute from the <form action=""> element */
    var $form = $(this),
      url = $form.attr('action');

    /* Send the data using post with element id name and name2*/

    var posting = $.post(url, {
      name: $('#name').val(),
      email: $('#email').val(),
      contact: $('#contact').val(),
      college_name: $('#college_name').val(),
      current_url: window.location.href,
      neetrank: $('#course_name').val(),
      location: $('#location').val(),
      sourse: "BHMS at Yenepoya Medical College 2025-26",
      description: $('#description').val(),

    });

    // console.log($('#name').val(),$('#email').val(), $('#contact').val(),$('#college_name').val(),);

    /* Alerts the results */
    posting.done(function (data) {
      // console.log(data);
      // console.log("1");
      $('#result').text('Thank you we will get back to you');
      $('#result').addClass('alert alert-success');
      $('#result').addClass('mt-1');
      $("#result").show().delay(5000).fadeOut();

      $('#formoid').each(function () {
        this.reset();
        location.href = "https://bangalorestudy.com/thank-you"
      });

    });
    posting.fail(function () {
      // console.log(data);
      // console.log("2");
      $('#result').text('Sorry please try again');
      $('#result').addClass('alert alert-warning');
      $('#result').addClass('mt-1');
      $("#result").show().delay(5000).fadeOut();
      $('#formoid').each(function () {
        this.reset();
      });
    });
  });
  $("#formoid1").submit(function (event) {
    console.log("12");
    /* stop form from submitting normally */
    event.preventDefault();

    /* get the action attribute from the <form action=""> element */
    var $form = $(this),
      url = $form.attr('action');

    /* Send the data using post with element id name and name2*/
    var posting = $.post(url, {
      name: $('#name1').val(),
      email: $('#email1').val(),
      contact: $('#contact1').val(),
      college_name: $('#college_name1').val(),
      current_url: window.location.href,
      // course_in_mind: $('#course_name').val(),
      location: $('#location1').val(),
      // description: $('#description1').val(),

    });

    /* Alerts the results */
    posting.done(function (data) {
      // console.log(data);
      // console.log("1");
      $('#result').text('Thank you we will get back to you');
      $('#result').addClass('alert alert-success');
      $('#result').addClass('mt-1');
      $("#result").show().delay(5000).fadeOut();

      $('#formoid1').each(function () {
        this.reset();
        location.href = "https://bangalorestudy.com/thank-you"
      });

    });
    posting.fail(function () {
      // console.log(data);
      // console.log("2");
      $('#result').text('Sorry please try again');
      $('#result').addClass('alert alert-warning');
      $('#result').addClass('mt-1');
      $("#result").show().delay(5000).fadeOut();
      $('#formoid1').each(function () {
        this.reset();
      });
    });
  });
})


`}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(
            {
              "@context": "https://schema.org",
              "@type": "LocalBusiness",
              "name": "Bangalore Study",
              "image": `${pConfig.siteUrl}home_page/images/logo.png`,
              "@id": `${pConfig.siteUrl}contact`,
              "url": `${pConfig.siteUrl}`,
              "telephone": "+91-9973733030",
              "priceRange": "INR",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "#3/75, 'D' Main, East End,Jayanagar 9th Block,Bangalore - 560069",
                "addressLocality": "Bengaluru",
                "postalCode": "560069",
                "addressCountry": "IN"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 12.918769,
                "longitude": 77.598285
              },
              "openingHoursSpecification": {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": [
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday",
                  "Saturday"
                ],
                "opens": "10:00",
                "closes": "19:00"
              }
            }
          )}
        </script>
        <script>
          {`
        var $titleTab = $('.title_tab');
        $('.Accordion_item:eq(0)').find('.title_tab').addClass('active').next().stop().slideDown(300);
        $('.Accordion_item:eq(0)').find('.inner_content').find('p').addClass('show');
        $titleTab.on('click', function (e) {
            e.preventDefault();
            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
                $(this).next().stop().slideUp(500);
                $(this).next().find('p').removeClass('show');
            } else {
                $(this).addClass('active');
                $(this).next().stop().slideDown(500);
                $(this).parent().siblings().children('.title_tab').removeClass('active');
                $(this).parent().siblings().children('.inner_content').slideUp(500);
                $(this).parent().siblings().children('.inner_content').find('p').removeClass('show');
                $(this).next().find('p').addClass('show');
            }
        });
      `}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(
            {
              "@context": "https://schema.org",
              "@type": "Website",
              "name": "Bangalore Study",
              "url": `${pConfig.siteUrl}`,
              "headline": "Bangalore Study | #1 College Admissions in Bangalore",
              "description": "Bangalorestudy.com by Learntech Edu Solution Pvt. Ltd is an online education platform that operates for counselling students on college admissions",
              "image": {
                "@type": "ImageObject",
                "url": `${pConfig.siteUrl}home_page/images/logo.png`,
                "sameAs": [
                  "https://www.facebook.com/bangalorestudy/",
                  "https://twitter.com/BangaloreStudy2",
                  "https://www.youtube.com/channel/UCZP40_ivVcdelNOVhmQFr7w",
                  "https://www.instagram.com/bangalorestudy/"
                ]
              }
            }
          )}
        </script>

        <script type="application/ld+json">
          {JSON.stringify(
            {
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity":
                [{
                  "@type": "Question",
                  "name": "What is BHMS full form?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "The full form of BHMS is Bachelor of Homeopathic Medicine and Surgery. It is one among the other alternative medical courses under AYUSH."
                  }
                }, {
                  "@type": "Question",
                  "name": "What is BHMS course duration and fees in YHMCH, Mangalore?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "BHMS is a 5.5 year undergraduate course which includes 4.5 years of academics and 1 year internship. The BHMS fees in YHMCH, Mangalore is very budget-friendly. Contact us to know about BHMS fee structure, scholarship for BHMS students and more."
                  }
                }, {
                  "@type": "Question",
                  "name": "What is the eligibility criteria to acquire a BHMS seat in YHMCH, Mangalore?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "The BHMS admission criteria is 50% aggregate marks in PCB In 10+2/ 2nd PUC/ Equivalent along with English as mandatory subject. Students should have appeared in the NEET-UG entrance exam to acquire a seat."
                  }
                }, {
                  "@type": "Question",
                  "name": "What are the documents required for BHMS admission in YHMCH, Mangalore?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Some of the documents which need to be submitted to the college at the time of admission include Admit Card & Score Card issued by NTA, 10th & 12th Marks Card, Online Allotment Letter of AYUSH, Transfer Certificate, Migration Certificate and more. Contact us to get the complete BHMS admission process details."
                  }
                },
                {
                  "@type": "Question",
                  "name": "Why is YHMCH one of the best Homeopathy Colleges in Mangalore?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "YHMCH is not only the best college in Mangalore, but it is one among the top homeopathy medical colleges in Karnataka. The main reason the college is so renowned is because it provides state-of the art facilities essential for students and a hand crafted curriculum where students gain practical and theoretical knowledge."
                  }
                },
                {
                  "@type": "Question",
                  "name": "What is the total fees of BHMS course in Yenepoya Homoeopathic Medical College & Hospital?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "BHMS course fees at Yenepoya Homoeopathic Medical College & Hospital, Mangalore, is very affordable. Reach out to us to know more about BHMS course fees, BHMS admission process and more."
                  }
                }
                ]
            }
          )}
        </script>


      </Helmet>
      <div className="col-md-12  desktop-view">
        <div className="right-sec top60">
          <div className="mob-sec cont-sec">
            <a href="tel:+91 9973733030">
              <span> 9973733030/36/24 &nbsp; &nbsp; </span>
              <i className="fa fa-phone mr-2" />
            </a>
          </div>
        </div>
        <div className=" right-sec2 top70 yenepoyaleft" >
          <div className="mail-sec cont-sec">
            <a href="mailto:yenepoyab@gmail.com">
              <span>yenepoyab@gmail.com &nbsp; &nbsp;</span>
              <i className="fa fa-envelope" />
            </a>
          </div>
        </div>
      </div>
      <div className="col-md-12 mobile-view">
        <div className="right-sec4">
          <div className="mob-sec cont-sec">
            <a href="tel:9036020016">
              <i className="fa fa-phone mr-2" />
            </a>
          </div>
        </div>
        <div className=" right-sec3">
          <div className="mail-sec cont-sec">
            <a href="mailto:yenepoyab@gmail.com">
              <i className="bi bi-envelope" />
            </a>
          </div>
        </div>
      </div>
      <header>
        <Navbar collapseOnSelect expand="lg" bg="light" fixed="top" variant="light">
          <Container fluid>
            <Navbar.Brand className="hov " >
              <img
                src={logo}
                width="220"
                height="70"
                className="d-inline-block align-top hov "
                alt="BHMS Admission at Yenepoya Homoeopathic Medical College"
              /></Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav my-3">
              <Nav style={{ marginLeft: "auto" }}>
                <Nav.Link href="" onClick={(e => navigate(e, 'home'))}>Home</Nav.Link>
                <Nav.Link href="" onClick={(e => navigate(e, 'YHMCH'))}>About YHMCH</Nav.Link>
                <Nav.Link href="" onClick={(e => navigate(e, 'BHMS'))}>BHMS @ YHMCH</Nav.Link>
                <Nav.Link href="" onClick={(e => navigate(e, 'Facilities'))}>Facilities</Nav.Link>
                <Nav.Link href="" onClick={(e => navigate(e, 'whyus'))}>Why Us?</Nav.Link>

                <Nav.Link href="" onClick={(e => navigate(e, 'FAQs'))}>FAQs</Nav.Link>

                <Nav.Link href="" onClick={(e => navigate(e, 'Contactus'))}>Contact Us</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>

      <section className="d-flex align-items-center pb-0 px-0 custom padding-top-homo" id="home"  >
        {/* <div className="container-fluid bgimage" style={{ background: `url(${ManagementCourseBG})` }}> */}
        <div className="container-fluid bamsimage-homo img-fluid" id="home" style={{ background: `linear-gradient(341deg, rgb(0 0 0 / 0%), rgb(0 0 0 / 0%)) , url(${ManagementCourseBG})` }}>
          <div
            className="container-fluid aos-init aos-animate p-md-2 p-4 inside-homo"
            data-aos="zoom-out"
            data-aos-delay={100}
          >
            <div className="row mt-md-5 pt-md-5 gap-md-0 gap-4" >
              <div className="col-xl-7 col-lg-7 col-md-7 text-center">
                <div className='bgblackyenepoya slide-in mx-md-5'>

                  <h1 className='text-center h1-yenepoya pb-2  pt-3 mx-4 '>Learn to Treat illnesses <br className='d-block d-md-none ' />with Care and Finesse.

                    {/* <span className='d-block f25yene pb-2 pt-2' >from</span > */}
                  </h1>

                  <h3 className='span pt-3 pt-md-4 pb-4 d-block ' style={{ color: 'white' }}>Acquire a Direct  BHMS Seat <br className='d-block d-md-none ' /> at <br className='d-md-block d-none' /> Yenepoya Homoeopathic <br className='d-block d-md-none ' />Medical College & Hospital, <br className='d-block d-md-none' /> Mangalore.
                  </h3>

                  {/* <p className='text-center p pt-md-3 mt-3  mt-md-2 font23 '  >Don’t Miss the Opportunity to Pursue Your Education from the Most Renowned Institution.

                  </p> */}

                  <Button variant="primary" className="btn btn-success mb-4 mbbdbtnpadding mx-4" onClick={handleShow}>
                    Apply Now

                  </Button>
                </div>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-5">

                <div className="form-boxamb p-md-4 slide-in2 py-md-2">
                  <div>

                    <h3
                      className="f700 pb-2 h3-homoeopathic"
                      style={{ color: "white", textAlign: "center", fontSize: 20 }}
                    >
                      Appeared in NEET-UG 2023? <br className='d-block' /> Grab a BHMS Seat in the <br className='d-block' /> Top Homeopathy College <br className='d-block' /> in  Mangalore.
                    </h3>
                  </div>

                  <form id="formoid"
                    action="https://api.bangalorestudy.com:3000/api/website/home/landingpageenquiry"
                    title=""
                    method="post">
                    <div className="form-group mb-3">

                      <input
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Name"
                        required
                        className="form-control"
                      />

                    </div>
                    <div className="form-group mb-3">
                      <input
                        className="form-control"
                        id="email"
                        type="email"
                        name="email"
                        placeholder="Your Email "
                        required
                      />
                    </div>
                    <div className="form-group mb-3">
                      <input
                        className="form-control"
                        name="contact"
                        id="contact"
                        type="number"

                        required
                        placeholder="Contact No."
                      />
                    </div>
                    <div className="form-group mb-3">
                      <input
                        className="form-control"
                        name="course_name"
                        id="course_name"
                        type="number"

                        required
                        placeholder="NEET Rank"
                      />
                    </div>

                    <div className="form-group mb-3">
                      <select
                        className="browser-default custom-select form-control"
                        name="location"
                        id="location"
                        required
                      >
                        <option value=" ">State</option>
                        <option value="Andhra Pradesh ">Andhra Pradesh</option>
                        <option value="Arunachal Pradesh ">Arunachal Pradesh</option>
                        <option value="Assam ">Assam</option>
                        <option value="Bihar ">Bihar</option>
                        <option value="Chhattisgarh ">Chhattisgarh</option>
                        <option value="Goa ">Goa</option>
                        <option value="Gujarat ">Gujarat</option>
                        <option value="Haryana ">Haryana</option>
                        <option value="Himachal Pradesh ">Himachal Pradesh</option>
                        <option value="Jammu & Kashmir ">Jammu &amp; Kashmir</option>
                        <option value="Jharkhand ">Jharkhand</option>
                        <option value="Karnataka ">Karnataka</option>
                        <option value="Kerala ">Kerala</option>
                        <option value="Madhya Pradesh ">Madhya Pradesh</option>
                        <option value="Maharashtra ">Maharashtra</option>
                        <option value="Manipur ">Manipur</option>
                        <option value="Meghalaya ">Meghalaya</option>
                        <option value="Mizoram ">Mizoram</option>
                        <option value="Nagaland ">Nagaland</option>
                        <option value="Odisha ">Odisha</option>
                        <option value="Punjab ">Punjab</option>
                        <option value="Rajasthan ">Rajasthan</option>
                        <option value="Sikkim ">Sikkim</option>
                        <option value="Tamil Nadu ">Tamil Nadu</option>
                        <option value="Tripura ">Tripura</option>
                        <option value="Telangana ">Telangana</option>
                        <option value="Uttarakhand ">Uttarakhand</option>
                        <option value="Uttar Pradesh ">Uttar Pradesh</option>
                        <option value="West Bengal ">West Bengal</option>
                        <option value="Andaman & Nicobar ">
                          Andaman &amp; Nicobar
                        </option>
                        <option value="Chandigarh ">Chandigarh</option>
                        <option value="Dadra and Nagar Haveli ">
                          Dadra and Nagar Haveli
                        </option>
                        <option value="Daman & Diu ">Daman &amp; Diu</option>
                        <option value="Delhi ">Delhi</option>
                        <option value="Lakshadweep ">Lakshadweep</option>
                        <option value="Puducherry ">Puducherry</option>
                      </select>
                    </div>

                    <div className="form-group mb-3">
                      <textarea

                        cols={3}
                        name="description"
                        id="description"
                        placeholder="Message (optional) "
                        className="form-control"
                        defaultValue={""}
                      />
                    </div>
                    <div className="form-group text-center" id="YHMCH" >
                      {/* <span  style={{ display: "hidden" }}></span> */}
                      <button className="btn btn-success submit btnbdscolour" name="submit" defaultValue="Submit" type="submit">Submit  </button>

                    </div>

                  </form>

                </div>
              </div>

            </div>

          </div>

        </div>

      </section>




      <section className="container-fluid  pb-0 pt-0" >
        <div className="container-fluid px-0 px-md-2">
          <div className="row">
            <div className="col-lg-12 text-center">
              <span style={{ fontSize: "27px", color: "rgb(39 64 152)", fontWeight: 600 }} >About YHMCH

              </span>
              <h3 className='f3Yenepoya-addmission pb-4 pb-md-0'>Yenepoya Homoeopathic Medical College & Hospital
              </h3>
              {/* <p className='mb-0' style={{color:"Black"}}>Recognized under Sec 3(A) of the UGC Act, 1956
                <span className='d-block' style={{color:"Black"}}> Accredited by NAAC with ‘A+’ Grade</span>
              </p> */}

              <div className='pt-md-4'>
                <OwlCarousel {...owlOptionsacc} className="topTrendingCourses loop_nav owl-carousel owl-theme ">

                  <div className="item  " style={itemStyle}>

                    <img src={UGC} className="img-fluid" alt="UGC" />
                  </div>

                  <div className="item" style={itemStyle}>

                    <img src={acc1} className="img-fluid" alt="NIRF" />
                  </div>
                  <div className="item" style={itemStyle}>

                    <img src={acc2} className="img-fluid" alt="NAAC" />
                  </div>
                  <div className="item" style={itemStyle}>

                    <img src={acc3} className="img-fluid" alt="NABH" />
                  </div>
                  <div className="item" style={itemStyle}>

                    <img src={acc4} className="img-fluid" alt="NABL" />
                  </div>
                  <div className="item" style={itemStyle}>

                    <img src={acc5} className="img-fluid" alt="KSURF" />
                  </div>
                  <div className="item" style={itemStyle}>

                    <img src={acc6} className="img-fluid" alt="ARIIA" />
                  </div>
                  <div className="item" style={itemStyle}>

                    <img src={acc7} className="img-fluid" alt="Times Higher Education" />
                  </div>




                </OwlCarousel>
              </div>



              <div className='container px-0 px-md-1'>
                <div className="col-lg-12 text-center pt-2 pt-md-4 pb-3"><p className='textmbbs'>
                  Yenepoya Homoeopathic Medical College & Hospital, Mangalore, is a constituent institute of the Yenepoya University, Mangalore. This premier institute has been approved by the University Grants Commission (UGC). The college has strived hard to provide quality education to students from the day of its commencement. Henceforth, it has earned its spot among the top BHMS colleges in Mangalore.
                </p></div>
              </div>




              <div className='container pt-4 pb-3 d-flex justify-content-center'>
                <div className='row g-4'>
                  <div className="col-lg-6 text-center  d-flex justify-content-center ">
                    <Card style={{ backgroundColor: 'rgb(57 25 120)', borderRadius: '40px', minWidth: '100%' }} >
                      <Card.Body className='p-md-4'>
                        <Card.Title className='f4homoeopathic '>Mission</Card.Title>


                        <Card.Text className='cardhomoeopathic pt-3 m-0'>
                          Provide <b>Quality Education</b><br />
                          through <b>Selfless Service</b> and fulfil the commitment of <b>Excellence</b>
                        </Card.Text>

                      </Card.Body>
                    </Card></div>

                  <div className="col-lg-6 text-center  d-flex justify-content-center ">
                    <Card style={{ backgroundColor: 'rgb(57 25 120)', borderRadius: '40px', minWidth: '100%' }} >
                      <Card.Body className='p-md-4'>
                        <Card.Title className='f4homoeopathic'>Vision</Card.Title>

                        <Card.Text className='cardhomoeopathic pt-3 m-0'>
                          Build a Centre of Global <br className='d-bock d-md-none' />  Excellence <br className='d-none d-md-block' />in <b>Homeopathic<br className='d-bock d-md-none' />  Education</b> through<br className='d-block d-md-block' />  <b> Quality Teaching</b> and <b>Research</b>
                        </Card.Text>
                      </Card.Body>
                    </Card></div>

                </div>

              </div>





            </div>

          </div>


          <div className="col-lg-12 text-center pt-4 pb-3 ">

            <h3 className='f3homoeopathic  pb-3 pb-md-0'>Why YHMCH is the Best?

            </h3>
          </div>

          <div className='container pt-md-4 pt-3 pb-3'>
            <div className='row g-4'>
              <div className='col-md-3 col-12 text-center'>

                <Card className='border-radius-20'  >
                  <Card.Img variant="top" src={Research} className='center' alt='Research' />
                  <Card.Body>

                    <Card.Text>
                      <h5 className=' Iconhomopethic'>
                        Research
                      </h5>
                    </Card.Text>
                    <div className='pt-3  text-center'>
                      <img src={checkmark} className='Iconimg' alt="Times Higher Education" />
                    </div>
                  </Card.Body>
                </Card>
                {/* <img src={Research} alt="Research" className='best-icon' />
                <h5 className=' Iconhomopethic'>
                  Research
                </h5>
                <div className='pt-3  text-center'>
                  <img src={checkmark} className='Iconimg' alt="Times Higher Education" />
                </div> */}

              </div>

              <div className='col-md-3 col-12'>

                <Card className='border-radius-20'  >
                  <Card.Img variant="top" src={Accreditations} className=' center' />
                  <Card.Body>

                    <Card.Text>
                      <h5 className=' Iconhomopethic text-center '>
                        Accreditations
                      </h5>
                    </Card.Text>
                    <div className='pt-3  text-center'>
                      <img src={checkmark} className='Iconimg' alt="Times Higher Education" />
                    </div>
                  </Card.Body>
                </Card>

                {/* <img src={Accreditations} alt="Accreditations" className='best-icon' />
                <h5 className='
                 Iconhomopethic'>
                  Accreditations
                </h5>
                <div className=' pt-3 text-center '>
                  <img src={checkmark} className='Iconimg' />
                </div> */}
              </div>

              <div className='col-md-3 col-12'>

                <Card className='border-radius-20'  >
                  <Card.Img variant="top" src={Practical} className=' center' />
                  <Card.Body>

                    <Card.Text>
                      <h5 className=' Iconhomopethic text-center '>
                        Practical Learning
                      </h5>
                    </Card.Text>
                    <div className='pt-3  text-center'>
                      <img src={checkmark} className='Iconimg' alt="Times Higher Education" />
                    </div>
                  </Card.Body>
                </Card>
                {/* <img src={Practical} alt="Practical Learning" className='best-icon' />
                <h5 className='
                 Iconhomopethic'>
                  Practical Learning
                </h5>
                <div className='pt-3 '>
                  <img src={checkmark} className='Iconimg' />
                </div> */}
              </div>


              <div className='col-md-3 col-12'>

                <Card className='border-radius-20' >
                  <Card.Img variant="top" src={Simulation} className='center' />
                  <Card.Body>

                    <Card.Text>
                      <h5 className=' Iconhomopethic text-center '>
                        Simulation Centre
                      </h5>
                    </Card.Text>
                    <div className='pt-3  text-center'>
                      <img src={checkmark} className='Iconimg' alt="Times Higher Education" />
                    </div>
                    <span id="BHMS" style={{ display: "hidden" }}></span>
                  </Card.Body>
                </Card>
                {/* <img src={Simulation} alt="Simulation Centre" className='best-icon' />
                <h5 className='Iconhomopethic'>
                  Simulation Centre
                </h5>
                <div className=' pt-3 '>
                  <img src={checkmark} className='Iconimg' />
                  
                 
                </div> */}



              </div>


            </div>


          </div>
          <div className='col-lg-12 text-center p-3 f700 ' style={{ fontSize: 27 }} >
            & Many More!</div>
        </div >
      </section >









      <section className="container-fluid  p-0 pb-0 pt-3"  >
        <div className="container">
          <div className="row ">
            <div className="col-lg-12 text-center pt-3">
              <h3 className='f3Yenepoya-addmission pb-3 pb-md-0'>BHMS @ <br />
                Yenepoya Homoeopathic Medical College & Hospital
              </h3>

            </div>

            <div className="col-lg-12 text-center  pt-md-4 pb-0"><p className='textmbbs'>Right from Medicine to Arts & Social Sciences, Yenepoya University, Mangalore, provides numerous courses for students to learn and achieve their dreams. Yenepoya Homoeopathic Medical College & Hospital is one among the many subunits that was introduced exclusively for Homoeopathy courses.
            </p></div>

            <div className="col-lg-12 text-center  pt-4 pb-3"><p className='textmbbs'>The Bachelor of Homeopathic Medicine and Surgery, also known as BHMS, is an undergraduate alternative medicine program which is provided under YHMCH. Let’s have a brief look at the course details, before running through the Homeopathy syllabus and subjects.
            </p></div>



            <div className="col-lg-12 px-0 px-md-5 pb-3 ">
              <div className=" Homoeopathicscopecustomborder">
                <table className="table table-bordered mb-0">
                  <tbody>

                    <tr>
                      <th scope="row">Course Name</th>
                      <td colSpan={3}>Bachelor of Homeopathic Medicine and Surgery (BHMS)
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Course Level

                      </th>
                      <td colSpan={3}>Undergraduate (UG)</td>

                    </tr>
                    <tr>
                      <th scope="row">Duration</th>
                      <td colSpan={3}>5.5 Years<br />
                        <p>(4.5 Year Academic Study + 1 Year Internship)</p></td>

                    </tr>
                    <tr>
                      <th scope="row">Entrance Exam</th>
                      <td colSpan={3}> NEET-UG


                      </td>

                    </tr>
                    <tr>
                      <th scope="row">BHMS Eligibility Criteria</th>
                      <td colSpan={3} className='d-flex justify-content-center'>

                        <ul className='text-start ps-4'>
                          <li>Passed 10+2/ 2nd PUC or Equivalent with English and  <br /> 50% Aggregate Marks in PCB Subjects</li>
                          <li>Appeared in NEET-UG entrance exam</li>
                          <li>17 Years of age as on Dec 31st in the Admission Year</li>
                        </ul>


                      </td>

                    </tr>

                    <tr>
                      <th scope="row">Regulatory Body</th>
                      <td colSpan={3}> Ministry of AYUSH


                      </td>

                    </tr>


                  </tbody>
                </table>


              </div>
            </div>

            <div className="col-lg-12 text-center pt-4 pb-3 ">

              <h4 className='f3homoeopathic  pb-md-0'>BHMS Syllabus

              </h4>
            </div>


            <div className="col-lg-12 text-center  pb-2"><p className='textmbbs'>Homeopathic treatments are given for acute and chronic diseases. Therefore, a student who opts for a Homeopathy course must learn Homeopathic treatment methods of various illnesses. YHMCH college offers a well-knitted curriculum which has a blend of both medical and homeopathic subjects. Students get both theoretical and practical training during the span of the course.
            </p></div>

            <div className="col-lg-12 text-center pt-4 pb-0 ">

              <h4 className='f3homoeopathic  pb-3 pb-md-0' style={{ fontSize: 26 }}>BHMS Subjects


              </h4>
            </div>


            <div className="row justify-content-center align-items-start pt-md-4 pb-0  m-0 g-3">



              <div className=" col-12 col-md-3">
                <div className='notificationCard'>

                  <h5 className='BHMS text-center pt-4 pb-2'>1st Year</h5>
                  <hr className="my-0" />
                  <ul className="BHMS p-2 pt-3 ">
                    <li><i className="fa fa-check" aria-hidden="true"></i> Anatomy</li>
                    <li><i className="fa fa-check" aria-hidden="true"></i> Physiology</li>
                    <li><i className="fa fa-check" aria-hidden="true"></i> Homoeopathic Pharmacy
                    </li>
                    <li><i className="fa fa-check" aria-hidden="true"></i> Homoeopathic Materia
                      Medica
                    </li>
                  </ul>
                  <div className="buttonContainer">
                    <Button variant="primary" className=" d-block mx-auto buttonfonthomo btn btn-success  homoeopathic-btn-blue" onClick={handelshowCTA}>
                      Know More
                    </Button>

                  </div>
                </div>
              </div>



              <div className=" col-12 col-md-3  ">
                <div className='notificationCard'>

                  <h5 className='BHMS text-center pt-4 pb-2'>2nd Year</h5>
                  <hr className="my-0" />
                  <ul className="BHMS p-2 pt-3">
                    <li><i className="fa fa-check" aria-hidden="true"></i> Pathology</li>
                    <li><i className="fa fa-check" aria-hidden="true"></i> Forensic Medicine & Toxicology</li>
                    <li><i className="fa fa-check" aria-hidden="true"></i> Surgery
                    </li>
                    <li><i className="fa fa-check" aria-hidden="true"></i> Gynaecology and Obstetrics
                    </li>
                  </ul>
                  <div className="buttonContainer">
                    <Button variant="primary" className=" d-block mx-auto buttonfonthomo btn btn-success  homoeopathic-btn-blue" onClick={handelshowCTA}>
                      Know More
                    </Button>

                  </div>
                </div>
              </div>



              <div className=" col-12 col-md-3 ">
                <div className='notificationCard'>

                  <h5 className='BHMS text-center pt-4 pb-2'>3rd Year</h5>
                  <hr className="my-0" />
                  <ul className="BHMS p-2 pt-3 ">
                    <li><i className="fa fa-check" aria-hidden="true"></i> Organon of Medicine</li>
                    <li><i className="fa fa-check" aria-hidden="true"></i> Practice of Medicine & Homoeopathic Therapeutics
                    </li>
                    <li><i className="fa fa-check" aria-hidden="true"></i> Community Medicine
                    </li>
                    <li><i className="fa fa-check" aria-hidden="true"></i> Repertory
                    </li>
                  </ul>

                  <div className="buttonContainer">
                    <Button variant="primary" className=" d-block mx-auto buttonfonthomo btn btn-success  homoeopathic-btn-blue" onClick={handelshowCTA}>
                      Know More
                    </Button>

                  </div>
                </div>
              </div>


              <div className=" col-12 col-md-3 ">
                <div className='notificationCard'>

                  <h5 className='BHMS text-center pt-4 pb-2'>4th Year</h5>
                  <hr className="my-0" />
                  <ul className="BHMS p-2 pt-3 ">
                    <li><i className="fa fa-check" aria-hidden="true"></i> Community Medicine</li>
                    <li><i className="fa fa-check" aria-hidden="true"></i> Repertory</li>
                    <li><i className="fa fa-check" aria-hidden="true"></i> Organon of Medicine
                    </li>
                    <li><i className="fa fa-check" aria-hidden="true"></i> Homoeopathic Materia Medica
                    </li>
                  </ul>

                  <div className="buttonContainer">
                    <Button variant="primary" className=" d-block mx-auto buttonfonthomo btn btn-success  homoeopathic-btn-blue" onClick={handelshowCTA} id="Facilities">
                      Know More
                    </Button>

                  </div>

                </div>
              </div>




            </div>


          </div>


        </div>




      </section >





      <section className="container-fluid  p-0 pb-0 pt-3"  >
        <div className="container">
          <div className="row ">
            <div className="col-lg-12 text-center pt-4 pb-0">
              <h3 className='f3Yenepoya-addmission'>Facilities @ YHMCH</h3>
            </div>

            <div className="col-lg-6 text-center pt-4 pb-3 d-flex justify-content-center ">
              <p className='textmbbs p-md-4'>Facilities and infrastructure play a pivotal role as it directly hampers or improves the growth of a student. Therefore, Yenepoya Homoeopathic Medical College & Hospital provides all the necessary facilities required for the overall development of students. Some of the amenities provided by the college include YHMCH Library, Simulation Centre, Hostel Facilities, Sports Facilities, exclusive Research Centre and many more.

                <span id="whyus" style={{ display: "hidden" }}></span>
              </p>


            </div>



            <div className="col-lg-6 text-center pt-5 pb-3">

              <OwlCarousel {...owlOptions} className="topTrendingCourses loop_nav owl-carousel owl-theme crouselyenepoya">



                <div className="item">

                  <img src={Library} className="img-fluid" alt="Library" />
                </div>
                <div className="item">

                  <img src={SimulationCentre} className="img-fluid" alt="SimulationCentre" />
                </div>
                <div className="item">

                  <img src={girlhHostel} className="img-fluid" alt="girlhHostel" />
                </div>
                <div className="item">

                  <img src={boyshHostel} className="img-fluid" alt="boyshHostel" />
                </div>
                <div className="item">

                  <img src={Sports} className="img-fluid" alt="Sports" />
                </div>

                <div className="item">

                  <img src={Gym} className="img-fluid" alt="Gym" />
                </div >

              </OwlCarousel >



            </div>

          </div>

        </div>
      </section>




      <section className="container-fluid  p-0 pb-0 pt-3"  >
        <div className="container">
          <div className="row ">
            <div className="col-lg-12 text-center pt-3">
              <h3 className='f3Yenepoya-addmission'>Why Us?</h3>
            </div>

            <div className="col-lg-12 text-center pt-4 pt-md-4 pb-0"><p className='textmbbs'>Getting a seat in a college might be easy. But, to acquire a seat in a top college is comparatively hard. Here is where we come into picture. We help students acquire seats in top ranked colleges all over India in the program they wish to study.


            </p></div>

            <div className="col-lg-12 text-center pt-4 pt-md-4 pb-2"><p className='textmbbs'>If you are a student who is eyeing to get BHMS admission in Karnataka in one of the top Homeopathy colleges in Karnataka or want to get into the prestigious Yenepoya University, Mangalore to study BHMS, then we can help you acquire a seat with ease. All you need to do is contact us and we will take care of the rest.


            </p></div>

          </div>

          <div className=' gap-1  bodrdertext homo-backgroundcard  divfont d-flex justify-content-around align-items-center mt-md-4 mt-2  flex-column p-2 p-md-3 ' style={{ maxWidth: "700px", margin: "auto" }}>
            <div className='text-center'>Wrote NEET-UG?<br className='d-block d-md-none' /> Want to Acquire a BHMS Seat?<br />Make it Yours!
            </div>
            <span id="FAQs" style={{ display: "hidden" }}></span>
            <Button variant="primary" className="btn btn-success   font-white23 homoeopathic-btn buttonfonthomo " onClick={handleShow}>
              Connect Now
            </Button>

          </div>
        </div>


      </section>







      <div className="faq-section p-3 bg-gray-2" >
        <div className="container ">
          <div className="faq-header ">
            <h2
              style={{
                color: "#1D3355",
                fontWeight: 600,
                textAlign: "center",
                paddingTop: 10
              }}
            >
              FAQs
            </h2>
          </div>
          <div className="faq-outer ">
            <div className="faq-img Ambfaq-img ">
              <img src={Faqimg} alt="FAQs " />
            </div>
            <div
              className="faq-sec Ambfaq-sec "
              style={{
                background:
                  "linear-gradient(90deg,transparent, #ebebfb, transparent)"
              }}
            >
              <div className="">
                <div className="Accordions ">
                  <div className="Accordion_item ">
                    <div className="title_tab ">
                      <h3 className="title ">
                        What is BHMS full form?
                        <span className="icon ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg "
                            width={16}
                            height={16}
                            fill="currentColor "
                            className="bi bi-chevron-down "
                            viewBox="0 0 16 16 "
                          >
                            <path
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z "
                            />
                          </svg>
                        </span>
                      </h3>
                    </div>
                    <div className="inner_content ">
                      <p>
                        The full form of BHMS is Bachelor of Homeopathic Medicine and Surgery. It is one among the other alternative medical courses under AYUSH.



                      </p>
                    </div>
                  </div>
                  <div className="Accordion_item ">
                    <div className="title_tab ">
                      <h3 className="title ">
                        What is BHMS course duration and fees in YHMCH, Mangalore?

                        <span className="icon ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg "
                            width={16}
                            height={16}
                            fill="currentColor "
                            className="bi bi-chevron-down "
                            viewBox="0 0 16 16 "
                          >
                            <path
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z "
                            />
                          </svg>
                        </span>
                      </h3>
                    </div>
                    <div className="inner_content ">
                      <p>
                        BHMS is a 5.5 year undergraduate course which includes 4.5 years of academics and 1 year internship. The BHMS fees in YHMCH, Mangalore is very budget-friendly. Contact us to know about BHMS fee structure, scholarship for BHMS students and more.

                      </p>
                    </div>
                  </div>
                  <div className="Accordion_item ">
                    <div className="title_tab ">
                      <h3 className="title ">
                        What is the eligibility criteria to acquire a BHMS seat in YHMCH, Mangalore?


                        <span className="icon ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg "
                            width={16}
                            height={16}
                            fill="currentColor "
                            className="bi bi-chevron-down "
                            viewBox="0 0 16 16 "
                          >
                            <path
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z "
                            />
                          </svg>
                        </span>
                      </h3>
                    </div>
                    <div className="inner_content ">
                      <p>
                        The BHMS admission criteria is 50% aggregate marks in PCB In 10+2/ 2nd PUC/ Equivalent along with English as mandatory subject. Students should have appeared in the NEET-UG entrance exam to acquire a seat.

                      </p>
                    </div>
                  </div>
                  <div className="Accordion_item ">
                    <div className="title_tab ">
                      <h3 className="title ">
                        What are the documents required for BHMS admission in YHMCH, Mangalore?
                        <span className="icon ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg "
                            width={16}
                            height={16}
                            fill="currentColor "
                            className="bi bi-chevron-down "
                            viewBox="0 0 16 16 "
                          >
                            <path
                              // fillRule="evenodd "
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z "
                            />
                          </svg>
                        </span>
                      </h3>
                    </div>
                    <div className="inner_content ">
                      <p>
                        Some of the documents which need to be submitted to the college at the time of admission include Admit Card & Score Card issued by NTA, 10th & 12th Marks Card, Online Allotment Letter of AYUSH, Transfer Certificate, Migration Certificate and more. Contact us to get the complete BHMS admission process details.


                      </p>
                    </div>
                  </div>
                  <div className="Accordion_item ">
                    <div className="title_tab ">
                      <h3 className="title ">
                        Why is YHMCH one of the best Homeopathy Colleges in Mangalore?
                        <span className="icon ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg "
                            width={16}
                            height={16}
                            fill="currentColor "
                            className="bi bi-chevron-down "
                            viewBox="0 0 16 16 "
                          >
                            <path
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z "
                            />
                          </svg>
                        </span>
                      </h3>
                    </div>
                    <div className="inner_content ">
                      <p>
                        YHMCH is not only the best college in Mangalore, but it is one among the top Homeopathy Medical Colleges in Karnataka. The main reason the college is so renowned is because it provides state-of the art facilities essential for students and a hand crafted curriculum where students gain practical and theoretical knowledge.


                      </p>
                    </div>
                  </div>
                  <div className="Accordion_item ">
                    <div className="title_tab ">
                      <h3 className="title ">
                        What is the total fees of BHMS course in Yenepoya Homoeopathic Medical College & Hospital?


                        <span className="icon ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg "
                            width={16}
                            height={16}
                            fill="currentColor "
                            className="bi bi-chevron-down "
                            viewBox="0 0 16 16 "
                          >
                            <path
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z "
                            />
                          </svg>
                        </span>
                      </h3>
                    </div>
                    <div className="inner_content ">
                      <p>
                        BHMS course fees at Yenepoya Homoeopathic Medical College & Hospital, Mangalore, is very affordable. Reach out to us to know more about BHMS course fees, BHMS admission process and more.


                      </p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>




      <section className="container-fluid d-flex align-items-center pt-3 pb-0 px-0 " id="Contactus">
        <div className="container-fluid bgimage text-center d-flex justify-content-center align-items imgh" style={{ background: `linear-gradient(341deg, rgb(0 0 0 / 0%), rgb(0 0 0 / 0%)) , url(${contactbanner})` }}>
          <div className='ddAmb  '><h2 className='text-white p-3' style={{
            background: "rgb(0 2 62 / 48%)",
            borderRadius: "11px"
          }}>Study the ‘A to Z’ of Homeopathic Medicine from the Renowned YHMCH.
          </h2>
            <Button variant="primary" className="btn btn-success mt-5 mb-4 m-3 m-md-5 font-white23 homoeopathic-btn " onClick={handleShow}>
              Apply Now!
            </Button>
          </div>
        </div>


      </section>



      <footer id="contact" className='bg-light p-0'>
        <div className="footer-top p-0">
          <div className="container-fluid ">
            <div className="row " style={{ justifyContent: "space-between" }}>
              <div className="col-lg-6 col-md-6 footer-contact px-4 pt-4">
                <img src={logo} alt="logo" className="img-responsive px-md-5" />
              </div>
              <div
                className="col-lg-6 col-md-6 footer-newsletter text-black d-flex justify-content-md-end"

              >
                <div className='pt-5  pt-md-2 pe-5  ps-3 ps-md-5'>
                  <h4>Yenepoya University Address:</h4>
                  <p>
                    <i className="bi bi-house"></i><span > Ayush Campus,</span> Naringana,
                    Deralakatte,

                    <br />
                    Mangalore, Karnataka  - 575018
                  </p>
                  <p><i className="bi bi-envelope"></i>   yenepoyab@gmail.com
                  </p>
                  <p> <i className="bi bi-telephone"></i>  9973733030
                  </p>
                </div>
              </div>
              <div className='col-12 text-center bg-dark mt-2'>Copyright @ Bangalore Study 2025</div>
            </div>
          </div>
        </div>



      </footer>

      <a
        href="https://wa.me/+919036020016"
        style={{
          position: "fixed",
          width: 63,
          height: 64,
          bottom: 7,
          right: 24,
          // backgroundColor: "#25d366",
          color: "#fff",
          borderRadius: 50,
          textAlign: "center",
          fontSize: 44,
          boxShadow: "2px 2px 3px #999",
          zIndex: 1059
        }}
        target="_blank "
      >
        {/* <i className="bi bi-whatsapp my-float " /> */}
                <img src={whtsp} alt='whtsplogo' style={{ width: "66px" }}></img>
        
      </a>

      <Modal show={show} onHide={handleClose}>

        <Modal.Body>

          <div className="heading-popup ">
            <h4 className='text-center'>{textmodal}</h4>
          </div>
          <form
            onSubmit={onSubmit}

          >

            <fieldset>
              <input
                type="text"
                id="name1"
                name="name"
                placeholder="Name* "
                required
                value={values.name} onChange={set('name')}
              />
            </fieldset>
            <small className="text-danger " />
            <fieldset className="form-group group_field">
              <input
                name="contact"
                type="number"
                id="contact1"
                // min={0}
                required
                placeholder="Contact No. * "
                // aria-required="true"
                value={values.contact} onChange={set('contact')}
              />
            </fieldset>
            <small className="text-danger " />
            <fieldset className="form-group group_field">
              <input
                type="email"
                id="email1"
                name="email"
                placeholder="Email ID* "
                required
                value={values.email} onChange={set('email')}
              />
            </fieldset>
            <fieldset className="form-group group_field">
              <input
                name="course_in_mind"
                type="number"
                id="course_in_mind"
                // min={0}
                required
                placeholder="NEET Rank"
                // aria-required="true"
                value={values.course_in_mind} onChange={set('course_in_mind')}
              />
            </fieldset>
            <small className="text-danger " />
            <fieldset className="form-group group_field">
              <small className="text-danger " />
              <fieldset className="form-group group_field">

                <select name="location" id="location1"
                  value={values.location} onChange={set('location')} required>
                  <option value=" ">--select state--</option>
                  <option value="Andhra Pradesh ">Andhra Pradesh</option>
                  <option value="Arunachal Pradesh ">Arunachal Pradesh</option>
                  <option value="Assam ">Assam</option>
                  <option value="Bihar ">Bihar</option>
                  <option value="Chhattisgarh ">Chhattisgarh</option>
                  <option value="Goa ">Goa</option>
                  <option value="Gujarat ">Gujarat</option>
                  <option value="Haryana ">Haryana</option>
                  <option value="Himachal Pradesh ">Himachal Pradesh</option>
                  <option value="Jammu & Kashmir ">Jammu &amp; Kashmir</option>
                  <option value="Jharkhand ">Jharkhand</option>
                  <option value="Karnataka ">Karnataka</option>
                  <option value="Kerala ">Kerala</option>
                  <option value="Madhya Pradesh ">Madhya Pradesh</option>
                  <option value="Maharashtra ">Maharashtra</option>
                  <option value="Manipur ">Manipur</option>
                  <option value="Meghalaya ">Meghalaya</option>
                  <option value="Mizoram ">Mizoram</option>
                  <option value="Nagaland ">Nagaland</option>
                  <option value="Odisha ">Odisha</option>
                  <option value="Punjab ">Punjab</option>
                  <option value="Rajasthan ">Rajasthan</option>
                  <option value="Sikkim ">Sikkim</option>
                  <option value="Tamil Nadu ">Tamil Nadu</option>
                  <option value="Tripura ">Tripura</option>
                  <option value="Telangana ">Telangana</option>
                  <option value="Uttarakhand ">Uttarakhand</option>
                  <option value="Uttar Pradesh ">Uttar Pradesh</option>
                  <option value="West Bengal ">West Bengal</option>
                  <option value="Andaman & Nicobar ">Andaman &amp; Nicobar</option>
                  <option value="Chandigarh ">Chandigarh</option>
                  <option value="Dadra and Nagar Haveli ">
                    Dadra and Nagar Haveli
                  </option>
                  <option value="Daman & Diu ">Daman &amp; Diu</option>
                  <option value="Delhi ">Delhi</option>
                  <option value="Lakshadweep ">Lakshadweep</option>
                  <option value="Puducherry ">Puducherry</option>
                </select>


              </fieldset>
              <small className="text-danger " />
              <fieldset className="form-group ">
                <input
                  value={values.description} onChange={set('description')}
                  type="text "
                  name="description"
                  id="description1"
                  placeholder="Message (optional) "
                />
              </fieldset>
              <small className="text-danger " />
              <fieldset className="form-group ">
                <button type="submit" className='btn btn-primary'>Submit</button>
              </fieldset>
            </fieldset>
          </form>

        </Modal.Body>

      </Modal>





    </>
  );
};

export default YenepoyaHomoeopathic;